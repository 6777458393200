import React, {
  useState,
  InputHTMLAttributes,
  HTMLProps,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import SimpleRadioUI from 'components/theme/SimpleRadioUI';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  fontSize?: number;
}

const RadioField = ({
  id,
  checked,
  disabled,
  label,
  onChange,
  ...props
}: Props) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Label
      htmlFor={id}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <input
        id={id}
        type="radio"
        hidden
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <SimpleRadioUI
        checked={checked || false}
        disabled={disabled}
        hovered={hovered}
      />
      {typeof label === 'string' ? <span>{label}</span> : label}
    </Label>
  );
};

export default RadioField;

const Label = styled.label<
  HTMLProps<HTMLLabelElement> & { disabled?: boolean }
>`
  display: flex;
  gap: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
