import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'hook-form';
import useStripePromise from 'hooks/use-stripe-promise';
import { Elements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { PaymentMethod } from 'types/payment';
import { useAppState } from 'AppProvider';
import {
  CreditCardPayment,
  MemberChargePayment,
  RoomChargePayment,
  KICC,
} from 'features/ShoppingCart/components/PaymentMethods';
import { Sep } from 'components/common/styled/common-styled';
import PaymentAccordion from 'features/ShoppingCart/components/PaymentSection/PaymentAccordion';
import { HeadTitle } from 'features/ShoppingCart/utils/styles';
import { getStripeElementOptions } from './PaymentSectionUtils';

const paymentMethodsComponents = {
  [PaymentMethod.KICC]: KICC,
  [PaymentMethod.ROOM_CHARGE]: RoomChargePayment,
  [PaymentMethod.MEMBER_NUMBER]: MemberChargePayment,
  [PaymentMethod.CREDIT_CARD]: CreditCardPayment,
};

interface IPaymentSectionProps {
  paymentOptions: Array<{
    name: PaymentMethod;
    shouldValidateComplimentaryBooking: boolean;
  }>;
  paymentIntentSecret: string | null;
  totalCartAmoutIsFree: boolean;
}

const PaymentSection: React.FC<IPaymentSectionProps> = ({
  paymentOptions,
  paymentIntentSecret,
  totalCartAmoutIsFree,
}) => {
  const { theme } = useAppState();
  const { setValue, clearErrors } = useFormContext();
  const { t: translateText } = useTranslation();
  const [activeOption, setActiveOption] = useState<PaymentMethod | null>(null);
  const stripePromise = useStripePromise();
  const stripeElementOptions = getStripeElementOptions(
    paymentIntentSecret,
    theme,
  );
  const availablePaymentOptions = useMemo(
    () =>
      paymentOptions.filter(
        (po) => paymentIntentSecret || po.name !== PaymentMethod.CREDIT_CARD,
      ),
    [paymentOptions, paymentIntentSecret],
  );

  const selectPaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      setActiveOption(paymentMethod);
      setValue('activeOption', paymentMethod);
    },
    [setValue],
  );

  useEffect(() => {
    if (paymentOptions.length > 0 && !activeOption) {
      selectPaymentMethod(paymentOptions[0].name);
    }
  }, [activeOption, paymentOptions, selectPaymentMethod]);

  const isActive = (methodName: PaymentMethod) => {
    return activeOption === methodName;
  };

  useEffect(() => {
    if (activeOption) {
      setValue('activeOption', activeOption);
    }
    clearErrors(['lastName', 'roomNumber', 'reservationCode', 'card']);
  }, [activeOption, setValue, clearErrors]);

  if (
    totalCartAmoutIsFree &&
    availablePaymentOptions.filter((p) => p.shouldValidateComplimentaryBooking)
      .length === 0
  ) {
    return null;
  }

  const renderPaymentMethods = () => (
    <>
      {availablePaymentOptions.length > 1 ? (
        <>
          <HeadTitle>{translateText('paymentMethod')}</HeadTitle>
          {availablePaymentOptions.map(
            ({ name: paymentMethod, shouldValidateComplimentaryBooking }) => {
              if (!totalCartAmoutIsFree || shouldValidateComplimentaryBooking) {
                const Component = paymentMethodsComponents[paymentMethod];
                return (
                  <PaymentAccordion
                    key={paymentMethod}
                    charge={paymentMethod}
                    onSelectOption={() => selectPaymentMethod(paymentMethod)}
                    selected={isActive(paymentMethod)}
                  >
                    <Component hasMultiplePaymentMethods />
                  </PaymentAccordion>
                );
              }
              return null;
            },
          )}
        </>
      ) : (
        availablePaymentOptions.length > 0 && (
          <>
            <HeadTitle>{translateText('payment')}</HeadTitle>
            {availablePaymentOptions.map(
              ({ name: paymentMethod, shouldValidateComplimentaryBooking }) => {
                if (
                  !totalCartAmoutIsFree ||
                  shouldValidateComplimentaryBooking
                ) {
                  const Component = paymentMethodsComponents[paymentMethod];
                  return <Component key={paymentMethod} />;
                }
                return null;
              },
            )}
          </>
        )
      )}

      {availablePaymentOptions.length > 0 && <Sep />}
    </>
  );

  if (paymentIntentSecret) {
    return (
      <CardContainer>
        <Elements options={stripeElementOptions} stripe={stripePromise}>
          {renderPaymentMethods()}
        </Elements>
      </CardContainer>
    );
  }

  return <CardContainer>{renderPaymentMethods()}</CardContainer>;
};

const CardContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  & > div:last-child {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export default PaymentSection;
