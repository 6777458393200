import { ProductLine } from '@kouto/types';
import useSWR from 'swr';
import { get } from 'utils/axios';

export type Category = {
  id: string;
  name: string;
  order: number | null;
};

const useBrandCategories = (brandId: string, product?: ProductLine) => {
  const getCategoriesUrl = `v1/brands/${brandId}/categories${
    product ? `?product=${product}` : ''
  }`;

  const { data, isLoading } = useSWR(
    getCategoriesUrl,
    get<{ items: Category[] }>,
  );

  return {
    categories: (data?.items ?? []).sort((c) => c.order ?? 0),
    isLoading,
  };
};

export default useBrandCategories;
