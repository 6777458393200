import styled from 'styled-components';

const commonStyles = () => {
  return `
    font-style: normal;
    font-weight: normal;
    text-transform:none;
  `;
};

// Header
export const Header = styled.h1`
  ${commonStyles()};
  font-size: 48px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  line-height: 59px;
  margin-top: 0;
  margin-bottom: 24px;
  color: var(--way-colors-primaryTextColor);
  @media (min-width: 768px) {
    font-size: 72px;
    line-height: 88px;
  }
`;

export const MainTitle = styled.h2`
  ${commonStyles()};
  font-size: 36px;
  line-height: 44px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  margin-top: 0;
  margin-bottom: 24px;
  color: var(--way-colors-primaryTextColor);
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 59px;
  }
`;

export const MainTitleH3 = styled(MainTitle)`
  ${commonStyles()};
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 24px;
  color: var(--way-colors-primaryTextColor);
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const Heading3 = styled.h3`
  ${commonStyles()};
  font-size: 24px;
  line-height: 32px;
  text-transform: none;
  margin-top: 0;
  margin-bottom: 24px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  color: var(--way-colors-primaryTextColor);
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const Heading4 = styled.h4`
  ${commonStyles()};
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 24px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  color: var(--way-colors-primaryTextColor);
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Heading5 = styled.h5`
  ${commonStyles()};
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: ${({ isSingleTier }) =>
    isSingleTier ? '0px !important' : '24px'};
  font-family: ${({ theme }) => theme.font.primaryFont};
  color: var(--way-colors-primaryTextColor);
`;
export const Heading6 = styled.h6`
  ${commonStyles()};
  font-size: 14px;
  line-height: 17px;
  margin-top: 0;
  margin-bottom: 17px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  color: var(--way-colors-primaryTextColor);
`;

export const P1 = styled.p`
  ${commonStyles()};
  font-size: 14px;
  line-height: 17px;
  margin-top: 0;
  margin-bottom: 24px !important;
  color: var(--way-colors-primaryTextColor);
`;

export const P = styled.p`
  ${commonStyles()};
  font-size: 12px;
  line-height: 15px;
  margin-top: 0;
  margin-bottom: 24px !important;
  font-style: normal;
  font-weight: normal;
  color: var(--way-colors-primaryTextColor);
`;
