import styled from 'styled-components';

const ReadMoreButton = styled.div`
  cursor: pointer;
  color: var(--way-colors-primaryColorShades-100);
  font-size: 14px;
  line-height: 19px;
  text-decoration: underline;
`;

export default ReadMoreButton;
