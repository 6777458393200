import moment from 'moment';
import { DATE_FORMAT } from 'utils';
import { Session } from 'types/listings';
import useOneListing from 'hooks/useOneListing';
import useCollectionAvailableSessions, {
  PublicSessionsQueryParams,
} from './useCollectionAvailableSessions';

interface Props {
  collectionId?: string;
  groupId?: string;
  resourceId?: string;
  date?: string;
  time?: string;
  duration?: number;
  latest: boolean;
}

const useResourceBookingSessionSelectedData = ({
  collectionId,
  groupId,
  resourceId,
  date,
  time,
  duration,
  latest,
}: Props) => {
  // 1 - GET COLLECTION FROM API
  const { listing: collection, isLoading: isLoadingCollection } = useOneListing(
    collectionId,
    latest,
  );

  // 2 - GET RESOURCE GROUP FROM COLLECTION
  let resourceGroup = collection?.resourceGroups?.find(
    (group) => group.id === groupId,
  );
  if (
    !resourceGroup &&
    collection?.resourceGroups?.length === 1 &&
    collection?.map
  ) {
    resourceGroup = collection.resourceGroups[0];
  }

  // 3 - GET RESOURCE FROM RESOURCE GROUP
  const resource = resourceGroup?.experiences?.find(
    (resource) => resource.id === resourceId,
  );

  // 4 - GET SESSION FROM API
  const sessionsProps: PublicSessionsQueryParams = { latest };
  if (collectionId && collection) {
    sessionsProps.collectionId = collectionId;
    const sessionsDate =
      date ||
      collection.firstAvailableDate?.scheduledDate ||
      moment().format(DATE_FORMAT);
    sessionsProps.from = sessionsDate;
    sessionsProps.to = sessionsDate;
    if (resourceGroup) {
      sessionsProps.resourceGroupId = resourceGroup.id;
      if (resource) {
        sessionsProps.experienceId = resource.id;
      }
    }
  }
  const { sessions, isLoadingSessions } =
    useCollectionAvailableSessions(sessionsProps);

  // 5 - GET SELECTED SESSION, IF POSSIBLE
  let selectedSession: Session | undefined;
  if (sessions.length > 0) {
    let filteredSessions = [...sessions];
    if (
      time &&
      filteredSessions.find(
        (session) => session.startDateTime.split('T')[1] === time,
      )
    ) {
      filteredSessions = filteredSessions.filter(
        (session) => session.startDateTime.split('T')[1] === time,
      );
    }
    if (
      duration &&
      filteredSessions.find((session) => session.duration === duration)
    ) {
      filteredSessions = filteredSessions.filter(
        (session) => session.duration === duration,
      );
    }
    if (filteredSessions.length === 1) {
      selectedSession = filteredSessions[0];
    }
  }
  if (!selectedSession && resource) {
    selectedSession = sessions[0];
  }

  return {
    isLoading: isLoadingCollection || isLoadingSessions,
    isLoadingCollection,
    isLoadingSessions,
    collection,
    resourceGroup,
    resource,
    sessions,
    selectedSession,
  };
};

export default useResourceBookingSessionSelectedData;
