import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckBox = ({ id, label, fontSize, ...props }) => {
  return (
    <LabelCheckBox htmlFor={id} fontSize={fontSize}>
      <input id={id} type="checkbox" hidden {...props} />
      <span className="checkmark" />
      <span style={{ marginLeft: 0, marginTop: 3 }}>{label}</span>
    </LabelCheckBox>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  fontSize: PropTypes.string,
};

CheckBox.defaultProps = {
  checked: false,
  fontSize: '14px',
};

export default CheckBox;

const LabelCheckBox = styled.label`
  display: flex;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  line-height: 1.3;
  font-size: ${({ fontSize }) => fontSize};

  .checkmark {
    flex-shrink: 0;
    position: relative;
    height: 15px;
    width: 15px;
    min-width: 15px;
    margin-top: 3px;
    border: 0.5px solid var(--way-colors-buttonColorShades-100);
    box-shadow: var(--way-design-boxShadow-s);
    border-radius: 4px;
    background-color: var(--way-palette-white-100);

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 4.2px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid var(--way-colors-buttonColorShades-100);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  input {
    &:checked {
      ~ .checkmark:after {
        display: block;
      }
    }
  }
`;
