import moment from 'moment';
import { PublicGetWaitlistEndpoint } from '@kouto/types';
import useOneListing from 'hooks/useOneListing';
import { Waitlist } from 'types/listings';
import useSWR from 'swr';
import { DATE_FORMAT } from 'utils';
import { get } from 'utils/axios';
import useEventSessions, {
  PublicSessionsQueryParams,
} from './useEventSessions';

const SESSIONS_FUTURE_MONTHS = 4;

interface Props {
  brandId: string;
  eventId?: string;
  date?: string;
  latest?: boolean;
}

const useEventBookingSessionSelectedData = ({
  brandId,
  eventId,
  date,
  latest,
}: Props) => {
  // 1 - GET EVENT FROM API
  const { listing: event, isLoading: isLoadingEvent } = useOneListing(
    eventId,
    latest,
  );

  // 2 - GET SESSION FROM API
  const eventSessionsProps: PublicSessionsQueryParams = { latest };
  const daySessionsProps: PublicSessionsQueryParams = { latest };
  if (eventId && event) {
    eventSessionsProps.eventId = eventId;
    eventSessionsProps.from = moment().format(DATE_FORMAT);
    eventSessionsProps.to = moment(
      event.firstAvailableDate?.scheduledDate || eventSessionsProps.from,
    )
      .add(SESSIONS_FUTURE_MONTHS, 'month')
      .format(DATE_FORMAT);

    daySessionsProps.eventId = eventId;
    if (date) {
      daySessionsProps.from = date;
      daySessionsProps.to = date;
    }
  }
  const { sessions: eventSessions, isLoading: isLoadingEventSessions } =
    useEventSessions(eventSessionsProps);

  const { sessions: daySessions, isLoading: isLoadingDaySessions } =
    useEventSessions(daySessionsProps);

  // 3 - GET WAITLIST FROM API
  let waitlistApiUrl: string | null = null;
  let waitlist: Waitlist | undefined;
  if (event?.waitlistId) {
    waitlistApiUrl = PublicGetWaitlistEndpoint.url({
      brandId,
      waitlistId: event.waitlistId,
    });
  }
  const { data: waitlistData, isLoading: isLoadingWaitlist } = useSWR(
    waitlistApiUrl,
    get<{ data: Waitlist }>,
  );
  if (waitlistData && !isLoadingWaitlist) {
    waitlist = waitlistData.data;
  }

  return {
    isLoading:
      isLoadingEvent ||
      isLoadingEventSessions ||
      isLoadingDaySessions ||
      isLoadingWaitlist,
    isLoadingEvent,
    isLoadingEventSessions,
    isLoadingDaySessions,
    isLoadingWaitlist,
    event,
    eventSessions,
    daySessions,
    waitlist,
  };
};

export default useEventBookingSessionSelectedData;
