import React, { PropsWithChildren, useState } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  renderHeader: (
    expanded: boolean,
    onToggle: CallableFunction,
  ) => React.ReactNode;
  customHeaderClickAction?: (onToggle: CallableFunction) => void;
  onToggle?: (expanded: boolean) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  containerStyles?: ReturnType<typeof css>;
  headerStyles?: ReturnType<typeof css>;
  contentStyles?: ReturnType<typeof css>;
  disabled?: boolean;
}

const CollapsibleCard = ({
  customHeaderClickAction,
  renderHeader,
  onToggle,
  onMouseEnter,
  onMouseLeave,
  children,
  containerStyles,
  headerStyles,
  contentStyles,
  disabled = false,
}: PropsWithChildren<Props>) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    onToggle?.(!isExpanded);
  };

  return (
    <CardContainer
      disabled={disabled}
      customStyles={containerStyles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <CardHeader
        customStyles={headerStyles}
        onClick={() => customHeaderClickAction?.(handleToggle)}
      >
        {renderHeader(isExpanded, handleToggle)}
      </CardHeader>
      <CardWrapperContent isExpanded={isExpanded} customStyles={contentStyles}>
        {children}
      </CardWrapperContent>
    </CardContainer>
  );
};

const CustomStyledDiv = styled.div<{ customStyles?: ReturnType<typeof css> }>`
  ${(props) => props.customStyles};
`;

const CardContainer = styled(CustomStyledDiv)<{ disabled: boolean }>`
  overflow: visible;
  position: relative;
  user-select: none;

  ${({ disabled }) =>
    disabled
      ? `
    &:after {
      content: ' ';
      cursor: not-allowed;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--way-palette-black-20);
      pointer-events: all;
    }
  `
      : ''}
`;

const CardHeader = styled(CustomStyledDiv)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  &:hover {
    background: var(--way-palette-black-5);
  }
`;

const CardWrapperContent = styled(CustomStyledDiv)<{
  isExpanded: boolean;
}>`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      max-height: 9999px;
    `};
`;

export default CollapsibleCard;
