import React, { useEffect } from 'react';
import { has } from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAppState } from 'AppProvider';
import useSearchQueryParams from 'hooks/use-search-params';
import useCustomHistory from 'hooks/use-custom-history';
import { MainTitle, P1 } from 'components/theme/Typography';
import { Wrapper } from 'components/theme/Wrapper';
import { Button } from 'components/theme/Button';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import { analyticsManager } from 'features/analytics';

const Confirmation = () => {
  const { t: translate } = useTranslation();
  const { searchParams } = useSearchQueryParams();
  const { push: customPush, replace: customReplace } = useCustomHistory();
  const emailAddress = window.sessionStorage.getItem('confirmation_email');
  const { settings } = useAppState((state) => state.brand);
  const { response } = useAppState((state) => state.booking);

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.CartConfirmationCode]:
        response?.confirmationCode || '',
    });
  }, [response?.confirmationCode]);

  useEffect(() => {
    if (!searchParams?.status) {
      customReplace({
        pathname: '/',
      });
    }
  }, []);

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_BOOKING_CONFIRMATION,
    payload: {
      [ANALYTICS_PROPERTY.CartConfirmationCode]:
        response?.confirmationCode || '',
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onViewAllListings = () => {
    customPush({
      pathname: `/e/`,
      search: has(searchParams, 'lang') ? `?lang=${searchParams.lang}` : '',
      unset: ['status'],
    });
    window.sessionStorage.removeItem('confirmation_email');
  };

  return (
    <div>
      <Helmet>
        {settings?.name && (
          <title>Experience Checkout Completed | {settings?.name}</title>
        )}
      </Helmet>
      <Wrapper>
        <Center>
          <Heading>{translate('confirmationHeader')}</Heading>
          <P1>
            {translate('confirmationText', {
              email: emailAddress,
            })}
          </P1>

          <LinkBackButton onClick={onViewAllListings}>
            {translate('viewAll')}
          </LinkBackButton>
        </Center>
      </Wrapper>
    </div>
  );
};

export default Confirmation;

const Heading = styled(MainTitle)`
  font-weight: normal;
  padding-top: 64px;
  margin-bottom: 30px;
  padding-bottom: 0;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  display: block;
`;

const Center = styled.div`
  text-align: center;
`;

const LinkBackButton = styled(Button)`
  border: none;
  outline: none;
  margin-top: 30px;
  display: inline-block;
`;
