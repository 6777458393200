import React, { FC } from 'react';
import styled from 'styled-components';
import { ListingPictureKind } from '@kouto/types';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import { Event } from 'types/listings';

const getGalleryPhotoUrl = (coverPhoto: Event['pictures'][number]) => {
  return (
    coverPhoto.uri.original ||
    coverPhoto.uri['1080w'] ||
    coverPhoto.uri['750w'] ||
    coverPhoto.uri['640w'] ||
    coverPhoto.uri['480w'] ||
    coverPhoto.uri['384w']
  );
};

const EventGallery: FC = () => {
  const { isLoading, event } = useEventBookingSession();

  if (isLoading || !event) {
    return (
      <Wrapper>
        <GallerySkeleton />
      </Wrapper>
    );
  }

  const eventGalleryPhotos = event.pictures.filter(
    (p) => p.kind !== ListingPictureKind.COVER,
  );
  eventGalleryPhotos.splice(8);

  if (eventGalleryPhotos.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {eventGalleryPhotos.map((galleryPhoto) => (
        <img
          key={galleryPhoto.id}
          src={getGalleryPhotoUrl(galleryPhoto)}
          alt={event.title}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 85vh;
  overflow-x: scroll;
  padding: 42px 0px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5%;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  > * {
    scroll-snap-align: start;
    flex-shrink: 0;

    width: auto;
    max-width: 90%;
    object-fit: cover;
  }
  & > *:nth-child(1) {
    height: 100%;
  }
  & > *:nth-child(2) {
    height: 60%;
  }
  & > *:nth-child(3) {
    height: 80%;
  }
  & > *:nth-child(4) {
    height: 65%;
  }
  & > *:nth-child(5) {
    height: 100%;
  }
  & > *:nth-child(6) {
    height: 60%;
  }
  & > *:nth-child(7) {
    height: 80%;
  }
  & > *:nth-child(8) {
    height: 65%;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    height: 60vh;
  }
`;

const GallerySkeleton = styled(SkeletonLineSlim)`
  width: 100%;
  height: 100%;
`;

export default EventGallery;
