export default {
  reviewCompleteHeader: 'レビューして完了',
  participants: '参加者',
  enterParticipantInfoText: 'この体験の各参加者の情報を入力してください。',
  purchaser: '購入者',
  firstName: '名',
  lastName: '姓',
  email: 'メール',
  optionalEmail: 'メール（任意）',
  'phone:': '電話',
  participantFieldPlaceholder: '新規追加または既存の参加者を選択',
  reservationSummary: '予約概要',
  editParticipantsDetails: '参加者の詳細を編集',
  participantDetails: '参加者の詳細',
  duplicatePurchaserText: '購入者と同じ',
  phone: '電話',
  addParticipant: '追加 {{tierLabel}}',
  participant: '参加者',
  removeParticipant: '削除',
  experienceLimitReached: ' この体験は参加者数の最大限に達しました',
  payment: '支払い',
  duration: '期間',
  discountCode: '割引コード',
  removeCode: 'コードを削除',
  invalidCodeValidation: '無効なクーポンコード',
  networkErrorDiscountCode: '何か問題が発生しました',
  discount: '割引',
  promo: 'プロモーション',
  promoCode: 'プロモーションコード',
  apply: '適用',
  total: '合計',
  subTotal: '小計',
  includes: '含む',
  and: 'と',
  addHostNotes: 'ホストへのメモを追加',
  policyAggreementText:
    '<0>利用規約</0>と<1>プライバシーポリシー</1>に同意します',
  brandAggreementText:
    ' または、私はこの参加者を代表して同意する権限があることを証明します',
  termsAggreementValidation: '利用規約に同意してください。',
  firstNameValidation: '名前は必須です',
  lastNameValidation: '苗字は必須です',
  emailValidation: {
    emailRequiredText: 'メールアドレスは必須です',
    invalidEmail: '無効なメールアドレスです',
    not_specified: 'メールアドレスが正しくありません',
  },
  phoneValidation: {
    'Invalid Phone Number': '無効な電話番号です',
    not_specified: '無効です',
    phoneRequiredText: '電話番号は必須です',
  },
  participantFirstNameValidation: '参加者の名前は必須です',
  participantLastNameValidation: '参加者の姓は必須です',
  complete: '完了',
  '404Message': '見つかりません',
  waiting: 'お待ちください',
  complimentary: '無料提供',
  confirmationHeader: 'ご予約いただきました！',
  confirmationText:
    '確認メールが{{email}}宛に追加の詳細を含めて送信されました。',
  viewAll: 'すべての体験を表示',
  paymentMethod: '支払い方法',
  creditCard: 'クレジットカード',
  roomCharge: '部屋代',
  memberCharge: '会員料金',
  roomOrReservationNumber: '部屋番号または予約確認番号',
  roomNumber: '部屋番号',
  roomNumberLegacy: '部屋番号または予約確認コード',
  memberNumber: '会員番号',
  kicc: 'イージーペイ',
  chargeValidation: '部屋番号と姓は必須です。',
  memberNumberValidation: '会員番号は必須です',
  invalidRoomNumber: '部屋番号が無効です',
  invalidRoomNumberLegacy: '部屋番号または予約コードが無効です',
  invalidMemberNumber: '会員番号が無効です',
  invalidPhoneNumber: '電話番号が無効です',
  guestNameIsRequired: 'ゲスト名は必須です',
  roomNumberIsRequired: '部屋番号は必須です',
  roomNumberIsRequiredLegacy: '部屋番号または予約コードは必須です',
  roomOrReservationNumberIsRequired: '部屋番号または予約確認番号が必要です',
  cardNumberIncomplete: 'カード番号が不完全です',
  lastNameValidationError: '姓は必要です',
  isReqd: '必要です',
  reservationCode: '予約/確認番号',
  nonHotelGuest: 'ホテルのゲストでない',
  onPropertyGuest: '宿泊中のゲスト',
  preArrivalGuest: '到着前のゲスト',
  cardNumber: 'カード番号',
  pmsValidationErrorMessage: '無効な姓または部屋番号または予約確認番号',
  pmsDateValidationErrorMessage:
    '申し訳ありません！体験の日付は、到着日と出発日の間に含まれていません。',
  fullName: 'フルネーム',
  checkout: 'チェックアウト',
  editParticipants: '参加者の編集',
  private: 'プライベート',
  browseAllExperieces: 'すべての体験を見る',
  payWithCreditCard: 'クレジットカードで支払う',
  payWithRoomCharge: '部屋代で支払う',
  payWithMemberCharge: '会員料金で支払う',
  payWith: '支払う',
  bookBulkErrorByCard:
    '予約の確認中に問題が発生しました。カードは請求されていません',
  bookBulkErrorByRoomCharge: '姓と部屋番号が無効です。',
  bookBulkErrorByMemberCharge: '姓と会員番号が無効です。',
};
