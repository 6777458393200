import React, { useState, FC, ReactNode } from 'react';
import styled from 'styled-components';

interface TabsProps {
  tabsLabel: string[];
  children: ReactNode[];
}

const Tabs: FC<TabsProps> = ({ tabsLabel, children }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  if (tabsLabel.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <TabsHeader>
        {tabsLabel.map((label, index) => (
          <TabLabel
            key={label}
            selected={index === selectedTabIndex}
            clickable={index !== selectedTabIndex && tabsLabel.length > 1}
            onClick={() => setSelectedTabIndex(index)}
          >
            {label}
          </TabLabel>
        ))}
      </TabsHeader>
      <Content>
        {tabsLabel.length === 1
          ? children[0] || children
          : children[selectedTabIndex] || null}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const TabsHeader = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const TabLabel = styled.span<{
  selected: boolean;
  clickable: boolean;
}>`
  /* skip css namespace */
  user-select: none;
  margin: 0px 12px;
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 900;
  border-bottom: ${({ selected }) =>
    selected
      ? 'solid 1px var(--way-colors-borderColor)'
      : 'solid 1px transparent'};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  color: ${({ selected, theme }) =>
    `${theme.colors.primaryTextColor}${selected ? 'ff' : '99'}`};

  &:first-child {
    margin-left: 0px;
  }
`;

const Content = styled.div`
  padding: 16px;
  padding-left: 0px;
  min-height: 50px;
`;

export default Tabs;
