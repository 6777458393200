import { useBrandId, useAppState } from 'AppProvider';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { fetchCustomQuestions } from 'services/experience';
import { Question, QUESTION_SCOPES } from 'types/custom-questions';
import { IExperienceFields } from 'types/experience.types';

const groupByHierarchy = R.groupBy<Question>((question: Question) =>
  question.config.conditions?.length ? 'conditional' : 'parent',
);

const useCustomQuestions = () => {
  const brandId = useBrandId();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<{
    participantParentQuestions: Question[];
    participantConditionalQuestions: Question[];
    purchaserParentQuestions: Question[];
    purchaserConditionalQuestions: Question[];
  }>({
    participantParentQuestions: [],
    participantConditionalQuestions: [],
    purchaserParentQuestions: [],
    purchaserConditionalQuestions: [],
  });
  const {
    experience,
  }: { experience: IExperienceFields & { loading?: boolean } } = useAppState(
    (state: Record<string, unknown>) => state.experience,
  );

  const populateQuestions = async () => {
    try {
      const { items } = await fetchCustomQuestions(experience.id, brandId);

      const allParticipantQuestionsResponse = R.filter(
        (q) => q.config.scope === QUESTION_SCOPES.PARTICIPANT,
        items,
      );
      const allPurchaserQuestionsResponse = R.filter(
        (q) => q.config.scope === QUESTION_SCOPES.PURCHASER,
        items,
      );

      const groupedParticipantQuestions = groupByHierarchy(
        allParticipantQuestionsResponse,
      );
      const groupedPurchaserQuestions = groupByHierarchy(
        allPurchaserQuestionsResponse,
      );

      setState({
        participantParentQuestions: groupedParticipantQuestions.parent || [],
        participantConditionalQuestions:
          groupedParticipantQuestions.conditional || [],
        purchaserParentQuestions: groupedPurchaserQuestions.parent || [],
        purchaserConditionalQuestions:
          groupedPurchaserQuestions.conditional || [],
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!experience?.loading) {
      populateQuestions();
    }
  }, [experience?.loading]);

  return { loading, ...state };
};

export default useCustomQuestions;
