import React, { FC } from 'react';
import styled from 'styled-components';
import { ListingPictureKind, AVAILABLE_BRAND_FEATURES } from '@kouto/types';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import { Event } from 'types/listings';

const getCoverPhotoUrl = (coverPhoto: Event['pictures'][number]) => {
  return (
    coverPhoto.uri['1080w'] ||
    coverPhoto.uri['750w'] ||
    coverPhoto.uri.original ||
    coverPhoto.uri['640w'] ||
    coverPhoto.uri['480w'] ||
    coverPhoto.uri['384w']
  );
};

interface Props {
  animationDelay: number;
}

const EventCoverPhotosDesktop: FC<Props> = ({ animationDelay }) => {
  const { isLoading, event } = useEventBookingSession();
  const disableAnimateScroll = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.DISABLE_ACTIVATE_SCROLLING_ANIMATION,
  );

  if (isLoading || !event) {
    return (
      <Wrapper shouldStick={!disableAnimateScroll}>
        <ImagesWrapper items={1} animationDelay={0}>
          <CoverPhotoSkeleton />
        </ImagesWrapper>
      </Wrapper>
    );
  }

  const eventCoverPhotos = event.pictures.filter(
    (p) => p.kind === ListingPictureKind.COVER,
  );
  eventCoverPhotos.splice(3);

  return (
    <Wrapper shouldStick={!disableAnimateScroll}>
      <ImagesWrapper
        items={eventCoverPhotos.length}
        animationDelay={animationDelay}
      >
        {eventCoverPhotos.map((coverPhoto) => (
          <img
            key={coverPhoto.id}
            src={getCoverPhotoUrl(coverPhoto)}
            alt={event.title}
          />
        ))}
      </ImagesWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  shouldStick: boolean;
}>`
  width: 100%;
  height: 600px;
  margin-top: 32px;

  ${({ shouldStick }) =>
    shouldStick
      ? `
        position: sticky;
        top: 8px;
      `
      : ''};
`;

const ImagesWrapper = styled.div.attrs<{
  items: number;
  animationDelay: number;
}>(({ animationDelay }) => ({
  style: {
    transform: `scale(${1 - (0.2 * animationDelay) / 100})`,
    opacity: 1 - (0.6 * animationDelay) / 100,
  },
}))<{
  items: number;
  animationDelay: number;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  transform-origin: center bottom;

  ${({ items }) => {
    if (items === 1) {
      return `
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      `;
    }
    if (items === 2) {
      return `
        img {
          width: calc(50% - 8px);
          height: 100%;
          object-fit: cover;
        }
        img:first-child {
          margin-right: 16px;
        }
      `;
    }

    return `
      img {
        width: calc(50% - 8px);
        object-fit: cover;
        height: calc(50% - 8px);
        position: absolute;
      }
      img:first-child {
        margin-right: 16px;
        height: 100%;
      }
      img:nth-child(2) {
        top: 0px;
        right: 0px;
      }
      img:nth-child(3) {
        bottom: 0px;
        right: 0px;
      }
    `;
  }};
`;

const CoverPhotoSkeleton = styled(SkeletonLineSlim)`
  width: 100%;
  height: 100%;
`;

export default EventCoverPhotosDesktop;
