import React, { FC, useState } from 'react';
import { CalendarProps } from 'react-calendar';
import moment from 'moment';
import BottomSheetDateSelector from 'components/Resource/BottomSheetDateSelector';
import { Modal } from 'components/Modal';
import { useIsMobile } from 'WindowDimensionProvider';
import { BottomDrawer } from 'components/BottomDrawer';

interface Props extends Omit<CalendarProps, 'value'> {
  availableDates: string[];
  onMonthChange: (date: moment.Moment) => void;
  value: string;
  triggerButton: (openModal: () => void) => React.ReactNode;
}

export const ModalDatePicker: FC<Props> = ({
  availableDates,
  onChange,
  onMonthChange,
  triggerButton,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleChange: CalendarProps['onChange'] = (option, event) => {
    setOpen(false);
    onChange?.(option, event);
  };

  return (
    <>
      {triggerButton(() => setOpen(true))}
      {isMobile ? (
        <BottomDrawer
          size="full"
          open={open}
          heading="Date"
          onClose={() => setOpen(false)}
        >
          <BottomSheetDateSelector
            availableDates={availableDates}
            onMonthChange={onMonthChange}
            {...props}
            onChange={handleChange}
          />
        </BottomDrawer>
      ) : (
        <Modal
          content={
            <BottomSheetDateSelector
              availableDates={availableDates}
              onMonthChange={onMonthChange}
              {...props}
              onChange={handleChange}
            />
          }
          isClosable={false}
          onClose={() => setOpen(false)}
          isVisible={open}
          miniCloseButton
        />
      )}
    </>
  );
};

export default BottomSheetDateSelector;
