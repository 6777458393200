import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get, isNil, isEqual, partition } from 'lodash';
import { Heading3, P, Heading4 } from 'components/theme/Typography';
import { VibeButton, TypeButton } from 'components/theme/Button';
import { BOOKING_AVAILABILITY_MODE_PRIVATE } from 'types/experience';
import { SkeletonPulseAbsolute, SkeletonLine } from 'components/theme/Skeleton';
import { RatingSummary } from 'components/common/RatingSummary';
import ToggleFeature from 'components/ToggleFeature';
import ResponsiveImage from 'components/common/ResponsiveImage';
import { useHistory } from 'react-router-dom';
import useSearchQueryParams from 'hooks/use-search-params';
import {
  analyticsManager,
  getExperiencesForAnalytics,
  getProductFromResourceGroups,
} from 'features/analytics';

import {
  getShortDescription,
  isImageRatioGreaterOne,
  isImageRatioGreaterSixteenNine,
  getCurrencyFromCode,
  getUserFullName,
  currencyFormat,
  isExperienceComplimentary,
} from 'utils';
import { useAppState } from 'AppProvider';
import PeopleIcon from 'assets/icon-user';
import { getListingRoute } from 'utils/listings';
import {
  ANALYTICS_EVENT,
  ANALYTICS_PROPERTY,
  ExperienceKind,
} from '@kouto/types';
import StarIcon from './star-icon.svg';

const FeatureImage = styled.img`
  width: 100%;
  height: ${({ isSixteenNineRatio }) => (isSixteenNineRatio ? 'auto' : '100%')};
  object-fit: cover;
  top: 50%;
  left: 50%;
  position: absolute;
  object-position: center;
  transform: translate(-50%, -50%);
`;

const HostedByImage = styled.img`
  width: 48px;
  object-fit: cover;
  height: ${({ isSquare }) => (isSquare ? 'auto' : '48px')};
  top: 50%;
  left: 50%;
  position: absolute;
  object-position: center;
  transform: translate(-50%, -50%);
`;

const FeaturedExperienceItem = ({ experience, isEven }) => {
  const { t: translateText } = useTranslation();
  const [isSquare, setIsSquare] = useState(false);
  const [isSixteenNineRatio, setIsSixteenNineRatio] = useState(false);
  const { settings } = useAppState((state) => state.brand);
  const [isHovered, setIsHovered] = useState(false);

  const history = useHistory();
  const isHostLed = !!experience?.hostedBy?.id;

  const { searchParams } = useSearchQueryParams();

  const isExclusive = get(experience, 'isExclusive');
  const descriptionHost = get(experience, 'hostedBy.description');
  const exclusiveTagName =
    get(settings, 'promotion.exclusiveTag') ?? 'Exclusive';
  const userProfileImage = get(experience, 'hostedBy.profilePicture.uri');

  const onImgLoad = ({ target: img }) => {
    setIsSquare(isImageRatioGreaterOne(img.naturalWidth, img.naturalHeight));
  };

  const onImgLoadSixteenNineRatio = ({ target: img }) => {
    setIsSixteenNineRatio(
      isImageRatioGreaterSixteenNine(img.naturalWidth, img.naturalHeight),
    );
  };

  const [coverPictures, pictures] = partition(
    experience.pictures,
    (picture) => picture.kind === 'cover',
  );

  const coverPicture =
    [...(coverPictures ?? []), ...(pictures ?? [])]?.[0]?.uri ??
    get(experience, 'coverPicture.uri');

  const reviewsEnabled = () => {
    return (
      <ToggleFeature featureId="reviews">
        <>
          {!isNil(experience?.ratings?.total) && (
            <RatingSummary
              className="featured-experience__rating_summary"
              rating={experience?.ratings?.rating}
              count={experience?.ratings?.total}
            />
          )}
        </>
      </ToggleFeature>
    );
  };

  const getListingPrice = (listing) => {
    const experiencePricingTiers = listing?.defaultPriceTiers || [];
    return experiencePricingTiers
      .filter((priceCategory) => Number(priceCategory?.price))
      .sort((a, b) => a.price - b.price, 0)?.[0];
  };

  const getExperiencePrice = () => {
    const currency = getCurrencyFromCode(settings.currency);
    const experienceStarterPrice = getListingPrice(experience);
    const singleTierExperience = experience?.defaultPriceTiers?.[0];

    if (experience?.hidePrice) return null;

    if (isExperienceComplimentary(experience)) {
      return (
        <FeaturedMeta className="featured-experience__meta">
          <FeaturedParticipantsTotal className="featured-experience__participants-total">
            {translateText('complimentary')}
          </FeaturedParticipantsTotal>
          {reviewsEnabled()}
        </FeaturedMeta>
      );
    }

    if (
      isEqual(
        experience?.bookingAvailabilityMode,
        BOOKING_AVAILABILITY_MODE_PRIVATE,
      )
    ) {
      const privatePrice =
        (singleTierExperience?.price || 0) *
        (experience?.defaultMaxParticipantCount || 0);

      return (
        <FeaturedMeta className="featured-experience__meta">
          <FeaturedParticipantsTotal className="featured-experience__participants-total">
            {currencyFormat(currency)(privatePrice)}&nbsp;
            {translateText('total')}
          </FeaturedParticipantsTotal>
          {reviewsEnabled()}
        </FeaturedMeta>
      );
    }

    return experience?.defaultPriceTiers?.length > 1 ? (
      <FeaturedMeta className="featured-experience__meta">
        <FeaturedParticipantsTotal className="featured-experience__participants-total">
          {translateText('experienceStartingPrice', {
            price: currencyFormat(currency)(experienceStarterPrice?.price),
          })}
          &nbsp;/&nbsp;
          {experienceStarterPrice?.name}{' '}
        </FeaturedParticipantsTotal>
        {reviewsEnabled()}
      </FeaturedMeta>
    ) : (
      <FeaturedMeta className="featured-experience__meta">
        <FeaturedParticipantsTotal className="featured-experience__participants-total">
          {currencyFormat(currency)(singleTierExperience?.price)}
          &nbsp;/&nbsp;
          {singleTierExperience?.name}{' '}
        </FeaturedParticipantsTotal>
        {reviewsEnabled()}
      </FeaturedMeta>
    );
  };

  const onExperienceViewClick = () => {
    if (
      experience.kind === ExperienceKind.EXPERIENCE ||
      experience.resourceGroups.length === 1
    ) {
      analyticsManager.sendEvent(ANALYTICS_EVENT.CLICK_EXPERIENCE, {
        [ANALYTICS_PROPERTY.ExperienceId]: experience.id,
        [ANALYTICS_PROPERTY.ExperienceTitle]: experience.title,
        [ANALYTICS_PROPERTY.ExperienceCategory]: experience.category?.name,
        [ANALYTICS_PROPERTY.Products]: JSON.stringify(
          experience.kind === ExperienceKind.RESOURCE
            ? getProductFromResourceGroups({
                groups: [experience],
                categoryName: experience.category?.name,
                collectionId: experience.collectionId,
                collectionName: experience.collectionName,
                priceTiers:
                  experience.defaultPriceTiers ?? experience.priceTiers,
              })
            : getExperiencesForAnalytics([experience]),
        ),
      });
    }
    history.push(getListingRoute(experience, searchParams));
  };

  return (
    <Wrapper
      className={`featured-experience__wrapper-${isEven ? 'even' : 'odd'}`}
      isEven={isEven}
      onMouseOver={() => setIsHovered(true)}
      onFocus={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
    >
      <WraperContent isEven={isEven}>
        <WrapText className="featured-experience__wrap-text">
          <StyledLink
            onClick={onExperienceViewClick}
            className="featured-experience__styled-link"
          >
            {!experience.loading ? (
              <Title className="featured-experience__item-title">
                {experience.title}
              </Title>
            ) : (
              <SkeletonLine translucent />
            )}

            {!experience.loading ? (
              <Body className="featured-experience__item-headline">
                {experience.headline}
              </Body>
            ) : (
              <SkeletonLine translucent />
            )}

            {!experience.loading ? (
              <TagButtonWrap className="featured-experience__tag-button">
                <Button
                  loading={`${experience.loading}`}
                  className="featured-experience__item-category-button"
                >
                  {get(experience, 'category.name')}
                </Button>
                {experience.vibes
                  ?.filter((vibe) => vibe)
                  .map((vibe) => (
                    <ButtonGray
                      className="featured-experience__item-vibe-button"
                      id={`featured-experience-${experience?.id}-vibe-${vibe?.id}`}
                      key={`featured-view-vibe-${vibe?.id}`}
                    >
                      {vibe.name}
                    </ButtonGray>
                  ))}
              </TagButtonWrap>
            ) : (
              <SkeletonLine translucent />
            )}
            {!experience.loading ? (
              <PriceTag className="featured-experience__item-price-tag">
                {getExperiencePrice()}
              </PriceTag>
            ) : (
              <SkeletonLine translucent />
            )}

            {isHostLed ? (
              <Footer className="featured-experience__item-footer">
                {!experience.loading ? (
                  <Title4 className="featured-experience__item-hosted-by-label">
                    {translateText('hostedBy')}{' '}
                    {getUserFullName(experience?.hostedBy)}
                  </Title4>
                ) : (
                  <SkeletonLine translucent />
                )}
                <HostedBy className="featured-experience__item-hosted-by-wrapper">
                  {userProfileImage ? (
                    <HostedByImageWrap>
                      {!experience.loading ? (
                        <ResponsiveImage
                          uriObject={get(
                            experience,
                            'hostedBy.profilePicture.uri',
                          )}
                          defaultSize="48w"
                          isSquare={isSquare}
                          onLoad={onImgLoad}
                          CustomRenderer={HostedByImage}
                          alt={descriptionHost}
                          className="featured-experience__item-hosted-by-image"
                        />
                      ) : (
                        <SkeletonLine translucent />
                      )}
                    </HostedByImageWrap>
                  ) : (
                    (!experience.loading && (
                      <DefaultUserAvatar>
                        <PeopleIcon />
                      </DefaultUserAvatar>
                    )) || (
                      <HostedByImageWrap>
                        <SkeletonLine translucent />
                      </HostedByImageWrap>
                    )
                  )}
                  <HostedByContent className="featured-experience__item-hosted-by-content">
                    {!experience.loading ? (
                      getShortDescription(descriptionHost, 150)
                    ) : (
                      <SkeletonLine translucent />
                    )}
                  </HostedByContent>
                </HostedBy>
              </Footer>
            ) : (
              <Footer />
            )}
          </StyledLink>
        </WrapText>
      </WraperContent>
      <WraperImage
        className="featured-experience__item-image-wrapper"
        loading={`${experience.loading}`}
        isEven={isEven}
      >
        <WraperImageRatio>
          {!experience.loading ? (
            <StyledLink onClick={onExperienceViewClick}>
              {isExclusive && isHovered ? (
                <ExclusiveTagOverlay
                  loading={`${experience.loading}`}
                  className="featured-experience__item-image-exclusive-overlay"
                >
                  {!experience.loading ? (
                    exclusiveTagName
                  ) : (
                    <SkeletonPulseAbsolute translucent />
                  )}
                </ExclusiveTagOverlay>
              ) : null}
              {isExclusive ? (
                <ExclusiveStar
                  loading={`${experience.loading}`}
                  className="featured-experience__item-image-exclusive-star"
                >
                  {!experience.loading ? (
                    <StarIcon />
                  ) : (
                    <SkeletonPulseAbsolute translucent />
                  )}
                </ExclusiveStar>
              ) : null}
              <ResponsiveImage
                className="featured-experience__item-image-responsive-image"
                uriObject={coverPicture}
                defaultSize="480w"
                isSixteenNineRatio={isSixteenNineRatio}
                onLoad={onImgLoadSixteenNineRatio}
                CustomRenderer={FeatureImage}
                alt={experience.title}
              />
            </StyledLink>
          ) : (
            <SkeletonPulseAbsolute translucent />
          )}
        </WraperImageRatio>
      </WraperImage>
    </Wrapper>
  );
};

FeaturedExperienceItem.propTypes = {
  experience: PropTypes.object,
  isEven: PropTypes.bool,
};

FeaturedExperienceItem.defaultProps = {
  experience: {
    loading: true,
  },
};

export default FeaturedExperienceItem;

const Wrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    align-items: center;
    margin-bottom: 87px;
    display: flex;
    flex-direction: row;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
`;

const WraperImage = styled.div`
  position: relative;
  @media (min-width: 768px) {
    width: 50%;
    padding-right: 10px;
    &:nth-child(even) {
      flex-direction: row-reverse;
      padding-left: 10px;
    }
  }
`;

const WraperImageRatio = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const WraperContent = styled.div`
  position: relative;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  text-align: left;
  &:nth-child(even) {
    flex-direction: row-reverse;
    @media (min-width: 768px) {
      padding-right: 10px;
    }
  }

  @media (min-width: 768px) {
    width: 50%;
    padding-left: 10px;
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  color: var(--way-colors-primaryTextColor);
`;

const Button = styled(TypeButton)`
  margin-right: 8px;
  margin-bottom: 8px;
`;
const ButtonGray = styled(VibeButton)`
  margin-right: 8px;
  margin-bottom: 8px;
`;

const TagButtonWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7px;
`;

const Title = styled(Heading3)`
  font-weight: normal;
  margin-bottom: 15px;
`;
const Title4 = styled(Heading4)`
  font-size: 14px;
  line-height: 17px;
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
    font-weight: normal;
  }
`;
const Body = styled(P)`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
`;

const WrapText = styled.div`
  max-width: 400px;
`;
const HostedBy = styled.div`
  display: flex;
  align-items: flex-start;
`;
const HostedByImageWrap = styled.div`
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
`;
const HostedByContent = styled.div`
  font-size: 12px;
  line-height: 15px;
  width: calc(100% - 55px);
`;
const PriceTag = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 17px;
`;
const Footer = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-bottom-color: var(--way-colors-borderColor);
  padding-bottom: 24px;
`;

const FeaturedMeta = styled.div`
  display: flex;
  align-items: center;
`;
const FeaturedParticipantsTotal = styled.div`
  margin-right: 24px;
`;
const ExclusiveStar = styled.div`
  position: absolute;
  float: right;
  right: 17px;
  z-index: 4;
  top: 17px;
  pointer-events: none;
`;

const ExclusiveTagOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  font-style: italic;
  font-weight: normal;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--way-palette-white-100);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    var(--way-palette-black-100) 100%
  );
  opacity: 1;
  padding: 40% 0 10%;
`;

const DefaultUserAvatar = styled.div`
  background-color: #dedcdc6e;
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
  & > * {
    position: absolute;
    top: 20%;
    left: 25%;
  }
`;
