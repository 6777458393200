import React, { useState, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PaymentMethod } from 'types/payment';
import SimpleRadioUI from 'components/theme/SimpleRadioUI';

const paymentLabel = {
  [PaymentMethod.CREDIT_CARD]: 'creditCard',
  [PaymentMethod.ROOM_CHARGE]: 'roomCharge',
  [PaymentMethod.MEMBER_NUMBER]: 'memberCharge',
  [PaymentMethod.KICC]: 'kicc',
};

interface IAccordionProps {
  onSelectOption(): void;
  selected: boolean;
  charge: string;
}

const PaymentAccordion = ({
  onSelectOption,
  selected,
  charge,
  children,
}: PropsWithChildren<IAccordionProps>) => {
  const { t: translateText } = useTranslation();
  const [hovered, setHovered] = useState(false);

  return (
    <PaymentAccordionItem>
      <AccordionHeader
        id={charge}
        onClick={onSelectOption}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <SimpleRadioUI checked={selected} hovered={hovered} />
        <Label htmlFor={charge}>
          {translateText(paymentLabel[charge as keyof typeof paymentLabel])}
        </Label>
      </AccordionHeader>
      {selected ? children : null}
    </PaymentAccordionItem>
  );
};

const PaymentAccordionItem = styled.div`
  padding: 12px 0px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: inherit;
  margin-left: 8px;
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export default PaymentAccordion;
