import TRANSLATIONS_DATES_JP from './weekdays/translation';
import TRANSLATIONS_BOOKING_JP from './booking/translation';
import TRANSLATIONS_FORM_JP from './form/translation';
import TRANSLATIONS_EXPERIENCES_JP from './experiences/translation';
import TRANSLATION_LANDING_PAGES_JP from './landingPages/translation';
import TRANSLATION_EVENTS_JP from './events/translation';
import TRANSLATION_RESERVE_JP from './reserve/translation';

export default {
  ...TRANSLATIONS_DATES_JP,
  ...TRANSLATIONS_BOOKING_JP,
  ...TRANSLATIONS_FORM_JP,
  ...TRANSLATIONS_EXPERIENCES_JP,
  ...TRANSLATION_LANDING_PAGES_JP,
  ...TRANSLATION_EVENTS_JP,
  ...TRANSLATION_RESERVE_JP,
};
