import { useParams, useHistory } from 'react-router-dom';
import {
  getSanitizedPathName,
  queryParamsToObject,
  serializeParams,
} from 'utils';
import useSearchQueryParams from './use-search-params';

const getParams = (search, searchParams, unset) => {
  const searchObject = { ...queryParamsToObject(search), ...searchParams };

  return Object.keys(searchObject).reduce((acc, key) => {
    if (unset.includes(key)) {
      return acc;
    }

    return {
      ...acc,
      [key]: searchObject[key],
    };
  }, {});
};

const useCustomHistory = () => {
  const params = useParams();
  const history = useHistory();
  const { searchParams } = useSearchQueryParams();

  const push = ({ pathname, search = '', unset = [] }) => {
    const searchObject = getParams(search, searchParams, unset);

    history.push({
      search: serializeParams({ ...searchParams, ...searchObject }),
      pathname: getSanitizedPathName(params, pathname),
    });
  };

  const replace = ({ pathname, search = '', unset = [] }) => {
    const searchObject = getParams(search, searchParams, unset);

    history.replace({
      search: serializeParams({ ...searchParams, ...searchObject }),
      pathname: getSanitizedPathName(params, pathname),
    });
  };

  return { push, replace };
};

export default useCustomHistory;
