import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SkeletonPulseAbsolute } from 'components/theme/Skeleton';
import ResponsiveImage from 'components/common/ResponsiveImage';
import { ButtonLink } from 'components/theme/Button';

import { isImageRatioGreaterSixteenNine } from 'utils';
import { useIsMobile } from 'WindowDimensionProvider';

import ChevronLeft from 'assets/chevron-left';
import ChevronRight from 'assets/chevron-right';

const NAV_DIRECTION_LEFT = 'left';
const NAV_DIRECTION_RIGHT = 'right';

const ExperieneImageItem = styled.img`
  height: 352px !important;
  border-radius: var(--way-design-borderRadiusDefault);
  @media (max-width: 992px) {
    height: 250px !important;
    max-width: 80vw !important;
  }
  max-width: 100vw !important;
  object-fit: cover;
  object-position: center;
  margin-right: 16px;
`;

const ExperienceCoverImage = styled.img`
  width: 627px !important;
  max-width: 627px !important;
  height: 352px !important;
  object-fit: cover;
  border-radius: var(--way-design-borderRadiusDefault);
  object-position: center;
  margin-right: 16px;
  @media (min-width: 992px) and (max-width: 1080px) {
    width: auto !important;
    max-width: 90vw !important;
  }
`;

/**
 * This component is not used. Just saving it for a reference
 * It uses the scrollBy api native animation.
 */
export default function PhotoSlider({ experience }) {
  const [isSixteenNineRatio, setIsSixteenNineRatio] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [pictures, setPictures] = useState(null);
  const [, setNavigationDirection] = useState(null);
  const isMobile = useIsMobile();
  const sliderRef = useRef();
  const intervalRef = useRef();

  useEffect(() => {
    if (experience?.pictures?.length) {
      setPictures([
        { ...experience.coverPicture, isCoverPhoto: true },
        ...(experience.pictures ?? []),
      ]);
    }
    return function cleanup() {
      setPictures(null);
      setCurrentImageIndex(0);
    };
  }, [experience]);

  const onImgLoadSixteenNineRatio = ({ target: img }) => {
    setIsSixteenNineRatio(
      isImageRatioGreaterSixteenNine(img.naturalWidth, img.naturalHeight),
    );
  };

  const mapPictureComponentsForMobile = (items) =>
    items?.map((picture) => (
      <ResponsiveImage
        key={picture?.id}
        uriObject={picture?.uri}
        defaultSize="480w"
        isSixteenNineRatio={isSixteenNineRatio}
        onLoad={onImgLoadSixteenNineRatio}
        CustomRenderer={ExperieneImageItem}
        className="experience-view__experience-image-item"
        alt={experience.title}
      />
    ));

  const mapPictureComponents = (items) =>
    items?.map((picture) => (
      <ResponsiveImage
        key={picture?.id}
        uriObject={picture?.uri}
        defaultSize="480w"
        isSixteenNineRatio={isSixteenNineRatio}
        onLoad={onImgLoadSixteenNineRatio}
        CustomRenderer={
          picture.isCoverPhoto ? ExperienceCoverImage : ExperieneImageItem
        }
        className="experience-view__experience-image-item"
        alt={experience.title}
      />
    ));

  const handlePrev = () => {
    if (!sliderRef?.current) return;
    if (currentImageIndex === 0) return;
    const pictureNodes = sliderRef.current.childNodes;
    // TODO optimize scrollTo value calculation
    const scrollTo = [...pictureNodes]
      .filter((picture, index) => {
        return index < currentImageIndex - 1;
      })
      .map((pictureNode) => {
        return pictureNode?.getBoundingClientRect().width;
      })
      .reduce((sum, next) => {
        return sum + next;
      }, 0);
    sliderRef.current.scrollTo({
      left: scrollTo,
      behavior: 'smooth',
    });
    setNavigationDirection(NAV_DIRECTION_LEFT);
    setCurrentImageIndex((index) => index - 1);
  };

  const handleNext = () => {
    if (!sliderRef?.current) return;
    if (currentImageIndex === pictures.length - 1) return;
    const pictureNodes = sliderRef.current.childNodes;
    // TODO optimize scrollTo value calculation
    const scrollTo = [...pictureNodes]
      .filter((picture, index) => {
        return index <= currentImageIndex;
      })
      .map((pictureNode) => {
        return pictureNode?.getBoundingClientRect().width;
      })
      .reduce((sum, next) => {
        return sum + next;
      }, 0);
    sliderRef.current.scrollTo({
      left: scrollTo,
      behavior: 'smooth',
    });
    setNavigationDirection(NAV_DIRECTION_RIGHT);
    setCurrentImageIndex((index) => index + 1);
  };

  useEffect(() => {
    sliderRef.current.scrollTo({
      left: 0,
    });
    setCurrentImageIndex(0);
    if (intervalRef.current) clearInterval(intervalRef.current);
  }, [isMobile]);

  useEffect(() => {
    if (pictures?.length && !isMobile) {
      intervalRef.current = setInterval(() => {
        if (currentImageIndex < pictures.length - 1) {
          handleNext();
          return;
        }
        setCurrentImageIndex(0);
        sliderRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      }, 5000);
    }
    return function cleanup() {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [pictures, currentImageIndex, isMobile]);

  return (
    <NavigationWrapper>
      {pictures?.length && !isMobile ? (
        <NavigateButtonLeft
          onClick={handlePrev}
          isHidden={currentImageIndex === 0}
        >
          <ChevronLeft />
        </NavigateButtonLeft>
      ) : null}
      <CardImage loading={experience.loading}>
        {!experience.loading ? (
          <ImageCarousal>
            {!experience.loading ? (
              mapPictureComponentsForMobile(pictures)
            ) : (
              <SkeletonPulseAbsolute translucent />
            )}
          </ImageCarousal>
        ) : (
          <SkeletonPulseAbsolute translucent />
        )}
        <ImageCarousalDesktop ref={sliderRef}>
          {pictures?.length
            ? mapPictureComponents(pictures, ExperieneImageItem)
            : null}
        </ImageCarousalDesktop>
      </CardImage>
      {pictures?.length && !isMobile ? (
        <NavigateButtonRight
          onClick={handleNext}
          isHidden={currentImageIndex === pictures?.length - 1}
        >
          <ChevronRight />
        </NavigateButtonRight>
      ) : null}
    </NavigationWrapper>
  );
}

PhotoSlider.propTypes = {
  experience: PropTypes.object.isRequired,
};

// card Image
const CardImage = styled.div`
  margin-bottom: 0;
  border-radius: var(--way-design-borderRadiusDefault);
  position: relative;
  max-width: 100% !important;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  @media (max-width: 992px) {
    padding-bottom: 10px;
  }
`;

const NavigationWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const NavigateButton = styled(ButtonLink)`
  position: absolute;
  top: 160px;
  z-index: 4;
  right: 30px;
  height: 36px;
  width: 36px;
  border: 2px solid var(--way-palette-white-30);
  border-radius: 50%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--way-colors-buttonColorShades-100);
  background-color: var(--way-palette-white-30);
  font-size: 12px;
  line-height: 15px;
  &:focus {
    outline: 0 none;
  }
`;

const NavigateButtonLeft = styled(NavigateButton)`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  left: 5px;
  @media (max-width: 992px) {
    display: none;
  }
`;

const NavigateButtonRight = styled(NavigateButton)`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  right: 5px;
  @media (max-width: 992px) {
    display: none;
  }
`;

const ImageCarousal = styled.div`
  display: none;
  @media (max-width: 992px) {
    display: flex;
    width: 100%;
    height: 250px;
  }
`;

const ImageCarousalDesktop = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: var(--way-design-borderRadiusDefault);
  height: 352px;
  @media (max-width: 992px) {
    display: none;
  }
`;
