import React, { InputHTMLAttributes, HTMLProps, ReactElement } from 'react';
import styled from 'styled-components';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactElement;
  fontSize?: number;
}

const CheckboxField = ({
  checked,
  disabled,
  label,
  onChange,
  ...props
}: Props) => {
  return (
    <Label>
      <input
        type="checkbox"
        hidden
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <Mark className={checked ? 'active' : ''} disabled={disabled} />
      <span>{label}</span>
    </Label>
  );
};

export default CheckboxField;

const Label = styled.label<
  HTMLProps<HTMLLabelElement> & { fontSize?: number; disabled?: boolean }
>`
  display: flex;
  gap: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
`;

const Mark = styled.span<{ active?: boolean; disabled?: boolean }>`
  flex-shrink: 0;
  position: relative;
  height: 15px;
  width: 15px;
  min-width: 15px;
  margin-top: 1px;
  border: 0.5px solid var(--way-colors-buttonColorShades-100);
  box-shadow: var(--way-design-boxShadow-s);
  border-radius: 4px;
  background-color: var(--way-palette-white-100);

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 4.2px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid var(--way-colors-buttonColorShades-100);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &.active {
    &:after {
      display: block;
    }
  }
`;
