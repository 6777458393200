import React, { useState } from 'react';
import styled from 'styled-components';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import useCustomHistory from 'hooks/use-custom-history';
import useCartItems from 'hooks/useCartItems';
import CartIconSvg from 'assets/icon-cart';
import { ExperienceCountBadge } from './ExperienceCountBadge';

export interface IExperienceFilterParam {
  categoryIds?: string;
  vibeIds?: string;
  startDate?: string;
  endDate?: string;
  lang?: string;
}

export const CartIcon: React.FC<{ searchParam?: IExperienceFilterParam }> = ({
  searchParam,
}) => {
  const [hovered, setHovered] = useState(false);
  const isCartEnabled = useBrandToggleFeature('shoppingCart');
  const { cartItems } = useCartItems();
  const itemsInCart = Object.values(cartItems).length;
  const { push } = useCustomHistory();

  if (!isCartEnabled) {
    return null;
  }

  return (
    <IconWrapper
      disabled={itemsInCart < 1}
      className="header__cart-button"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onClick={() => {
        if (itemsInCart >= 1) {
          push({
            pathname: '/checkout',
            search: searchParam ? `?${searchParam}` : '',
          });
        }
      }}
    >
      <ExperienceCountBadge count={itemsInCart} />
      <CartIconSvg
        scale={1.3}
        color={
          hovered
            ? 'var(--way-colors-contrastColorShades-100)'
            : 'var(--way-colors-contrastColorShades-70)'
        }
        className="header__cart-button-icon"
      />
    </IconWrapper>
  );
};

const IconWrapper = styled.div<{
  disabled: boolean;
}>`
  position: relative;
  grid-area: cart;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: var(--way-design-borderRadiusDefault);
  border: 0.5px solid var(--way-colors-borderColor);
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  height: 50px;
  width: 50px;
  gap: 8px;

  &:hover {
    border: 0.5px solid
      ${({ disabled }) =>
        disabled
          ? 'var(--way-colors-borderColor)'
          : 'var(--way-colors-borderHoverColor)'};
  }
`;
