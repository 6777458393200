import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { throttle } from 'lodash';
import { ProductLine } from '@kouto/types';
import { useAppState } from 'AppProvider';
import { useIsMobile } from 'WindowDimensionProvider';
import { ListingType } from 'utils/listings';
import usePrevious from 'hooks/usePrevious';
import DynamicGrid from 'components/DynamicGrid/DynamicGrid';
import DynamicGridLoading from 'components/DynamicGrid/DynamicGridLoading';

import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import ListingItemCard from './ListingItemCard';
import { useGridConfig } from '../hooks/useGridConfig';

const DESKTOP_VIEWPORT_DISTANCE_TO_SCROLL = 0.5;
const MOBILE_VIEWPORT_DISTANCE_TO_SCROLL = 1;

interface Props {
  listings: ListingType[];
  loadMore: () => void;
  allItemsLoaded: boolean;
  isLoading: boolean;
}

export const DynamicListingsGrid: FC<Props> = ({
  listings,
  loadMore,
  allItemsLoaded,
  isLoading,
}) => {
  const brandSettings = useAppState((s: any) => s.brand.settings);
  const gridConfig = useGridConfig();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isResults, product } = useLandingPageRouteParams();
  const isMobile = useIsMobile();
  const previousIsLoading = usePrevious<boolean>(isLoading);

  const VIEWPORT_DISTANCE_TO_SCROLL = isMobile
    ? MOBILE_VIEWPORT_DISTANCE_TO_SCROLL
    : DESKTOP_VIEWPORT_DISTANCE_TO_SCROLL;

  useEffect(() => {
    if (previousIsLoading && !isLoading && !allItemsLoaded) {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight -
          VIEWPORT_DISTANCE_TO_SCROLL * window.innerHeight
      ) {
        loadMore();
      }
    }
  }, [
    previousIsLoading,
    isLoading,
    allItemsLoaded,
    VIEWPORT_DISTANCE_TO_SCROLL,
    loadMore,
  ]);

  const onScroll = useCallback(
    throttle(() => {
      let gridDistanceFromPageBottom = 200; // accountig for a generic footer height, on first render
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        gridDistanceFromPageBottom =
          document.body.offsetHeight - (rect.bottom + window.scrollY);
      }

      if (
        !allItemsLoaded &&
        !isLoading &&
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight -
            VIEWPORT_DISTANCE_TO_SCROLL * window.innerHeight -
            gridDistanceFromPageBottom
      ) {
        loadMore();
      }
    }, 200),
    [
      loadMore,
      allItemsLoaded,
      isLoading,
      containerRef.current,
      VIEWPORT_DISTANCE_TO_SCROLL,
    ],
  );

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  const listingsByMonth = useMemo(
    () =>
      listings.reduce((acc, listing) => {
        const year = listing.dates?.[0].split('-')[0];
        const month = listing.dates?.[0].split('-')[1];
        if (!year || !month) return acc;

        const key = `${year}-${month}`;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(listing);

        return acc;
      }, {} as Record<string, ListingType[]>),
    [listings],
  );

  if (isLoading && listings.length === 0) {
    return (
      <DynamicGridLoading
        className={`listing-grid-${product ?? 'all'}-loading`}
        config={gridConfig}
        skeletonCount={5}
      />
    );
  }

  if (!isResults || product !== ProductLine.ACTIVATE) {
    return (
      <DynamicGrid
        className={`listing-grid-${product ?? 'all'}`}
        refProp={containerRef}
        config={gridConfig}
      >
        {listings.map((listing, index) => (
          <ListingItemCard
            key={`${listing.id}-${getFirstListingDate(listing)}`}
            gridArea={`A${index}`}
            listing={listing}
            brandSettings={brandSettings}
          />
        ))}
      </DynamicGrid>
    );
  }

  const availableMonths = Object.keys(listingsByMonth).sort();
  const currentYear = new Date().getFullYear();

  return (
    <ListByMonthWrapper
      className="listing-grid-month-container"
      ref={containerRef}
    >
      {availableMonths.map((yearAndMonth) => {
        const monthFormatted = moment(yearAndMonth, 'YYYY-MM').format('MMMM');
        const cardYear = parseInt(yearAndMonth.split('-')[0], 10);
        const showYear = cardYear !== currentYear;
        return (
          <Fragment key={yearAndMonth}>
            <h2 className="listing-grid-month-title">
              {monthFormatted} {showYear ? cardYear : ''}
            </h2>
            <DynamicGrid
              className="listing-grid-activate-month"
              config={gridConfig}
              style={{
                marginBottom: 80,
              }}
            >
              {listingsByMonth[yearAndMonth].map((listing, index) => (
                <ListingItemCard
                  key={`${listing.id}-${getFirstListingDate(listing)}`}
                  gridArea={`A${index}`}
                  listing={listing}
                  showYear={showYear}
                  brandSettings={brandSettings}
                />
              ))}
            </DynamicGrid>
          </Fragment>
        );
      })}
    </ListByMonthWrapper>
  );
};

const ListByMonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 12px;

  & > h2 {
    margin-bottom: 6px;
    margin-top: 0px;
  }

  & > * {
    width: 100%;
  }
`;

const getFirstListingDate = (listing: ListingType) => {
  return listing.dates?.[0] || listing.firstAvailableDate?.scheduledDate || '';
};
