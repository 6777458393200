import React, { CSSProperties, useMemo, useState } from 'react';
import { isUndefined, noop } from 'lodash';
import moment from 'moment/moment';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import 'components/theme/ReactDate';
import * as SC from 'components/ExperienceView/styles';
import PrevArrowIcon from 'assets/chevron-left';
import NextArrowIcon from 'assets/chevron-right';
import FilledCalendarIcon from 'assets/calendar';
import styled, { css } from 'styled-components';
import { DATE_FORMAT } from 'utils';
import { useIsMobile } from 'WindowDimensionProvider';
import {
  CustomizedCalendarDay,
  KeyFrameWrapper,
  LoadingCalendarDay,
} from './CustomizedCalendarDay';

interface Props
  extends Pick<SingleDatePickerShape, 'initialVisibleMonth' | 'displayFormat'> {
  date: string;
  availableDates: string[];
  onMonthChange: (date: moment.Moment) => void;
  onChange: (date: string) => void;
  maxWidth?: CSSProperties['maxWidth'];
  width?: CSSProperties['width'];
  loading?: boolean;
}

export const DateSelector: React.FC<Props> = ({
  initialVisibleMonth,
  date,
  availableDates,
  onMonthChange = noop,
  onChange,
  displayFormat = 'MMMM Do',
  maxWidth,
  width,
  loading = false,
}) => {
  const isMobile = useIsMobile();

  const isDayHighlighted: SingleDatePickerShape['isDayHighlighted'] = (
    date,
  ) => {
    return !!availableDates?.find((availabilityDate) => {
      return moment(availabilityDate).isSame(date, 'day');
    });
  };

  const isOutsideRange: SingleDatePickerShape['isOutsideRange'] = (date) =>
    !isDayHighlighted(date);

  const [focused, setFocused] = useState(false);

  const handleChange: SingleDatePickerShape['onDateChange'] = (date) => {
    return onChange(date ? date.format(DATE_FORMAT) : '');
  };

  const value = useMemo(() => moment(date), [date]);

  return (
    <DateSelectorWrapper maxWidth={maxWidth} width={width}>
      <SingleDatePicker
        date={value}
        numberOfMonths={1}
        onDateChange={handleChange}
        onPrevMonthClick={onMonthChange}
        onNextMonthClick={onMonthChange}
        isDayHighlighted={isDayHighlighted}
        displayFormat={displayFormat}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        transitionDuration={isMobile ? 0 : 300}
        verticalHeight={350}
        isOutsideRange={isOutsideRange}
        initialVisibleMonth={initialVisibleMonth}
        readOnly
        customCloseIcon={
          <button type="submit" onClick={(e) => e.preventDefault()}>
            hello
          </button>
        }
        navPrev={
          <SC.PrevIconCalendar>
            <PrevArrowIcon color="var(--way-palette-black-100)" />
          </SC.PrevIconCalendar>
        }
        navNext={
          <SC.NextIconCalendar>
            <NextArrowIcon color="var(--way-palette-black-100)" />
          </SC.NextIconCalendar>
        }
        id="DateSelectorkouto_selectedSessionDateAvailabilityCalendarSelector"
        customInputIcon={
          <IconSpan>
            <FilledCalendarIcon />
          </IconSpan>
        }
        showClearDate={false}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        block
        noBorder
        renderCalendarDay={(dayProps) =>
          loading ? (
            <>
              <KeyFrameWrapper />
              <LoadingCalendarDay {...dayProps} />
            </>
          ) : (
            <CustomizedCalendarDay {...dayProps} />
          )
        }
        keepOpenOnDateSelect
      />
    </DateSelectorWrapper>
  );
};

const DateSelectorWrapper = styled.div<{
  maxWidth?: CSSProperties['maxWidth'];
  width?: CSSProperties['width'];
}>`
  height: 48px;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border: 0.5px solid var(--way-colors-borderColor);
  max-width: ${({ maxWidth }) => maxWidth};

  ${({ width }) =>
    !isUndefined(width) &&
    css`
      width: ${width};
    `};

  &:hover {
    background: var(--way-palette-black-5);
  }

  button[type='button'] {
    padding: 0;
    padding-left: 12px;
    margin: 0;
  }

  button[aria-label] {
    display: none;
  }

  > *:first-child {
    flex: 1;
    cursor: pointer;
  }

  > * > * > * {
    display: flex !important;
    background: transparent;

    & > div {
      background: transparent;
    }
  }

  input {
    border: none;
    background: transparent;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
`;

export const IconSpan = styled.span`
  cursor: pointer;
  display: grid;
  place-items: center;
  width: min-content;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;
