import React, { FC, useMemo, useState } from 'react';
import Calendar, { CalendarProps } from 'react-calendar';
import moment from 'moment';
import styled from 'styled-components';
import PrevArrowIcon from 'assets/chevron-left';
import NextArrowIcon from 'assets/chevron-right';
import { DATE_FORMAT } from 'utils';
import 'react-calendar/dist/Calendar.css';

interface Props extends CalendarProps {
  availableDates: string[];
  onMonthChange: (date: moment.Moment) => void;
}

const BottomSheetDateSelector: FC<Props> = ({
  availableDates,
  onMonthChange,
  ...props
}) => {
  const selectedDate = props.value instanceof Date ? props.value : null;

  const [activeDate, setActiveDate] = useState(
    props.activeStartDate || selectedDate || new Date(),
  );

  const isDayDisabled = (date: Date) => {
    const isInvalidDate = moment(moment(date).format(DATE_FORMAT)).isBefore(
      moment().format(DATE_FORMAT),
    );
    const isUnselectable = !availableDates.includes(
      moment(date).format(DATE_FORMAT),
    );
    return isInvalidDate || isUnselectable;
  };

  const shouldShowPreviousLabel = useMemo(() => {
    return moment(activeDate)
      .startOf('month')
      .isAfter(moment().startOf('month'));
  }, [activeDate]);

  return (
    <CalendarWrapper>
      <Calendar
        calendarType="gregory"
        showNeighboringMonth={false}
        tileDisabled={({ date }) => isDayDisabled(date)}
        nextLabel={
          <NextArrowIcon
            color="var(--way-colors-contrastColorShades-100)"
            scale={1.3}
          />
        }
        prevLabel={
          shouldShowPreviousLabel ? (
            <PrevArrowIcon
              color="var(--way-colors-contrastColorShades-100)"
              scale={1.3}
            />
          ) : null
        }
        formatMonthYear={(_, date) => moment(date).format('MMMM')}
        onActiveStartDateChange={({ activeStartDate }) => {
          if (activeStartDate) {
            onMonthChange(moment(activeStartDate));
            setActiveDate(activeStartDate);
          }
        }}
        {...props}
      />
    </CalendarWrapper>
  );
};

const CalendarWrapper = styled.div`
  & * {
    font-family: ${({ theme }) => theme.font.primaryFont};
  }

  & .react-calendar {
    width: 100%;
    border: none;
    background-color: transparent;
  }

  & .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  & .react-calendar__navigation__label {
    pointer-events: none;
    flex-grow: 0 !important;
    color: var(--way-colors-buttonColorShades-100);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.38px;
  }

  & .react-calendar__navigation {
    height: auto;
    margin-bottom: 12px;
  }

  & .react-calendar__month-view__weekdays__weekday > * {
    text-decoration: none;
    color: #3c3c434d;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.078px;
  }

  & .react-calendar__tile--now {
    background: transparent;
  }

  & .react-calendar__tile {
    padding: 10px 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.38px;
    color: var(--way-colors-buttonColorShades-100);

    & abbr {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
  }

  .react-calendar__tile:not(.react-calendar__tile--active):not(:disabled)
    > abbr:hover {
    background-color: #e6e6e6;
  }

  & .react-calendar__navigation button:enabled:focus,
  .react-calendar__navigation button:enabled:hover {
    background-color: transparent;
  }

  & .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: transparent;
  }

  & .react-calendar__month-view__days__day--weekend {
    color: var(--way-colors-buttonColorShades-100);
  }

  & .react-calendar__tile--active {
    background-color: transparent;

    & abbr {
      background-color: var(--way-colors-buttonColorShades-80);
      color: var(--way-colors-buttonColorContrastShades-80);
      border-radius: 50%;

      &:hover {
        background-color: var(--way-colors-buttonColorShades-100);
        color: var(--way-colors-buttonColorContrastShades-100);
      }
    }
  }

  & .react-calendar__tile:disabled {
    background-color: transparent;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default BottomSheetDateSelector;
