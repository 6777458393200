import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import sumBy from 'lodash/sumBy';
import { useTranslation } from 'react-i18next';
import { ITier } from 'features/ShoppingCart/types';
import { ResourceGroup } from 'types/listings';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import { currencyFormat, getPercentageAmount } from 'utils';
import { Flex, Sep } from 'components/common/styled/common-styled';

interface Props {
  priceTiers: Array<ITier>;
  title: string;
  taxes: ResourceGroup['taxes'];
  imageUri: ResourceGroup['pictures'][number]['uri'];
  applyFlatFeeToEachTier: boolean;
}

const sumUpTotal = (tiers: ITier[]): number => {
  if (!tiers.length) {
    return 0;
  }
  return tiers.reduce<number>((sum, tier) => {
    const summedAmount = sum + tier.price * tier.selectedNumber;
    return summedAmount;
  }, 0);
};

const PriceTiersSummary: FC<Props> = ({
  priceTiers,
  title,
  taxes,
  imageUri,
  applyFlatFeeToEachTier,
}) => {
  const currency = useBrandCurrency();
  const { formattedNumber } = useCurrencyFormatter();
  const { t: translate } = useTranslation();

  const includedTaxes = useMemo(() => {
    return (
      taxes
        ?.filter((tax) => tax.includedInPrice)
        .map((t) => ({
          name: t.name,
          percentage: 'percentage' in t ? t.percentage : 0,
        })) ?? []
    );
  }, [taxes]);

  const nonIncludedTaxes = useMemo(() => {
    return taxes?.filter((tax) => !tax.includedInPrice) ?? [];
  }, [taxes]);

  const mapNonIncludedTaxes = useMemo(() => {
    const total = sumUpTotal(priceTiers);

    const selectedPriceTiersCount = priceTiers.reduce(
      (acc, tier) => acc + tier.selectedNumber,
      0,
    );
    /* eslint-disable no-nested-ternary */
    return nonIncludedTaxes
      .map((tax) => {
        return {
          ...tax,
          amountFee:
            'percentage' in tax
              ? getPercentageAmount(tax.percentage)(total)
              : applyFlatFeeToEachTier
              ? tax.amount * selectedPriceTiersCount
              : tax.amount,
        };
      })
      .filter((t) => !!t.amountFee);
    /* eslint-enable no-nested-ternary */
  }, [priceTiers, nonIncludedTaxes, applyFlatFeeToEachTier]);

  const netCartAmount = useMemo(() => {
    const totalAmount = sumUpTotal(priceTiers);

    const totalNetAmount =
      totalAmount + sumBy(mapNonIncludedTaxes, 'amountFee');

    return totalNetAmount;
  }, [priceTiers, mapNonIncludedTaxes]);

  return (
    <SummaryWrapper>
      <WrapperDiv>
        <ResponsiveImage
          className="resource-group-summary__resource-image"
          uriObject={imageUri}
          defaultSize="384w"
          viewType="small"
          style={{
            borderRadius: 0,
          }}
          CustomRenderer={ResourceGroupImage}
          alt={title}
          pictureStyle={`
            display: grid;
            place-items: center;
          `}
        />
      </WrapperDiv>
      <Sep />
      <WrapperDiv>
        <Flex direction="column" alignItem="flex-start" gap={20}>
          {priceTiers.map((tier) => {
            if (!tier.selectedNumber) {
              return null;
            }
            return (
              <Flex key={tier.name} direction="row" width="100%">
                <ResourceParagraph
                  className="resource-group-summary__price-tier"
                  color="var(--way-colors-secondaryTextColor)"
                  fontSize="14px"
                  fontWeight="400"
                >
                  {tier.name} (x{tier.selectedNumber})
                </ResourceParagraph>
                {currency && (
                  <ResourceParagraph
                    className="resource-group-summary__tier-amount"
                    color="var(--way-colors-secondaryTextColor)"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    {currencyFormat(currency)(formattedNumber(tier.price))}
                  </ResourceParagraph>
                )}
              </Flex>
            );
          })}
          {nonIncludedTaxes.length >= 1 &&
            mapNonIncludedTaxes.map((tax) => {
              return (
                <Flex key={tax.id} direction="row" width="100%">
                  <ResourceParagraph
                    className="resource-group-summary__tax"
                    color="var(--way-colors-secondaryTextColor)"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    {tax.name}
                  </ResourceParagraph>
                  {currency && (
                    <ResourceParagraph
                      className="resource-group-summary__tax-amount"
                      color="var(--way-colors-secondaryTextColor)"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      {currencyFormat(currency)(formattedNumber(tax.amountFee))}
                    </ResourceParagraph>
                  )}
                </Flex>
              );
            })}

          <Flex direction="row" width="100%">
            <ResourceParagraph
              className="resource-group-summary__total"
              color="var(--way-colors-secondaryTextColor)"
              fontSize="14px"
              fontWeight="700"
            >
              {translate('total')}:
            </ResourceParagraph>
            {currency && (
              <ResourceParagraph
                className="resource-group-summary__total-amount"
                color="var(--way-colors-secondaryTextColor)"
                fontSize="14px"
                fontWeight="700"
              >
                {netCartAmount > 0
                  ? currencyFormat(currency)(formattedNumber(netCartAmount))
                  : '-'}
              </ResourceParagraph>
            )}
          </Flex>
          {netCartAmount > 0 && (
            <Flex direction="row" width="100%">
              <ResourceParagraph
                className="resource-group-summary__included-taxes"
                color="var(--way-colors-secondaryTextColor)"
                fontSize="12px"
                fontWeight="100"
              >
                * {translate('includes')}{' '}
                {includedTaxes
                  .map((t) => `${t.name} (${t.percentage || 0}%)`)
                  .join(` ${translate('and')} `)}
              </ResourceParagraph>
            </Flex>
          )}
        </Flex>
      </WrapperDiv>
    </SummaryWrapper>
  );
};

const WrapperDiv = styled.div`
  padding: 24px;
`;

const SummaryWrapper = styled.div`
  border: 0.5px solid var(--way-colors-borderColor);
  box-shadow: var(--way-design-boxShadow-s);
  max-width: 350px;
  height: auto;
  background: var(--way-palette-white-10);
  align-self: stretch;
`;

const ResourceGroupImage = styled.img`
  object-fit: cover;
  user-select: none;
  max-height: 100%;
  object-position: center;
  height: 254px;
  width: 100%;
`;

export default PriceTiersSummary;
