import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import RadioButton from 'components/theme/RadioButton/RadioButton';
import { formatTimeInAMPM } from 'utils';

const RadioWrapper = ({ sessionTimes, value, ...props }) => {
  const { t: translate } = useTranslation();
  const getSlotsMessage = (supportedCount) => {
    if (supportedCount > 1)
      return translate('slotsLeft', {
        remainSlots: supportedCount,
      });
    return translate('slotLeft', { remainSlots: supportedCount });
  };

  return (
    <RadioGroupWrapper>
      {sessionTimes?.map(
        ({ startTime, isDisabled, supportedParticipantsCount }) => {
          const shouldShowSlots =
            supportedParticipantsCount > 0 &&
            supportedParticipantsCount <= 10 &&
            !isDisabled;
          return (
            <SessionTimeWrapper key={`start-time-${startTime}`}>
              <RadioButton
                disabled={isDisabled}
                value={startTime}
                checked={value === startTime}
                {...props}
              >
                {formatTimeInAMPM(startTime)}
              </RadioButton>

              <ParticipantSlots isLimitExceed={isDisabled}>
                {shouldShowSlots && getSlotsMessage(supportedParticipantsCount)}
              </ParticipantSlots>
            </SessionTimeWrapper>
          );
        },
      )}
    </RadioGroupWrapper>
  );
};

const RadioGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
`;

const SessionTimeWrapper = styled.span`
  text-align: center;
  min-height: 50px;
`;

const ParticipantSlots = styled.span`
  font-size: 12px;
  color: var(--way-colors-secondaryTextColor);
  opacity: ${({ isLimitExceed }) => (isLimitExceed ? 0.5 : 1)};
  font-style: italic;
`;

RadioWrapper.propTypes = {
  sessionTimes: PropTypes.array.isRequired,
  value: PropTypes.string,
};

RadioWrapper.defaultProps = {
  value: '',
};

export default RadioWrapper;
