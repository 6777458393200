import React, { FC, useMemo } from 'react';
import { useAppState, useBrandId } from 'AppProvider';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'components/theme/Wrapper';
import { Flex } from 'components/common/styled/common-styled';
import { NavigationHeader } from 'components/NavigationHeader';
import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';

import Hero from 'components/Hero/Hero';
import { ReducerState } from 'types/reducer';
import FeaturedExperiences from 'components/FeaturedExperiences/FeaturedExperiences';
import Spinner from 'components/Spinner/Spinner';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { getAnalyticsItemsFromProductsListing } from 'features/analytics';
import LandingPageHeader from './components/LandingPageHeader';
import useGetListings from './hooks/useGetListings';

import { DynamicListingsGrid } from './components/DynamicListingsGrid';
import { ProductsList } from './components/ProductsList/ProductsList';
import NoResultsPage from './components/NoResultsPage';

export const AllProductsLandingPage: FC = () => {
  const brandId = useBrandId();
  const { t: translate } = useTranslation();
  const {
    listings,
    loadMore,
    allItemsLoaded,
    isLoadingInitialData,
    isLoadingMore,
  } = useGetListings(brandId);

  const { isResults, product } = useLandingPageRouteParams();
  const settings = useAppState((state: ReducerState) => state.brand.settings);

  const analyticsItems = useMemo(() => {
    const items = getAnalyticsItemsFromProductsListing({
      listings,
    });

    const data = items.reduce(
      (acc, item) => {
        acc.ids.push(item.id ?? '');
        acc.titles.push(item.title ?? '');

        acc.categories.push(item.category ?? '');
        return acc;
      },
      {
        ids: [] as string[],
        titles: [] as string[],
        categories: [] as string[],
      },
    );

    return { items, data };
  }, [listings]);

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_HOME,
    payload: {
      [ANALYTICS_PROPERTY.ListName]: 'All Products',
      [ANALYTICS_PROPERTY.AllExperiences]:
        analyticsItems.data.titles.join(';') || '',
      [ANALYTICS_PROPERTY.AllCategories]:
        analyticsItems.data.categories.join(';') || '',
      [ANALYTICS_PROPERTY.ExperienceCount]:
        analyticsItems.items?.length?.toString(),
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(analyticsItems.items),
    },
    isNotReady: !listings.length,
  });

  return (
    <>
      {!isResults && !product && <Hero settings={settings} />}
      <Wrapper
        style={{
          position: 'relative',
          zIndex: 5,
        }}
      >
        {!isResults && !product && <FeaturedExperiences />}
        {isResults ? (
          <NavigationHeader />
        ) : (
          <Flex gap={72}>
            <ProductsList title={translate('discoverExperiences')} />
          </Flex>
        )}

        <LandingPageHeader />

        {isResults && isLoadingInitialData && (
          <Spinner
            style={{
              marginTop: '100px',
              minHeight: '300px',
            }}
          />
        )}

        {(!isResults || (listings.length > 0 && !isLoadingInitialData)) && (
          <DynamicListingsGrid
            listings={listings}
            loadMore={loadMore}
            allItemsLoaded={allItemsLoaded}
            isLoading={isLoadingMore}
          />
        )}

        {isResults && listings.length === 0 && !isLoadingInitialData && (
          <NoResultsPage />
        )}
      </Wrapper>
    </>
  );
};
