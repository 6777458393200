import React, { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';
import RadioButton from 'components/theme/RadioButton/RadioButton';

interface IOption {
  label: string;
  value: string | number;
}

interface IProps {
  options: IOption[];
  value?: string | number;
  name: string;
  onChange: (value: string | number | null) => unknown;
  wrap?: boolean;
}

export const RadioButtons: FC<IProps> = ({
  options,
  value = '',
  name,
  onChange,
  wrap = true,
}) => {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      onChange(e.target.value);
    } else {
      onChange(null);
    }
  };
  return (
    <RadioGroupWrapper>
      {options.map((option) => (
        <OptionWrapper wrap={wrap} key={option.value}>
          <RadioButton
            disabled={false}
            value={option.value}
            checked={value === option.value}
            onChange={handleOnChange}
            name={name || ''}
          >
            {option.label}
          </RadioButton>
        </OptionWrapper>
      ))}
    </RadioGroupWrapper>
  );
};

export const RadioGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
`;

const OptionWrapper = styled.span<{ wrap: boolean }>`
  text-align: center;
  min-height: 50px;
  white-space: ${({ wrap }) => (wrap ? undefined : 'nowrap')};
`;
