export default {
  reviewCompleteHeader: '검토 및 완료',
  participants: '참여자',
  enterParticipantInfoText:
    '체험판에 참가자들 정보를 입력해 주세요. 남는 공간이 있을 경우, 참가자들을 더 추가해 주세요. ',
  purchaser: '구매자',
  firstName: '이름',
  lastName: '성',
  email: '이메일',
  optionalEmail: '이메일:(선택 사항)',
  'phone:': '전화 번호',
  duplicatePurchaserText: '구매자 정보와 같음',
  phone: '전화 번호:(선택 사항)',
  addParticipant: '추가 {{tierLabel}}',
  participant: '참가자',
  removeParticipant: '삭제',
  experienceLimitReached: '죄송합니다. 최대 참가자 수에 도달했습니다.',
  payment: '결제',
  duration: '기간',
  discountCode: '할인 코드',
  removeCode: '코드 삭제',
  invalidCodeValidation: '쿠폰 코드 불일치',
  networkErrorDiscountCode: '저희 측에서 문제가 발생했습니다.',
  discount: '할인',
  promo: 'Promo',
  promoCode: 'Promo code',
  apply: '신청하기',
  total: '합계',
  subTotal: 'Subtotal',
  includes: 'Includes',
  and: 'and',
  addHostNotes: '호스트를 위해 노트를 추가해 주세요.',
  policyAggreementText:
    '<0>서비스 약관</0> 및 <1>개인 정보 보호 정책에</1> 동의합니다',
  brandAggreementText:
    '본인은 본 참가자를 대신하여 다음 이용 약관에 동의 할 권한이 있음을 동의하거나 증명합니다.',
  termsAggreementValidation: '약관에 동의해 주세요',
  firstNameValidation: '이름을 입력해 주세요',
  lastNameValidation: '성을 입력해 주세요',
  emailValidation: {
    'Invalid Email Address': '잘못된 이메일 주소',
    emailRequiredText: '이메일은 필수 입력 항목입니다',
    not_specified: '잘못된 이메일 주소입니다.',
  },
  phoneValidation: {
    'Invalid Phone Number': '잘못된 전화 번호',
    not_specified: '유효하지 않은',
  },
  participantFirstNameValidation: '참가자 이름은 필수 입력 항목입니다',
  participantLastNameValidation: '참가자 성은 필수 입력 항목입니다',
  complete: '완료',
  '404Message': '찾을 수 없음',
  waiting: '대기 중...',
  complimentary: '무료',
  confirmationHeader: '체험 예약이 완료되었습니다!',
  confirmationText:
    '{{email}} 로 추가 세부 정보가 포함된 확인 이메일이 전송되었습니다.',
  viewAll: '모든 체험 보기',
  paymentMethod: '결제 방법',
  creditCard: '신용카드',
  roomCharge: '방 요금',
  memberCharge: '회원 요금',
  memberNumber: '회원 번호',
  roomNumber: '방 번호',
  kicc: 'Easy Pay',
  chargeValidation: '방 번호와 성은 필수 입력 항목입니다',
  memberNumberValidation: '회원 번호와 성은 필수 입력 항목입니다',
  invalidMemberNumber: '회원 번호가 잘못되었습니다.',
  invalidRoomNumber: '방 번호가 올바르지 않습니다',
  cardNumberIncomplete: '카드 번호 미완료',
  lastNameValidationError: '성은 필수 입력 항목입니다',
  isReqd: '은(는) 필수입니다',
  private: '비공개',
  browseAllExperieces: '모든 체험 둘러보기',
  payWithCreditCard: '신용카드로 결제',
  payWithRoomCharge: '방 요금으로 결제',
  payWithMemberCharge: '회원 요금으로 결제',
  payWith: '결제 방법',
  bookBulkErrorByCard:
    '예약 확인 중 문제가 발생했습니다. 카드가 청구되지 않았습니다.',
  bookBulkErrorByRoomCharge: '잘못된 성 및 객실 번호입니다.',
  bookBulkErrorByMemberCharge: '잘못된 성과 회원번호입니다.',
};
