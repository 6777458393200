/* eslint-disable default-case, consistent-return */
import { ListingKind, ProductLine, PublicListingSchema } from '@kouto/types';
import { z } from 'zod';
import { serializeParams } from 'utils';
import { QUESTION_SCOPES } from 'types/custom-questions';
import { Listing } from 'types/listings';

export type ListingType = z.infer<typeof PublicListingSchema>;

export const getListingProductLine = (listing: ListingType): ProductLine => {
  if (listing.productLine) return listing.productLine;

  switch (listing.kind) {
    case ListingKind.EVENT:
      return ProductLine.ACTIVATE;
    case ListingKind.RESOURCE:
      return ProductLine.RESERVE;
    case ListingKind.EXPERIENCE:
      return listing.hostedBy ? ProductLine.HOST : ProductLine.RESERVE;
  }
};

export const getListingRoute = (
  listing: ListingType,
): {
  pathname: string;
  search: string;
} => {
  let pathname = '';
  let search = '';

  switch (listing.kind) {
    case ListingKind.EVENT:
      pathname = `/event/${listing.id}`;
      if (listing.dates && listing.dates[0]) {
        search = serializeParams({
          preselectedDate: listing.dates[0],
        });
      }
      break;
    case ListingKind.RESOURCE:
      pathname = `/collection/${listing.id}`;
      break;
    case ListingKind.EXPERIENCE:
      pathname = `/e/${listing.slug}`;
  }

  return {
    pathname,
    search,
  };
};

export const getProductPageTitle = ({
  product,
  translate,
}: {
  product?: ProductLine;
  translate: (label: string, options?: Record<string, string>) => string;
}): string => {
  if (!product) return translate('mainLandingPageTitle');

  switch (product) {
    case ProductLine.ACTIVATE:
      return translate('activateLandingPageTitle');
    case ProductLine.RESERVE:
      return translate('reserveLandingPageTitle');
    case ProductLine.HOST:
      return translate('hostLandingPageTitle');
  }
};

export const getProductCardButtonLabel = (
  product: ProductLine,
  translate: (label: string) => string,
): string => {
  switch (product) {
    case ProductLine.ACTIVATE:
      return translate('buyTickets');
    case ProductLine.RESERVE:
      return translate('reserve');
    case ProductLine.HOST:
      return translate('buyTickets');
  }
};

export const getProductCardLabel = (
  product: ProductLine,
  translate: (label: string) => string,
): string => {
  switch (product) {
    case ProductLine.ACTIVATE:
      return translate('activateProductCardTitle');
    case ProductLine.RESERVE:
      return translate('reserveProductCardTitle');
    case ProductLine.HOST:
      return translate('hostProductCardTitle');
  }
};

export const listingShouldGoThroughParticipantsPage = (
  listing?: Listing,
  selectedGroupId?: string,
  selectedExperienceId?: string,
) => {
  let hasCustomQuestions = false;
  let askPartiticpantsInfo = false;
  let addOns = [];

  if (listing) {
    const selectedGroup = listing.resourceGroups?.find(
      (rg) => rg.id === selectedGroupId,
    );

    addOns = [...listing.addOns, ...(selectedGroup?.addOns || [])];

    if (listing.kind === ListingKind.EXPERIENCE) {
      askPartiticpantsInfo = true;
      // hasCustomQuestions = listing.customQuestions.length // TODO there are no customQuestions on listing root at the moment
    }

    if (selectedGroup) {
      const selectedResource = selectedGroup.experiences.find(
        (r) => r.id === selectedExperienceId,
      );
      if (selectedResource) {
        hasCustomQuestions = !!selectedResource.customQuestions.find(
          (q) => !q.config.isDefault && q.config.isActive,
        );
        askPartiticpantsInfo =
          selectedResource.customQuestions[0].config.scope ===
          QUESTION_SCOPES.PARTICIPANT;
      }
    }
  }

  return {
    hasAddOns: addOns.length > 0,
    askPartiticpantsInfo,
    hasCustomQuestions,
  };
};
