import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import { PriceTierWithParticipants } from 'components/Booking/PriceTier/types';
import PriceTiersSummary from 'components/Resource/PriceTiersSummary';

interface Props {
  priceTiers: PriceTierWithParticipants[];
}

const TicketSummary: FC<Props> = ({ priceTiers }) => {
  const { isLoading, event } = useEventBookingSession();

  const formatterPriceTiers = useMemo(() => {
    return priceTiers.map((tier) => {
      return {
        ...tier,
        selectedNumber: tier.participants,
      };
    });
  }, [priceTiers]);

  const firstImageUri = useMemo(() => {
    let images = event?.pictures || [];
    images = images.filter((image) => image.kind === 'cover');

    return images[0]?.uri || {};
  }, [event]);

  const taxes = useMemo(() => {
    return (
      event?.taxes ||
      (event?.resourceGroups && event.resourceGroups[0]?.taxes) ||
      []
    );
  }, [event]);

  if (isLoading || !event) {
    return (
      <Wrapper>
        <SkeletonLineSlim />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PriceTiersSummary
        priceTiers={formatterPriceTiers}
        title={event.title}
        taxes={taxes}
        imageUri={firstImageUri}
        applyFlatFeeToEachTier={false}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 350px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export default TicketSummary;
