import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import flow from 'lodash/fp/flow';

import format from 'date-fns/fp/format';
import subMonths from 'date-fns/fp/subMonths';
import addMonths from 'date-fns/fp/addMonths';
import addDays from 'date-fns/fp/addDays';
import startOfMonth from 'date-fns/fp/startOfMonth';
import endOfMonth from 'date-fns/fp/endOfMonth';
import startOfWeek from 'date-fns/fp/startOfWeek';
import endOfWeek from 'date-fns/fp/endOfWeek';
import isSameDay from 'date-fns/fp/isSameDay';
import isBefore from 'date-fns/fp/isBefore';
import eachDayOfInterval from 'date-fns/fp/eachDayOfInterval';
import getDate from 'date-fns/fp/getDate';

import { useIsMobile } from 'WindowDimensionProvider';
import PrevIcon from 'assets/chevron-left';
import NextIcon from 'assets/chevron-right';

import { SearchAllWrapperStart } from 'components/theme/SearchAllWrapperStart';
import { LinksBack } from 'components/ExperienceView/styles';
import useCustomHistory from 'hooks/use-custom-history';
import { serializeParams } from 'utils';
import useSearchQueryParams from 'hooks/use-search-params';
import { CartIcon } from 'features/ShoppingCart/components/CartIcon/CartIcon';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';

const NUM_DAYS_WEEK = 7;

/* replacement for lodash fp methods that didn't work as expected */
const fill = (total) => (data) => {
  return new Array(total).fill(data);
};

const map = (iterator) => (data) => {
  return data.map(iterator);
};

export const getMonthStartDate = (currentMonth) => {
  const monthStart = startOfMonth(currentMonth);
  return startOfWeek(monthStart);
};

export const getMonthEndDate = (currentMonth) => {
  const monthEnd = endOfMonth(currentMonth);
  return endOfWeek(monthEnd);
};

const arrayToMatrix = (array, columns) =>
  Array(Math.ceil(array.length / columns))
    .fill('')
    .reduce((acc, cur, index) => {
      return [...acc, [...array].splice(index * columns, columns)];
    }, []);

export default function Calendar({
  currentMonth,
  onDateSelected,
  onMonthChange,
  navRenderer,
  itemRenderer,
}) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [currentMonthState, setCurrentMonthState] = useState(currentMonth);
  const [selectedDate, setSelectedDate] = useState(
    isMobile ? new Date() : null,
  );

  const isCartFeatureEnabled = useBrandToggleFeature('shoppingCart');

  const { push } = useCustomHistory();
  const { searchParams } = useSearchQueryParams();

  const handleNavigateToHomePage = () => {
    push({
      pathname: `/`,
      search: serializeParams(searchParams),
    });
  };

  useEffect(
    function setCurrentMonthFromProps() {
      if (currentMonth) {
        setCurrentMonthState(currentMonth);
      }
    },
    [currentMonth],
  );

  const prevMonth = () => {
    const subtractOneMonth = subMonths(1);
    const newMonth = subtractOneMonth(currentMonthState);
    setCurrentMonthState(newMonth);
    setSelectedDate(newMonth);
    onMonthChange(newMonth);
  };

  const nextMonth = () => {
    const addOneMonth = addMonths(1);
    const newMonth = addOneMonth(currentMonthState);
    setCurrentMonthState(newMonth);
    setSelectedDate(newMonth);
    onMonthChange(newMonth);
  };

  const renderHeader = () => {
    const formattedMonth = 'MMMM';
    const yearFormat = 'yyyy';

    const formatToMonthYear = format(yearFormat);
    const requiredMonth = format(formattedMonth)(currentMonthState);
    const translatedMonth = t(`months_calendar.${requiredMonth}`);

    return (
      <>
        <Navigation>
          <NavigationIcons>
            <IconContainer
              className="calendar__icon-container"
              onClick={prevMonth}
            >
              <PrevIcon />
            </IconContainer>
            <IconContainer
              className="calendar__icon-container"
              onClick={nextMonth}
            >
              <NextIcon />
            </IconContainer>
          </NavigationIcons>
          <CurrentMonth>
            {translatedMonth} {formatToMonthYear(currentMonthState)}
          </CurrentMonth>
          {!isMobile &&
            navRenderer({
              currentMonth: currentMonthState,
              isCartFeatureEnabled,
            })}
        </Navigation>
        {isMobile &&
          navRenderer({
            currentMonth: currentMonthState,
            isCartFeatureEnabled,
          })}
      </>
    );
  };

  const renderHeaderDays = () => {
    const dateFormat = 'EEE';
    const startDate = startOfWeek(currentMonthState);

    const headingDayViewMapper = (_, index) => {
      const dayName = flow(addDays(index), format(dateFormat))(startDate);

      return (
        <DayHeadingItem key={`day-${dayName}`}>
          {t(`weekdays_calendar.${dayName}`)}
        </DayHeadingItem>
      );
    };

    const days = flow(fill(NUM_DAYS_WEEK), map(headingDayViewMapper))(null);

    return <DayHeadingRow>{days}</DayHeadingRow>;
  };

  const handleCurrentDateClick = (currentDate) => () => {
    setSelectedDate(currentDate);
    onDateSelected(currentDate);
  };

  const renderAllDates = () => {
    const monthStart = startOfMonth(currentMonthState);
    const startDate = startOfWeek(monthStart);
    const monthEnd = endOfMonth(currentMonthState);
    const endDate = endOfWeek(monthEnd);

    const cellDateFormat = 'd';
    const monthFormat = 'MMM';

    const allDatesInMonth = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    const datesGroupedByRow = arrayToMatrix(allDatesInMonth, 7);

    const currentDateViewMapper = (currentDate) => {
      const formattedDate = format(cellDateFormat)(currentDate);
      const isToday = isSameDay(new Date())(currentDate);
      const isPastDate = isBefore(new Date())(currentDate);
      const isSelected = isMobile && isSameDay(selectedDate)(currentDate);
      return (
        <DateItem
          className="calendar__date-item-container"
          key={currentDate}
          isSelected={isSelected}
          onClick={handleCurrentDateClick(currentDate)}
        >
          <DateNum
            className="calendar__date-number"
            isToday={isToday}
            isPastDate={isPastDate}
          >
            {getDate(currentDate) === 1
              ? format(monthFormat)(currentDate)
              : null}{' '}
            {formattedDate}
          </DateNum>
          <DateContent className="calendar__date-content">
            {itemRenderer({
              currentDate,
              isSelected,
              isToday,
              isPastDate,
            })}
          </DateContent>
        </DateItem>
      );
    };

    const calendarWeekViewMapper = (daysInWeek, rowIndex) => {
      const columns = map(currentDateViewMapper)(daysInWeek);
      return (
        <DateRowContainer key={`calendar-week-${rowIndex}`}>
          {columns}
        </DateRowContainer>
      );
    };

    const weekRows = map(calendarWeekViewMapper)(datesGroupedByRow);

    return weekRows;
  };
  return (
    <div>
      {isCartFeatureEnabled === true && (
        <SearchAllWrapperStart noPadding>
          <LinksBack
            className="calendar__navigation-back-link"
            onClick={handleNavigateToHomePage}
          >
            <PrevIcon height="24px" />
            &nbsp;
            {t('searchAllExperiences')}
          </LinksBack>
          <CartIcon />
        </SearchAllWrapperStart>
      )}
      {renderHeader()}
      <div>
        {renderHeaderDays()}
        {renderAllDates()}
      </div>
    </div>
  );
}

Calendar.propTypes = {
  currentMonth: PropTypes.instanceOf(Date).isRequired,
  onDateSelected: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  navRenderer: PropTypes.func.isRequired,
  itemRenderer: PropTypes.func.isRequired,
};

const Navigation = styled.div`
  color: var(--way-colors-primaryTextColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 11px;
  font-family: ${({ theme }) => theme.font.secondaryFont};
`;

const CurrentMonth = styled.div`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: normal !important;
  margin-right: 11px;
  max-width: 150px;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  @media (min-width: 768px) {
    max-width: 220px;
    font-size: 22px !important;
    line-height: 34px !important;
  }
`;

const NavigationIcons = styled.div`
  display: flex;
`;

const IconContainer = styled.span`
  margin-right: 9px;
  cursor: pointer;

  &.calendar__icon-container {
    min-width: 16px;
    display: grid;
    place-items: center;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DayHeadingRow = styled(RowContainer)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const DateRowContainer = styled(RowContainer)`
  &:last-child > div {
    border-bottom: 0.5px solid #e0e0e0;
  }
`;

const CellItem = styled.div`
  width: 100%;
  min-width: 50px;
  max-width: 150px;
  box-sizing: border-box;
  padding: 5px;
  font-style: normal !important;
  font-size: 10px !important;
  line-height: 12px !important;
  letter-spacing: normal !important;
  color: var(--way-colors-primaryTextColor);
  @media (min-width: 768px) {
    padding: 12px;
    max-width: 182px;

    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }
`;

const DayHeadingItem = styled(CellItem)`
  font-family: ${({ theme }) => theme.font.secondaryFont};
`;

const DateItem = styled(CellItem)`
  min-height: 50px;
  max-height: 150px;
  @media (min-width: 768px) {
    max-height: 213px;
    min-height: 152px;
    overflow-y: auto;
  }
  border: ${({ isSelected }) => {
    return isSelected
      ? '1px solid var(--way-colors-primaryTextColor)'
      : '1px solid #E0E0E0';
  }};
  border-right: ${({ isSelected }) => {
    return isSelected
      ? '1px solid var(--way-colors-primaryTextColor)'
      : '1px solid transparent';
  }};
  border-bottom: ${({ isSelected }) => {
    return isSelected
      ? '1px solid var(--way-colors-primaryTextColor) !important'
      : '1px solid transparent';
  }};
  &:last-child {
    border-right: ${({ isSelected }) => {
      return isSelected
        ? '1px solid var(--way-colors-primaryTextColor)'
        : '1px solid #E0E0E0';
    }};
  }
  &:hover {
    border-bottom: 1px solid var(--way-colors-primaryTextColor) !important;
  }
`;

const DateNum = styled.div`
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: ${({ isToday, isPastDate }) => {
    if (!isToday && isPastDate) {
      return 'var(--way-palette-black-50)';
    }
    return isToday ? 'var(--way-colors-primaryColorShades-100)' : 'inherit';
  }};
`;

const DateContent = styled.div`
  margin-top: 5px;
`;
