import React from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import type { ResourceGroup } from 'types/listings';
import { TruncatedText, TruncationMode } from 'components/TruncatedText';

interface Props {
  group: ResourceGroup;
  maxLines: number;
  customStyles?: ReturnType<typeof css>;
  onReadMore: () => void;
}

export const ResourceGroupDetail: React.FC<Props> = ({
  group,
  maxLines,
  customStyles,
  onReadMore,
}) => {
  const { t: translate } = useTranslation();

  return (
    <div>
      <TruncatedText
        mode={TruncationMode.INLINE}
        text={group.description}
        maxLines={maxLines}
        isRichText
        onShowMore={onReadMore}
        customStyles={customStyles}
        showLessText={translate('viewLess')}
        showMoreText={translate('viewMore')}
        textBottomGap={8}
      />
    </div>
  );
};
