import themePalette from './themePalette';

const themeState = {
  isBaseTheme: true,
  mode: 'default',
  palette: themePalette,
  colors: {
    primaryColor: '#AB9656',
    secondaryColor: '#E7EAED',
    buttonColor: '#AB9656',
    primaryTextColor: themePalette.black[100],
    secondaryTextColor: themePalette.black[100],
    linkColor: '#031C3A',
    borderColor: themePalette.black[20],
    borderHoverColor: themePalette.black[40],
    borderSelectedColor: themePalette.black[100],
    svgColor: '#051E39',
    disabledInput: themePalette.black[20],
    inputColor: themePalette.black[100],
    calendarInputColor: '#051E39',
    contrastColorShades: themePalette.black,
    coherentColorShades: themePalette.white,
  },
  darkColors: {
    primaryColor: '#AB9656',
    secondaryColor: '#E7EAED',
    buttonColor: '#AB9656',
    primaryTextColor: themePalette.white[100],
    secondaryTextColor: themePalette.white[100],
    linkColor: themePalette.white[100],
    borderColor: themePalette.white[20],
    borderHoverColor: themePalette.white[40],
    borderSelectedColor: themePalette.white[100],
    svgColor: themePalette.white[100],
    disabledInput: themePalette.black[70],
    inputColor: themePalette.white[100],
    calendarInputColor: themePalette.white[100],
    contrastColorShades: themePalette.white,
    coherentColorShades: themePalette.black,
  },
  design: {
    borderRadiusDefault: '0px',
    heroBoxShadow: {
      xl: '12px 12px 40px 0px rgba(0, 0, 0, 0.25), 60px 60px 110px 0px rgba(0, 0, 0, 0.25)',
      l: '0px 4px 4px -4px rgba(0, 0, 0, 0.15), 0px 12px 36px 0px rgba(0, 0, 0, 0.30)',
      m: '0px 2px 2px -2px rgba(0, 0, 0, 0.10), 0px 6px 18px 0px rgba(0, 0, 0, 0.20)',
      s: '0px 1px 1px 0px rgba(0, 0, 0, 0.06), 2px 4px 4px -1px rgba(0, 0, 0, 0.10)',
    },
    boxShadow: {
      s: '0px 4px 6px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.06)',
      l: '0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 30px 0px rgba(0, 0, 0, 0.12), 0px 3px 17px 0px rgba(0, 0, 0, 0.04)',
    },
  },
  darkDesign: {
    heroBoxShadow: {
      xl: '12px 12px 40px 0px rgba(0, 0, 0, 0.50), 60px 60px 110px 0px rgba(0, 0, 0, 0.50)',
      l: '0px 4px 4px -4px rgba(0, 0, 0, 0.30), 0px 12px 36px 0px rgba(0, 0, 0, 0.60)',
      m: '0px 2px 2px -2px rgba(0, 0, 0, 0.20), 0px 6px 18px 0px rgba(0, 0, 0, 0.40)',
      s: '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 2px 4px 4px -1px rgba(0, 0, 0, 0.20)',
    },
    boxShadow: {
      s: '0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.20)',
      l: '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 4px 30px 0px rgba(0, 0, 0, 0.26), 0px 3px 17px 0px rgba(0, 0, 0, 0.12)',
    },
  },
  font: {
    primaryFont: 'inherit, sans-serif',
    secondaryFont: 'inherit, sans-serif',
  },
};

export default themeState;
