import fetchApi from 'utils/fetch';
import { Question } from 'types/custom-questions';

export const fetchExperience = async (
  experienceId: string,
  brandId: string,
): Promise<unknown> => {
  return fetchApi
    .get(`v1/brands/${brandId}/experiences/${experienceId}`)
    .json();
};

export const fetchCustomQuestions = async (
  experienceId: string,
  brandId: string,
): Promise<{ items: Question[] }> => {
  return fetchApi
    .get(
      `v1/brands/${brandId}/experiences/${experienceId}/custom-questions?isActive=true`,
    )
    .json();
};
