import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledImg = styled.img``;
const ScrollPicture = styled.picture`
  height: 100%;

  ${({ pictureStyle }) => pictureStyle};
`;
const ScrollSource = styled.source``;

const getSrcSet = (uriObject, includeSizes) => {
  if (!uriObject) return null;
  const imgSizesList = Object.keys(uriObject)
    .filter((size) => includeSizes.includes(size))
    .map((size) => {
      return `${uriObject[size]} ${size}`;
    })
    .join(',');
  return imgSizesList;
};

function Picture({ uriObject, children, viewType, pictureStyle }) {
  const PictureComponent = ScrollPicture;
  const SourceComponent = ScrollSource;
  return (
    <PictureComponent pictureStyle={pictureStyle}>
      <SourceComponent
        srcSet={getSrcSet(uriObject, [
          '48w',
          '256w',
          '384w',
          '480w',
          '640w',
          '750w',
        ])}
        media={viewType === 'large' ? '(max-width: 768px)' : null}
      />
      {viewType === 'large' && (
        <SourceComponent
          srcSet={getSrcSet(uriObject, ['1080w', '1200w', '1920w', '2040w'])}
          media="(min-width: 768px)"
        />
      )}
      {/* children should be an img tag for fallback to picture element */}
      {children}
    </PictureComponent>
  );
}

Picture.propTypes = {
  uriObject: PropTypes.object,
  viewType: PropTypes.oneOf(['small', 'large']).isRequired,
  children: PropTypes.node.isRequired,
  pictureStyle: PropTypes.string,
};

Picture.defaultProps = {
  uriObject: null,
};

export default function ResponsiveImage({
  uriObject,
  defaultSize,
  viewType,
  alt,
  CustomRenderer,
  pictureStyle = '',
  ...rest
}) {
  const src = uriObject && uriObject[defaultSize];
  if (CustomRenderer) {
    return (
      <Picture
        uriObject={uriObject}
        viewType={viewType}
        pictureStyle={pictureStyle}
      >
        <CustomRenderer src={src} alt={alt} {...rest} />
      </Picture>
    );
  }
  return (
    <Picture
      uriObject={uriObject}
      viewType={viewType}
      pictureStyle={pictureStyle}
    >
      <StyledImg src={src} alt={alt} {...rest} />
    </Picture>
  );
}

ResponsiveImage.propTypes = {
  uriObject: PropTypes.object,
  defaultSize: PropTypes.string.isRequired,
  viewType: PropTypes.oneOf(['small', 'large']),
  alt: PropTypes.string,
  CustomRenderer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
  ]),
  pictureStyle: PropTypes.string,
};

ResponsiveImage.defaultProps = {
  uriObject: null,
  CustomRenderer: undefined,
  viewType: 'small',
};
