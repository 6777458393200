export default {
  reviewCompleteHeader: 'Zkontrolujte a dokončete',
  participants: 'Účastníci',
  enterParticipantInfoText:
    'Zadejte prosím informace pro každého účastníka této zkušenosti.',
  purchaser: 'Kupující',
  firstName: 'Jméno',
  lastName: 'Příjmení',
  email: 'Email',
  optionalEmail: 'Email: (volitelný)',
  participantFieldPlaceholder:
    'Přidejte nového nebo vyberte stávajícího účastníka',
  reservationSummary: 'Souhrn rezervace',
  editParticipantsDetails: 'Upravit detaily účastníka',
  participantDetails: 'Detaily účastníka',
  duplicatePurchaserText: 'Stejný jako kupující',
  phone: 'Telefon',
  addParticipant: 'Další {{tierLabel}}',
  participant: 'Účastník',
  removeParticipant: 'Odstranit',
  experienceLimitReached:
    'Litujeme, tato zkušenost dosáhla maximálního počtu účastníků',
  payment: 'Platba',
  duration: 'Trvání',
  discountCode: 'Slevový kód',
  removeCode: 'Odstranit kód',
  invalidCodeValidation: 'Neplatný kupón',
  networkErrorDiscountCode: 'Na naší straně se něco pokazilo',
  discount: 'Sleva',
  promo: 'Propagační',
  promoCode: 'Propagační kód',
  apply: 'Aplikovat',
  total: 'Celkem',
  subTotal: 'Mezisoučet',
  includes: 'Zahrnuje',
  and: 'a',
  addHostNotes: 'Přidat poznámky pro hostitele',
  policyAggreementText:
    'Souhlasím s <0>Obchodními podmínkami</0> a <1>Ochrana osobních údajů</1>',
  brandAggreementText:
    'Souhlasím, nebo jinak potvrzuji, že jsem oprávněn jménem účastníka souhlasit s obchodními podmínkami',
  termsAggreementValidation: 'Prosím, souhlaste s podmínkami',
  firstNameValidation: 'Jméno je vyžadováno',
  lastNameValidation: 'Příjmení je vyžadováno',
  emailValidation: {
    emailRequiredText: 'Email je vyžadován',
    invalidEmail: 'Neplatná emailová adresa',
    not_specified: 'Nesprávná emailová adresa',
  },
  phoneValidation: {
    'Invalid Phone Number': 'Neplatné telefonní číslo',
    not_specified: 'Neplatné',
    phoneRequiredText: 'Telefonní číslo je vyžadováno',
  },
  participantFirstNameValidation: 'Jméno účastníka je vyžadováno',
  participantLastNameValidation: 'Příjmení účastníka je vyžadováno',
  complete: 'Kompletní',
  '404Message': 'Nenalezeno',
  waiting: 'Čekání...',
  complimentary: 'Bezplatný',
  confirmationHeader: 'Vaše zkušenost byla rezervována!',
  confirmationText:
    'Potvrzovací email byl odeslán na {{email}} s dalšími detaily',
  viewAll: 'Zobraz',
  paymentMethod: 'Způsob platby',
  creditCard: 'Kreditní karta',
  roomCharge: 'Poplatek za pokoj',
  memberCharge: 'Poplatek pro členy',
  roomOrReservationNumber: 'Číslo pokoje nebo potvrzovací číslo',
  roomNumber: 'Číslo pokoje',
  roomNumberLegacy: 'Číslo pokoje nebo kód rezervace',
  memberNumber: 'Číslo člena',
  kicc: 'Jednoduchá platba',
  chargeValidation: 'Číslo pokoje a příjmení jsou vyžadovány',
  memberNumberValidation: 'Číslo člena je vyžadováno',
  invalidRoomNumber: 'Číslo pokoje je neplatné',
  invalidRoomNumberLegacy: 'Číslo pokoje nebo kód rezervace je neplatný',
  invalidMemberNumber: 'Číslo člena je neplatné',
  invalidPhoneNumber: 'Telefonní číslo je neplatné',
  requiredPhoneNumber: 'Telefonní číslo je vyžadováno',
  guestNameIsRequired: 'Jméno hosta je vyžadováno',
  roomNumberIsRequired: 'Číslo pokoje je vyžadováno',
  roomNumberIsRequiredLegacy: 'Číslo pokoje nebo kód rezervace je vyžadován',
  roomOrReservationNumberIsRequired:
    'Číslo pokoje nebo potvrzovací číslo je vyžadováno',
  cardNumberIncomplete: 'Číslo karty je neúplné',
  lastNameValidationError: 'Příjmení je vyžadováno',
  isReqd: 'je vyžadováno',
  reservationCode: 'Rezervační/potvrzovací číslo',
  nonHotelGuest: 'Host mimo hotel',
  onPropertyGuest: 'Host na majetku',
  preArrivalGuest: 'Host před příjezdem',
  cardNumber: 'Číslo karty',
  pmsValidationErrorMessage:
    'Neplatné příjmení nebo číslo pokoje nebo potvrzovací číslo',
  pmsDateValidationErrorMessage:
    'Litujeme! Datum zážitku není v rozmezí vašeho příjezdu a odjezdu.',
  fullName: 'Celé jméno',
  checkout: 'Odhlášení',
  editParticipants: 'Upravit účastníky',
  private: 'Soukromý',
  browseAllExperieces: 'Prohlédnout všechny zážitky',
  payWithCreditCard: 'Platit kredit',
  payWithRoomCharge: 'Platit poplatek za pokoj',
  payWithMemberCharge: 'Platit poplatek pro členy',
  payWith: 'Platit s',
  bookBulkErrorByCard:
    'Při potvrzení vaší rezervace došlo k problému. Z vaší karty nebyl poplatek účtován.',
  bookBulkErrorByRoomCharge: 'Neplatné příjmení a číslo pokoje.',
  bookBulkErrorByMemberCharge: 'Neplatné příjmení a členské číslo.',
};
