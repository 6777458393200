import React, {
  ComponentProps,
  FC,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
  MouseEventHandler,
} from 'react';
import { css } from 'styled-components';
import Select, { StylesConfig } from 'react-select';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { tabletBreakpoint, useIsMobile } from 'WindowDimensionProvider';
import { ExperienceImageSizeMediaSchema } from '@kouto/types';
import { CollapsibleCard } from 'components/CollapsibleCard';
import ResponsiveImage from 'components/common/ResponsiveImage';
import {
  Flex,
  HidableResponsiveContentWrapper,
  Sep,
} from 'components/common/styled/common-styled';
import { currencyFormat, isImageRatioGreaterOne } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { selectStyles } from 'components/ExperienceView/styles';
import { TruncatedText, TruncationMode } from 'components/TruncatedText';
import { SessionTimeOption } from 'features/Reserve/hooks/useSessionTimeOptions';
import {
  ResourceHeading2,
  ResourceParagraph,
} from 'components/theme/Typography/ResourceTypography';
import { ResourceGroup, Session } from 'types/listings';
import * as Styled from './styles';
import { PriceTierPopover } from '../Booking/PriceTier';
import { PriceTierWithParticipants } from '../Booking/PriceTier/types';
import { ResourceSessionList } from '../ResourceSessionSelector/ResourceSessionList';
import { BottomDrawer } from '../BottomDrawer';
import { BottomSheetNonMappedResourcePage } from '../Resource/BottomSheetNonMappedResourcePage';

export interface SessionDateAndDuration {
  startDateTime: string;
  duration: number;
}

interface Props {
  collectionId: string;
  group: ResourceGroup;
  title: string;
  allSessionTimes: SessionTimeOption[];
  initialPrice: string;
  capacityLabel: string;
  sessions: Session[];
  description: string;
  includedItems: Array<string>;
  resourceImage: z.infer<typeof ExperienceImageSizeMediaSchema>;
  onReserveClick: (session: SessionDateAndDuration) => void;
  onCardClick?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  showTruncatedView?: boolean;
  handleGroupSelect: (groupId: string) => void;
  priceTiers: PriceTierWithParticipants[];
  onParticipantsChange: ComponentProps<typeof PriceTierPopover>['onChange'];
}

const headerStyles = css`
  padding: 20px;

  @media all and (max-width: ${tabletBreakpoint}) {
    padding: 12px;
  }
`;

const CollapsibleResource: FC<Props> = ({
  collectionId,
  title,
  initialPrice,
  group,
  allSessionTimes,
  capacityLabel,
  sessions,
  description,
  includedItems,
  resourceImage,
  onReserveClick,
  onCardClick,
  onMouseEnter,
  onMouseLeave,
  handleGroupSelect,
  showTruncatedView = false,
  priceTiers,
  onParticipantsChange,
}) => {
  const isMobileView = useIsMobile();
  const currency = useBrandCurrency();
  const { t: translate } = useTranslation();
  const [isSixteenNineRatio, setIsSixteenNineRatio] = useState(false);

  const onImgLoad: HTMLImageElement['onload'] = (e) => {
    if (e.target instanceof HTMLImageElement) {
      setIsSixteenNineRatio(
        isImageRatioGreaterOne(e.target.naturalWidth, e.target.naturalHeight),
      );
    }
  };

  const handleOnReserveClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (selectedSession) {
      const { startDateTime, duration } = selectedSession;
      onReserveClick({
        startDateTime,
        duration,
      });
    }
  };

  const [selectedSession, setSelectedSession] = useState<Session | undefined>();

  useEffect(() => {
    if (allSessionTimes.length > 0 && !selectedSession) {
      setSelectedSession(sessions[0]);
    }
  }, [sessions, selectedSession, allSessionTimes]);

  const maxParticipants = useMemo(() => {
    if (selectedSession) {
      return sessions.filter(
        (session) =>
          selectedSession.startDateTime === session.startDateTime &&
          selectedSession.duration === session.duration,
      ).length;
    }
    return 0;
  }, [sessions, selectedSession]);

  const selectedSessionOption = useMemo(() => {
    return allSessionTimes.find((timeOption) => {
      const [sessionTime, sessionDuration] = timeOption.value.split('-');
      return (
        selectedSession?.startDateTime.split('T')[1] === sessionTime &&
        selectedSession?.duration === parseInt(sessionDuration, 10)
      );
    });
  }, [allSessionTimes, selectedSession]);

  const onSessionChange = (time: string, duration: number) => {
    const session = sessions.find(
      (s) => s.startDateTime.split('T')[1] === time && s.duration === duration,
    );
    if (session) {
      setSelectedSession(session);
    }
  };

  const [openSessionSelector, setOpenSessionSelector] = useState(false);
  const [openResourcePage, setOpenResourcePage] = useState(false);

  const toggleSessionSelector: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (sessions.length < 1) {
      setSelectedSession(sessions[0]);
      setOpenResourcePage(true);
      return;
    }

    setOpenSessionSelector(true);
  };

  const handleSessionSelectInMobile = (session: Session) => {
    setSelectedSession(session);
    setOpenSessionSelector(false);
    setOpenResourcePage(true);
  };

  const disabled = !allSessionTimes.length;

  return (
    <CollapsibleCard
      headerStyles={headerStyles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      customHeaderClickAction={(onOpenAccordion) => {
        if (onCardClick) {
          onCardClick();
        } else {
          onOpenAccordion();
        }
      }}
      containerStyles={
        isMobileView
          ? mobileContainerStyles(showTruncatedView)
          : desktopContainerStyles()
      }
      renderHeader={(expanded, onOpenAccordion) => (
        <Styled.HeaderWrapper gap="24px" mobileGap="18px">
          <ResponsiveImage
            className="resource-group-card__resource-image"
            uriObject={resourceImage?.uri}
            defaultSize="384w"
            viewType="small"
            isSixteenNineRatio={isSixteenNineRatio}
            onLoad={onImgLoad}
            style={{
              borderRadius: 0,
            }}
            CustomRenderer={Styled.ResourceGroupImage}
            alt={title}
            pictureStyle={`
              display: grid;
              place-items: center;
            `}
          />
          <Flex
            flex={1}
            justifyContent="space-between"
            alignItem={
              isMobileView || showTruncatedView ? 'flex-start' : 'center'
            }
            flexWrap="wrap"
            gap={isMobileView ? 6 : 8}
            width={isMobileView ? '100%' : '220px'}
            direction={isMobileView || showTruncatedView ? 'column' : 'row'}
          >
            <Styled.MainHeading
              mobileFontSize="16px"
              mobileFontWeight="normal"
              fontWeight="700"
              className="resource-group-card__resource-title"
            >
              {title}
            </Styled.MainHeading>

            <Flex
              direction="row"
              isContent={!isMobileView && !showTruncatedView}
              flex={1}
              gap={isMobileView || showTruncatedView ? 4 : 12}
              flexWrap="wrap"
              justifyContent={
                isMobileView || showTruncatedView
                  ? 'flex-start'
                  : 'space-around'
              }
            >
              {currency && (
                <Styled.ResourceGroupText
                  mobileFontSize="12px"
                  lineHeight="normal"
                  className="resource-group-card__resource-price"
                >
                  {!allSessionTimes.length
                    ? translate('unavailable')
                    : `${translate('from')} ${currencyFormat(currency)(
                        initialPrice,
                      )}`}
                </Styled.ResourceGroupText>
              )}
              {capacityLabel && (isMobileView || showTruncatedView) ? (
                <Styled.ResourceGroupText
                  lineHeight="normal"
                  className="resource-group-card__resource-capacity-bullet"
                >
                  •
                </Styled.ResourceGroupText>
              ) : null}
              {!!capacityLabel && (
                <Styled.ResourceGroupText
                  mobileFontSize="12px"
                  lineHeight="normal"
                  className="resource-group-card__capacity-label"
                >
                  {capacityLabel}
                </Styled.ResourceGroupText>
              )}
            </Flex>

            {(isMobileView || !showTruncatedView) && (
              <div>
                {showTruncatedView && isMobileView && (
                  <Styled.ReserveButton
                    onClick={() => handleGroupSelect(group.id)}
                    type="button"
                    disabled={disabled}
                    className="resource-group-card__resource-book-btn"
                  >
                    {translate('reserveNow')}
                  </Styled.ReserveButton>
                )}

                {!showTruncatedView && (
                  <HidableResponsiveContentWrapper maxWidth={512}>
                    <Styled.ButtonWrapper>
                      {!isMobileView && (
                        <Styled.SelectBox onClick={(e) => e.stopPropagation()}>
                          <Select
                            styles={resourceSelectStyles}
                            isSearchable={false}
                            options={allSessionTimes}
                            className="resource-group-card__resource-sessions-time"
                            classNamePrefix="resource-group-card-sessions"
                            placeholder="Select"
                            onChange={(option) => {
                              if (option) {
                                const [sessionTime, sessionDuration] =
                                  option.value.split('-');
                                onSessionChange(
                                  sessionTime,
                                  parseInt(sessionDuration, 10),
                                );
                              }
                            }}
                            value={selectedSessionOption}
                          />
                        </Styled.SelectBox>
                      )}

                      {currency?.code && !isMobileView ? (
                        <PriceTierPopover
                          asChild
                          priceTiers={priceTiers}
                          maxParticipants={maxParticipants}
                          currency={currency.code}
                          onChange={onParticipantsChange}
                          onSubmit={handleOnReserveClick}
                        >
                          <Styled.ReserveButton
                            type="button"
                            disabled={disabled}
                            className="resource-group-card__resource-book-btn"
                          >
                            {translate('reserve')}
                          </Styled.ReserveButton>
                        </PriceTierPopover>
                      ) : null}

                      {currency?.code && isMobileView ? (
                        <Styled.ReserveButton
                          type="button"
                          disabled={disabled}
                          className="resource-group-card__resource-book-btn"
                          onClick={toggleSessionSelector}
                        >
                          {translate('reserve')}
                        </Styled.ReserveButton>
                      ) : null}
                    </Styled.ButtonWrapper>
                  </HidableResponsiveContentWrapper>
                )}
              </div>
            )}
          </Flex>
        </Styled.HeaderWrapper>
      )}
    >
      <Styled.DetailsContainer>
        <Sep />
        <Styled.ResourceDetailsSection
          gap={16}
          direction="column"
          alignItem="flex-start"
          className="resource-group-card__resource-details-section"
        >
          <ResourceHeading2
            className="resource-group-card__resource-details-title"
            fontWeight="350"
          >
            {translate('details')}
          </ResourceHeading2>
          <TruncatedText
            isRichText
            text={description}
            maxLines={2}
            mode={TruncationMode.INLINE}
            customStyles={resourceGroupTextStyles}
          />
        </Styled.ResourceDetailsSection>

        {includedItems?.length >= 1 && (
          <>
            <Sep />
            <Styled.ResourceIncludedSection
              gap={16}
              direction="column"
              alignItem="flex-start"
            >
              <ResourceHeading2
                className="resource-group-card__resource-included-title"
                fontWeight="350"
              >
                {translate('whatsIncluded')}
              </ResourceHeading2>
              <Flex
                direction="row"
                alignItem="center"
                gap={8}
                flexWrap="wrap"
                className="resource-group-card__resource-included-items-content"
              >
                {includedItems.map((item) => (
                  <Styled.ResourceIncludedItem
                    className="resource-group-card__resource-included-item-wrapper"
                    key={item}
                  >
                    <ResourceParagraph
                      fontSize="14px"
                      className="resource-group-card__resource-included-item"
                    >
                      {item}
                    </ResourceParagraph>
                  </Styled.ResourceIncludedItem>
                ))}
              </Flex>
            </Styled.ResourceIncludedSection>
          </>
        )}
      </Styled.DetailsContainer>
      <BottomDrawer
        open={openSessionSelector && allSessionTimes.length > 0}
        heading={translate('session')}
        onClose={() => setOpenSessionSelector(false)}
      >
        <ResourceSessionList
          selectedSession={selectedSession}
          onSessionClick={handleSessionSelectInMobile}
          sessions={allSessionTimes.map((timeOption) => timeOption.original)}
        />
      </BottomDrawer>

      {selectedSession ? (
        <BottomSheetNonMappedResourcePage
          size="full"
          heading={group.experiences[0]?.title || ''}
          open={openResourcePage}
          onClose={() => setOpenResourcePage(false)}
          group={group}
          priceTiers={priceTiers}
          onPriceTierChange={onParticipantsChange}
          selectedSession={selectedSession}
          sessions={sessions}
          collectionId={collectionId}
        />
      ) : null}
    </CollapsibleCard>
  );
};

const containerStyles = () => css`
  border: 0.5px solid var(--way-colors-borderColor);
  box-shadow: var(--way-design-boxShadow-s);
  background-color: transparent;
`;

const mobileContainerStyles = (isMapped: boolean) => css`
  ${containerStyles()};
  ${isMapped
    ? `
  border: transparent;
  box-shadow: none;
    `
    : ''}
`;

const desktopContainerStyles = () => css`
  ${containerStyles()};
`;

const resourceGroupTextStyles = css`
  margin-bottom: 0;
  & > p {
    font-family: ${({ theme }) => theme.font.primaryFont};
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    font-size: 14px;
    color: var(--way-colors-contrastColorShades-80);
  }
`;

export const resourceSelectStyles: StylesConfig<
  { label: string; value: string },
  false
> = {
  ...selectStyles,
  control: (styles) => ({
    ...selectStyles.control(styles),
    minHeight: 48,
    width: 162,
    padding: 8,
    borderColor: 'var(--way-color-borderColor)',
    fontSize: 14,
    borderRadius: 0,
  }),
  menuList: (provided, state) => ({
    ...selectStyles.menuList(provided, state),
    paddingTop: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: 0,
    marginRight: 6,
  }),
  option: (styles, props) => ({
    ...selectStyles.option(styles, props),
    fontSize: 14,
    padding: 12,
    borderRadius: 0,
  }),
};

export default CollapsibleResource;
