import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Listing } from 'types/listings';
import { ICartExperience, CartItemAddOn, ICartParticipant } from 'types/cart';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import { currencyFormat } from 'utils';
import { Sep } from 'components/common/styled/common-styled';
import { computeOneCartItemTotalDetails } from '@kouto/cart-prices';
import { ANALYTICS_PROPERTY } from '@kouto/types';
import {
  analyticsManager,
  getAnalyticsDataFromCartItem,
} from 'features/analytics';

interface Props {
  cartItem: ICartExperience;
  listing: Listing;
  overriddenParticipants?: ICartParticipant[];
  overriddenAddOns?: CartItemAddOn[];
}

const ListingCartItemSummary: FC<Props> = ({
  cartItem,
  listing,
  overriddenParticipants,
  overriddenAddOns,
}) => {
  const currency = useBrandCurrency();
  const { formattedNumber } = useCurrencyFormatter();
  const { t: translate } = useTranslation();

  const currentCartItem = {
    ...cartItem,
    participants:
      overriddenParticipants && overriddenParticipants.length
        ? overriddenParticipants
        : cartItem.participants,
    addOns: overriddenAddOns || cartItem.addOns || [],
  };

  const imageUri = useMemo(() => {
    if (cartItem.groupId && listing.resourceGroups) {
      const resourceGroup = listing.resourceGroups.find(
        (group) => group.id === cartItem.groupId,
      );
      return resourceGroup?.pictures[0]?.uri || listing.coverPicture?.uri;
    }
    return listing.coverPicture?.uri;
  }, [cartItem, listing]);

  const {
    isPrivateBooking,
    selectedPriceTiers,
    includedTaxes,
    nonIncludedTaxes,
    subTotal,
    total,
  } = computeOneCartItemTotalDetails(currentCartItem, listing);

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.CurrentCartAmount]: `${total}`,
    });
  }, [total]);

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.TotalTaxAmount]: `${nonIncludedTaxes
        .reduce((sum, tax) => sum + tax.amountFee, 0)
        .toFixed(2)}`,
    });
  }, [nonIncludedTaxes]);

  useEffect(() => {
    const data = getAnalyticsDataFromCartItem({
      cartData: {
        ...cartItem,
        addOns: currentCartItem.addOns,
      },
      listing,
    });

    analyticsManager.setAppData(data);
  }, [
    cartItem?.experienceId,
    cartItem.listingId,
    currentCartItem.addOns,
    listing.category?.name,
    listing?.title,
    selectedPriceTiers,
  ]);

  return (
    <SummaryWrapper>
      <WrapperDiv>
        {imageUri && (
          <ResponsiveImage
            className="resource-group-summary__resource-image listing-cart-summary__resource-image"
            uriObject={imageUri}
            defaultSize="384w"
            viewType="small"
            style={{
              borderRadius: 0,
            }}
            CustomRenderer={ResourceGroupImage}
            alt={listing.title}
            pictureStyle={`
            display: grid;
            place-items: center;
          `}
          />
        )}
      </WrapperDiv>
      <Sep />

      <Prices>
        {selectedPriceTiers.map((tier) => {
          if (!tier.selectedNumber) {
            return null;
          }
          return (
            <PriceRow key={tier.name}>
              <p className="resource-group-summary__price-tier listing-cart-summary__price-tier">
                {translate(tier.name)}
                {!isPrivateBooking && ` (x${tier.selectedNumber})`}
              </p>
              <p className="resource-group-summary__tier-amount listing-cart-summary__tier-amount">
                {currencyFormat(currency)(formattedNumber(tier.price))}
              </p>
            </PriceRow>
          );
        })}
        {currentCartItem.addOns.map((addOn) => {
          if (!addOn.selectedNumber) {
            return null;
          }
          return (
            <PriceRow key={addOn.id}>
              <p className="listing-cart-summary__addon-name">
                {addOn.label} (x{addOn.selectedNumber})
              </p>
              <p className="listing-cart-summary__addon-price">
                {currencyFormat(currency)(formattedNumber(addOn.price))}
              </p>
            </PriceRow>
          );
        })}

        <Sep style={{ margin: '8px 0px' }} />

        <PriceRow>
          <p className="resource-group-summary__total listing-cart-summary__total">
            {translate('subTotal')}: {includedTaxes.length > 0 ? '*' : ''}
          </p>
          <p className="resource-group-summary__total-amount listing-cart-summary__total-amount">
            {subTotal > 0
              ? currencyFormat(currency)(formattedNumber(subTotal))
              : '-'}
          </p>
        </PriceRow>

        {nonIncludedTaxes.map((tax) => (
          <PriceRow key={tax.name}>
            <p className="resource-group-summary__tax listing-cart-summary__tax">
              {tax.name}
            </p>
            <p className="resource-group-summary__tax-amount listing-cart-summary__tax-amount">
              {currencyFormat(currency)(formattedNumber(tax.amountFee))}
            </p>
          </PriceRow>
        ))}

        <Sep style={{ margin: '8px 0px' }} />

        <PriceRow bold>
          <p className="resource-group-summary__total listing-cart-summary__total">
            {translate('total')}:
          </p>
          <p className="resource-group-summary__total-amount listing-cart-summary__total-amount">
            {total > 0 ? currencyFormat(currency)(formattedNumber(total)) : '-'}
          </p>
        </PriceRow>

        {includedTaxes.length > 0 && (
          <PriceRow>
            <small>
              * {translate('includes')}{' '}
              {includedTaxes
                .map((t) => `${t.name} (${t.percentage}%)`)
                .join(` ${translate('and')} `)}
            </small>
          </PriceRow>
        )}
      </Prices>
    </SummaryWrapper>
  );
};

const WrapperDiv = styled.div`
  padding: 24px;
`;

const SummaryWrapper = styled.div`
  box-shadow: var(--way-design-boxShadow-s);
  max-width: 350px;
  min-width: 350px;
  flex-shrink: 0;
  height: fit-content;
  border: 0.5px solid var(--way-colors-borderColor);
  background: var(--way-palette-white-10);
  align-self: stretch;

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

const ResourceGroupImage = styled.img`
  object-fit: cover;
  user-select: none;
  max-height: 100%;
  object-position: center;
  height: 254px;
  width: 100%;
`;

const Prices = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PriceRow = styled.div<{ bold?: boolean }>`
  /* skip css namespace */
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;

  &:last-child {
    margin-bottom: 0px;
  }

  & > p {
    margin: 0px;
    font-size: 15px;
    font-style: normal;
    font-weight: ${({ bold }) => (bold ? '700' : '100')};
    line-height: normal;
    letter-spacing: 0.014px;
  }
`;

export default ListingCartItemSummary;
