import React, { FC, useState, useMemo, useEffect, KeyboardEvent } from 'react';
import { z } from 'zod';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  ANALYTICS_EVENT,
  ANALYTICS_PROPERTY,
  ListingKind,
  ListingPictureKind,
  ProductLine,
  PublicListingSchema,
} from '@kouto/types';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { getListingRoute } from 'utils/listings';
import { useInView } from 'react-intersection-observer';
import { partition } from 'lodash';
import {
  analyticsManager,
  getExperiencesForAnalytics,
  getProductFromResourceGroups,
} from 'features/analytics';
import { ListingInfoBox } from './ListingInfoBox';
import { getNextDateLabels } from './ListingInfoBox/EventInfoBox';

const ZOOM_IN_DURATION = 3000; // ms

interface ListingItemCardProps {
  gridArea: string;
  listing: z.infer<typeof PublicListingSchema>;
  showYear?: boolean;
  brandSettings?: { promotion?: { exclusiveTag?: string } };
}

const ListingItemCard: FC<ListingItemCardProps> = ({
  gridArea,
  listing,
  showYear,
  brandSettings,
}) => {
  const history = useHistory();
  const currency = useBrandCurrency();
  const { t: translate } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const stopInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  useEffect(() => stopInterval, []);

  const onMouseEnter = () => {
    setHovered(true);
    stopInterval();
    setIntervalId(
      setInterval(() => {
        setSelectedImageIndex(
          (prevIndex) => (prevIndex + 1) % listing.pictures.length,
        );
      }, ZOOM_IN_DURATION - 500),
    );
  };

  const onMouseLeave = () => {
    stopInterval();
    setHovered(false);
    setSelectedImageIndex(0);
  };

  const onClick = () => {
    stopInterval();
    if (
      listing.kind === ListingKind.EXPERIENCE ||
      listing.resourceGroups?.length === 1
    ) {
      const item = listing.resourceGroups?.[0] ?? listing;
      analyticsManager.sendEvent(ANALYTICS_EVENT.CLICK_EXPERIENCE, {
        [ANALYTICS_PROPERTY.ExperienceId]: item.id,
        [ANALYTICS_PROPERTY.ExperienceTitle]: item.title,
        [ANALYTICS_PROPERTY.ExperienceCategory]: listing.category?.name,
        [ANALYTICS_PROPERTY.Products]: JSON.stringify(
          listing.kind === ListingKind.EXPERIENCE
            ? getExperiencesForAnalytics([
                {
                  id: listing.id,
                  title: listing.title,
                  category: listing.category,
                  priceTiers: listing.defaultPriceTiers,
                },
              ])
            : getProductFromResourceGroups({
                groups: [item],
                categoryName: listing.category?.name,
                collectionId: listing.id,
                collectionName: listing.title,
                priceTiers: listing.defaultPriceTiers,
              }),
        ),
      });
    }
    history.push(getListingRoute(listing));
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.code.toLowerCase() === 'enter') {
      onClick();
    }
  };

  const detailsLabel = useMemo(() => {
    const labels: string[] = [];

    if (listing.productLine === ProductLine.ACTIVATE) {
      const dateLabels = getNextDateLabels(listing);
      if (dateLabels) {
        labels.push(`${dateLabels.month} ${dateLabels.day}`);
        if (showYear) {
          labels[0] = `${labels[0]}, ${dateLabels.year}`;
        }
      }
    }

    const conditionalAndDefaultPriceTiers = [
      ...listing.defaultPriceTiers,
      ...(listing.conditionalPriceTiers ?? []),
    ];

    const conditionalAndSessionPriceTiers = [
      ...(listing.firstAvailableDate?.sessions ?? []).flatMap(
        (session) => session.priceTiers,
      ),
      ...(listing.conditionalPriceTiers ?? []),
    ];

    const prices =
      Array.from(
        new Set(
          listing.firstAvailableDate?.sessions.length
            ? conditionalAndSessionPriceTiers.map((pt) => pt.price)
            : conditionalAndDefaultPriceTiers.map((pt) => pt.price),
        ),
      ) || [];

    if (
      listing.bookingAvailabilityMode === 'private' &&
      listing.defaultMaxParticipantCount &&
      listing.defaultPriceTiers.length > 0
    ) {
      const firstDefaultPriceTierPrice = listing.defaultPriceTiers[0].price;
      const privatePrice =
        firstDefaultPriceTierPrice * listing.defaultMaxParticipantCount;
      labels.push(`${currencyFormat(currency)(privatePrice)}`);
    } else if (!listing.hidePrice && prices.length > 0) {
      const nonZeroPrices = prices.filter((price) => price > 0);
      const allZero = nonZeroPrices.length === 0;

      if (allZero) {
        labels.push(translate('complimentary'));
      } else {
        const minPrice = Math.min(...nonZeroPrices);
        const maxPrice = Math.max(...nonZeroPrices);

        if (minPrice === maxPrice) {
          labels.push(`${currencyFormat(currency)(minPrice)}`);
        } else {
          labels.push(
            `${currencyFormat(currency)(minPrice)} - ${currencyFormat(currency)(
              maxPrice,
            )}`,
          );
        }
      }
    }

    return labels.join(' • ');
  }, [listing, currency, showYear, translate]);

  const imagesCount = listing.pictures.length;

  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView({ triggerOnce: true });

  const [covers, otherPictures] = partition(
    listing.pictures,
    (picture) => picture.kind === ListingPictureKind.COVER,
  );

  return (
    <Wrapper
      ref={ref}
      gridArea={gridArea}
      className="listing-item-card-container"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      <ListingInfoBox listing={listing} />

      <ImagesWrapper
        className={loaded && inView ? '' : 'loading'}
        imagesCount={imagesCount}
      >
        {listing.isExclusive && (
          <ExclusiveBadge className="listing-card-exclusive-badge">
            <span>
              {brandSettings?.promotion?.exclusiveTag || translate('exclusive')}
            </span>
          </ExclusiveBadge>
        )}
        {[...covers, ...otherPictures].map((image, index) => {
          const imageUrl = image.uri['1080w'];
          const key = `${listing.id}-${imageUrl}`;

          return (
            <Img
              id={key}
              onLoad={() => {
                setLoaded(true);
              }}
              loading="lazy"
              className="listing-card-background-image"
              key={key}
              src={imageUrl}
              hovered={hovered}
              active={selectedImageIndex === index}
              imagesCount={imagesCount}
              imageIndex={index}
              alt={translate('experiencePhotoOfListing', {
                photoNumber: index + 1,
                listingTitle: listing.title,
              })}
            />
          );
        })}
      </ImagesWrapper>
      <Title className="listing-card-title">{listing.title}</Title>
      <DetailsLabel className="listing-card-details">
        {detailsLabel}
      </DetailsLabel>
    </Wrapper>
  );
};

export default ListingItemCard;

const Wrapper = styled.div<{
  gridArea: string;
}>`
  grid-area: ${(props) => props.gridArea};
  cursor: pointer;
  position: relative;
`;

const ImagesWrapper = styled.div<{ imagesCount: number }>`
  width: 100%;
  height: 380px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 300px;
  }

  & > img.listing-card-background-image,
  & > img.listing-card-background-image[loading='lazy'] {
    /* this css is defined here instead of inside <Img> component to get an higher priority than the css on the Turtle Bay website */
    height: 100%;
    opacity: 1;
    transition: scale ${ZOOM_IN_DURATION / 1000}s cubic-bezier(0, 0.7, 0.28, 1);
  }

  &::before {
    content: ' ';
    opacity: 0;
    transition: opacity 0.4s 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(-90deg, #c1c1c1 0%, #f8f8f8 50%, #c1c1c1 100%);
    background-size: 400% 400%;
    z-index: ${({ imagesCount }) => 30 + imagesCount + 1};
  }

  &.loading::before {
    opacity: 1;
    animation: pulse 1.2s ease-in-out infinite;
    @keyframes pulse {
      0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: -135% 0%;
      }
    }
  }
`;

const Img = styled.img<{
  hovered: boolean;
  active: boolean;
  imagesCount: number;
  imageIndex: number;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  scale: 1;
  z-index: ${({ imagesCount, imageIndex }) => imagesCount - imageIndex};

  ${({ active, imageIndex }) => active && `z-index: ${30 + imageIndex};`}
  ${({ hovered, active }) =>
    hovered &&
    active &&
    `
      scale: 1.05;
      animation-iteration-count: 1;
  `}
`;

/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--way-colors-primaryTextColor);
`;
/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */

const DetailsLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: var(--way-colors-contrastColorShades-80);
`;

const ExclusiveBadge = styled.div`
  position: absolute;
  display: flex;
  padding: 12px;
  align-items: end;
  justify-content: end;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: linear-gradient(
    143deg,
    rgba(0, 0, 0, 0) 80%,
    var(--way-palette-black-50) 100%
  );

  & > span {
    color: var(--way-palette-white-100);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
