import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppState, useDispatch } from 'AppProvider';

import { ITier } from 'features/ShoppingCart/types';
import { currencyFormat, getCurrencyFromCode } from 'utils';
import useSelectedParticipants from 'selectors/participants';
import {
  applyTierParticipants,
  deductTierParticipants,
} from 'actions/participants';

import Add from 'assets/plus-circle';
import Minus from 'assets/minus-circle';

interface IDropdownProps {
  priceCategory: ITier;
  index: number;
  setParticipantValidationMessage: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  cartExperienceSession: any;
}

const ParticipantsDropdown: React.FC<IDropdownProps> = ({
  priceCategory,
  index,
  setParticipantValidationMessage,
  cartExperienceSession,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { selectedParticipants, totalSelected, selectedSession } =
    useSelectedParticipants();
  const { experience } = useAppState(
    (state: Record<string, unknown>) => state.experience,
  );
  const { selectedNumber } = selectedParticipants.find(
    (participant: Record<string, unknown>) =>
      participant.name === priceCategory.name,
  );
  const sessionPriceTier = selectedSession?.priceTiers.find(
    (tier: ITier) => tier.name === priceCategory.name,
  );

  const maxParticipantsCountForTier = useMemo(
    () =>
      selectedSession?.availableQuantityPerTier[priceCategory.name] ??
      sessionPriceTier?.maxQuantity ??
      experience.maxParticipantsCount,
    [selectedSession, experience],
  );

  const isSoldOut =
    maxParticipantsCountForTier === 0 ||
    selectedSession?.supportedParticipantsCount === 0;

  const isEligibleForMore = useMemo(() => {
    const supportedParticipantsCount =
      selectedSession?.supportedParticipantsCount ||
      experience?.maxParticipantsCount;
    if (isSoldOut || selectedNumber >= maxParticipantsCountForTier) {
      return false;
    }
    if (
      !!experience.partySize &&
      experience.partySize <= supportedParticipantsCount
    ) {
      return (
        totalSelected + (cartExperienceSession?.participants?.length ?? 0) <
        experience.partySize
      );
    }
    return totalSelected < supportedParticipantsCount;
  }, [
    selectedNumber,
    totalSelected,
    selectedSession,
    isSoldOut,
    maxParticipantsCountForTier,
    experience.maxParticipantsCount,
  ]);

  const onIncreaseParticipantNumber = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isEligibleForMore) return;
    dispatch(
      applyTierParticipants({
        label: priceCategory.name,
      }),
    );
    setParticipantValidationMessage(null);
  };

  const onDecreaseParticipantsNumber = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!selectedNumber) return;
    dispatch(
      deductTierParticipants({
        label: priceCategory.name,
      }),
    );
    setParticipantValidationMessage(null);
  };

  return (
    <>
      <PriceCategory isFirstLabel={index === 0}>
        <PriceCategoryLabel className="participants-dropdown__price-category-label">
          {priceCategory.price === 0 ? (
            <p>{translate('complimentary')}</p>
          ) : (
            <p>
              {currencyFormat(getCurrencyFromCode(experience.currency))(
                priceCategory.price,
              )}
            </p>
          )}
          <p>{priceCategory.name}</p>
          {isSoldOut && <SoldOut className="soldOutLabel">Sold out</SoldOut>}
        </PriceCategoryLabel>
        <PriceCategorySelector className="participants-dropdown__price-category-selector">
          <MinusIcon
            data-testid={`booking-participant-minus-${priceCategory.name}`}
            onClick={onDecreaseParticipantsNumber}
            disabled={!selectedNumber}
            scale={0.9}
            color={
              !selectedNumber
                ? 'var(--way-colors-buttonColorShades-50)'
                : 'var(--way-colors-buttonColorShades-100)'
            }
          />
          <span>{selectedNumber}</span>
          <PlusIcon
            data-testid={`booking-participant-plus-${priceCategory.name}`}
            onClick={onIncreaseParticipantNumber}
            disabled={!isEligibleForMore}
            scale={0.9}
            color={
              !isEligibleForMore
                ? 'var(--way-colors-buttonColorShades-50)'
                : 'var(--way-colors-buttonColorShades-100)'
            }
          />
        </PriceCategorySelector>
      </PriceCategory>
    </>
  );
};

const PriceCategory = styled.div<{ isFirstLabel: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${({ isFirstLabel }) => (isFirstLabel ? '0px' : '11px')};
  padding-bottom: 11px;
  border-bottom: 1px solid var(--way-colors-borderColor);
`;

const SoldOut = styled.p`
  color: red;
  font-size: 14px;
  font-weight: 200;
  margin-top: 8px;
  margin-bottom: 0px;
`;

const PlusIcon = styled(Add)<{ disabled: boolean }>`
  margin-top: -2px;
  pointer-events: auto;
  cursor: ${({ disabled }) => (disabled && 'not-allowed') || 'pointer'};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.75)};
  }
`;

const MinusIcon = styled(Minus)<{ disabled: boolean }>`
  margin-top: -2px;
  pointer-events: auto;
  cursor: ${({ disabled }) => (disabled && 'not-allowed') || 'pointer'};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.75)};
  }
`;

const PriceCategorySelector = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 8px;
  }
  & > span {
    min-width: 20px;
    text-align: center;
    user-select: none;
    color: var(--way-palette-black-90);
  }
`;

const PriceCategoryLabel = styled.div`
  & > p {
    margin: 0px;
    user-select: none;
    color: var(--way-palette-black-60);
  }
  & > p:nth-child(1) {
    margin-bottom: 4px;
    font-size: 12px;
    user-select: none;
  }
  & > p:nth-child(2) {
    font-size: 16px;
  }
  & > .soldOutLabel {
    margin-top: 4px;
  }
`;

export default ParticipantsDropdown;
