import React, { FC, useState, useCallback, useMemo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ListingAddOnLimitType } from '@kouto/types';
import { currencyFormat } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { Listing } from 'types/listings';
import IconPlus from 'assets/icon-plus';
import IconMinus from 'assets/icon-minus';
import AddOnDetailsModal from './AddOnDetailsModal';

interface Props {
  addOn: Listing['addOns'][number];
  gridArea: string;
  selectedPriceTiersCount: number;
  currentAmount: number;
  onAmountChange: (amount: number) => void;
}

const getPictureUrl = (picture: Listing['addOns'][number]['picture']) => {
  if (!picture) {
    return '';
  }

  return (
    picture.uri['360w'] ||
    picture.uri['720w'] ||
    picture.uri['1080w'] ||
    picture.uri.original
  );
};

const AddOnCard: FC<Props> = ({
  addOn,
  gridArea,
  selectedPriceTiersCount,
  currentAmount,
  onAmountChange,
}) => {
  const [detailsModalOpened, setDetailsModalOpened] = useState(false);
  const currency = useBrandCurrency();
  const { t: translate } = useTranslation();
  const priceLabel = useMemo(
    () =>
      addOn.price
        ? currencyFormat(currency)(addOn.price)
        : translate('complimentary'),
    [currency, addOn.price, translate],
  );

  const amountLimit = useMemo(() => {
    if (!addOn.limit) {
      return Infinity;
    }
    if (addOn.limit.type === ListingAddOnLimitType.PER_BOOKING) {
      return addOn.limit.value;
    }
    return addOn.limit.value * selectedPriceTiersCount;
  }, [addOn.limit, selectedPriceTiersCount]);

  const openDetailsModal = useCallback(() => {
    setDetailsModalOpened(true);
  }, []);

  const closeDetailsModal = useCallback(() => {
    setDetailsModalOpened(false);
  }, []);

  const onAdd = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onAmountChange(Math.min(amountLimit || Infinity, currentAmount + 1));
    },
    [currentAmount, amountLimit, onAmountChange],
  );

  const onRemove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onAmountChange(Math.max(0, currentAmount - 1));
    },
    [currentAmount, onAmountChange],
  );

  return (
    <>
      {detailsModalOpened && (
        <AddOnDetailsModal addOn={addOn} onClose={closeDetailsModal} />
      )}
      <Wrapper
        gridArea={gridArea}
        className="add-on-card"
        onClick={openDetailsModal}
      >
        <Details className="add-on-card-details">
          <Title className="add-on-card-title">{addOn.label}</Title>
          <Price className="add-on-card-price">{priceLabel}</Price>
          {addOn.description && (
            <Description className="add-on-card-description">
              {addOn.description}
            </Description>
          )}
        </Details>
        {addOn.picture && (
          <Image
            className="add-on-card-image"
            src={getPictureUrl(addOn.picture)}
          />
        )}
        <ButtonsWrapper className="add-on-card-buttons-container">
          <button
            data-testid={`${addOn.label}_addon_minus`}
            type="button"
            className="add-on-card-button-minus"
            disabled={currentAmount <= 0}
            onClick={onRemove}
          >
            <IconMinus color="var(--way-palette-black-80)" />
          </button>
          <div className="add-on-card-amount">
            <span>{currentAmount}</span>
          </div>
          <button
            data-testid={`${addOn.label}_addon_plus`}
            type="button"
            className="add-on-card-button-plus"
            disabled={currentAmount >= amountLimit}
            onClick={onAdd}
          >
            <IconPlus color="var(--way-palette-black-80)" />
          </button>
        </ButtonsWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div<{ gridArea: string }>`
  position: relative;
  height: 126px;
  border: solid 0.5px var(--way-colors-borderColor);
  transition-duration: 0.2s;
  cursor: pointer;

  &:hover {
    border: solid 0.5px var(--way-colors-borderHoverColor);
  }
`;

const Details = styled.div`
  height: 126px;
  width: calc(100% - 124px);
  padding: 16px;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.016px;
  text-overflow: ellipsis;
  margin: 0px;
  color: var(--way-colors-primaryTextColor);
`;

const Price = styled.span`
  margin: 0px;
  height: 17px;
  color: var(--way-colors-primaryTextColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.014px;
`;

/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
const Description = styled.p`
  margin: 0px;
  margin-top: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.014px;
  color: var(--way-colors-contrastColorShades-60);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */

const Image = styled.div<{ src: string }>`
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  aspect-ratio: 1;
  background: linear-gradient(
      180deg,
      var(--way-palette-black-50),
      var(--way-palette-black-20) 100%
    ),
    url('${({ src }) => src}') lightgray 50% / cover no-repeat;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 12px;
  right: 12px;
  z-index: 20;
  width: 84px;
  height: 28px;
  background: var(--way-palette-white-80);
  backdrop-filter: blur(8px);
  box-shadow: var(--way-design-boxShadow-s);

  & > * {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & button {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0px;
    border-radius: var(--way-design-borderRadiusDefault);
    transition: all 0.2s ease;

    & path {
      stroke: transparent;
      fill: var(--way-palette-black-70);
    }

    &:hover {
      background: var(--way-palette-black-5);
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    &:disabled:hover {
      background: none;
    }
  }

  & span {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--way-palette-black-80);
  }
`;

export default AddOnCard;
