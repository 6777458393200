import React from 'react';
import styled from 'styled-components';

const StyledVersion = styled.div`
  color: var(--way-colors-disabledInput);
  text-align: center;
`;

const Version = () => {
  return (
    <StyledVersion>
      Version:&nbsp;
      {process.env.KOUTO_SCRIPT_VERSION}
    </StyledVersion>
  );
};

export default Version;
