import styled from 'styled-components';

// Styled Input
export const StyledInput = styled.input`
  width: 100%;
  border: 0.5px solid var(--way-colors-borderColor);
  color: ${({ customColor }) => customColor || 'var(--way-colors-inputColor)'};
  &:disabled {
    color: var(--way-colors-disabledInput);
  }
  padding: 12px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  line-height: 17px;
  font-weight: 400;
  background: transparent;
  min-height: 1px;
  font-style: normal;
  :focus {
    outline: 0 none;
  }

  &:hover {
    background: var(--way-palette-black-5);
  }

  &:not(:disabled):not(:focus):hover {
    border: 0.5px solid var(--way-colors-borderHoverColor);
  }
  &:focus {
    outline: none;
    border: 0.5px solid var(--way-colors-borderSelectedColor);
  }
`;

export const StyledWrapInput = styled.div`
  margin-bottom: 20px;
  &:hover {
    .input-field {
      > div {
        background: var(--way-palette-black-5);
      }
    }
  }
`;

// Styled Input
export const StyledLabel = styled.label`
  font-size: 12px;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: ${({ disabled, customColor }) =>
    disabled
      ? 'var(--way-colors-disabledInput)'
      : customColor || 'var(--way-colors-inputColor)'};
  margin-bottom: 4px;
  display: block;
  min-height: 1px;
  font-weight: normal;
  font-style: normal;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  height: 5px;
  margin-top: 5px;
  line-height: 1.2;
`;

export const Error = styled.div`
  color: red;
  font-size: 16px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: var(--way-design-borderRadiusDefault);
  padding: 10px 12px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  line-height: 20px;
  background: transparent;
  min-height: 1px;
  font-weight: normal;
  font-style: normal;

  color: ${({ customColor }) => customColor || 'var(--way-colors-inputColor)'};
  &:disabled {
    color: var(--way-colors-disabledInput);
  }

  border: 0.5px solid
    ${({ customBorderColor }) =>
      customBorderColor || 'var(--way-colors-borderColor)'};
  &:not(:disabled):not(:focus):hover {
    border: 0.5px solid
      ${({ customBorderColor }) =>
        customBorderColor || 'var(--way-colors-borderHoverColor)'};
  }
  &:focus {
    outline: none;
    border: 0.5px solid
      ${({ customBorderColor }) =>
        customBorderColor || 'var(--way-colors-borderSelectedColor)'};
  }
`;
