import React, { FC } from 'react';
import styled from 'styled-components';
import { ListingPictureKind, AVAILABLE_BRAND_FEATURES } from '@kouto/types';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import { Event } from 'types/listings';

const getCoverPhotoUrl = (coverPhoto: Event['pictures'][number]) => {
  return (
    coverPhoto.uri['1080w'] ||
    coverPhoto.uri['750w'] ||
    coverPhoto.uri.original ||
    coverPhoto.uri['640w'] ||
    coverPhoto.uri['480w'] ||
    coverPhoto.uri['384w']
  );
};

interface Props {
  animationDelay: number;
}

const EventCoverPhotosMobile: FC<Props> = ({ animationDelay }) => {
  const { isLoading, event } = useEventBookingSession();
  const disableAnimateScroll = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.DISABLE_ACTIVATE_SCROLLING_ANIMATION,
  );

  if (isLoading || !event) {
    return (
      <Wrapper shouldStick={!disableAnimateScroll}>
        <ImagesWrapper>
          <CoverPhotoSkeleton />
        </ImagesWrapper>
      </Wrapper>
    );
  }

  const eventCoverPhotos = event.pictures.filter(
    (p) => p.kind === ListingPictureKind.COVER,
  );
  eventCoverPhotos.splice(3);

  return (
    <Wrapper shouldStick={!disableAnimateScroll}>
      <ImagesWrapper>
        {eventCoverPhotos.map((coverPhoto) => (
          <div key={coverPhoto.id}>
            <Image
              src={getCoverPhotoUrl(coverPhoto)}
              animationDelay={animationDelay}
              alt={event.title}
            />
          </div>
        ))}
      </ImagesWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  shouldStick: boolean;
}>`
  width: 100%;
  height: 300px;

  ${({ shouldStick }) =>
    shouldStick
      ? `
        position: sticky;
        top: 8px;
      `
      : ''};
`;

const ImagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  > * {
    scroll-snap-align: start;
    flex-shrink: 0;

    padding: 0px 12px;
    height: 100%;
    width: 100%;
  }
`;

const Image = styled.img.attrs<{
  animationDelay: number;
}>(({ animationDelay }) => ({
  style: {
    opacity: 1 - (0.6 * animationDelay) / 100,
  },
}))<{
  animationDelay: number;
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CoverPhotoSkeleton = styled(SkeletonLineSlim)`
  width: 100%;
  height: 100%;
`;

export default EventCoverPhotosMobile;
