import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useMemo,
  MouseEventHandler,
} from 'react';
import styled, { css } from 'styled-components';
import { Modal } from 'components/Modal';
import { P1 } from 'components/theme/Typography/Typography';
import ReadMoreButton from 'components/common/ReadMoreButton';
import HTMLBlockDescription from 'components/HTMLBlockDescription/HTMLBlockDescription';
import { isNil } from 'lodash';
import { useAppState } from 'AppProvider';
import { calculateNumberOfLines, withTruncationStyles } from './utils';

export enum TruncationMode {
  INLINE = 'inline',
  MODAL = 'modal',
}

interface Props {
  text: string;
  maxLines?: number;
  mode?: TruncationMode;
  isRichText: boolean;
  waitForLineCalculation?: boolean;
  customStyles?: ReturnType<typeof css>;
  onShowMore?: () => void;
  showMoreText?: string;
  showLessText?: string;
  textBottomGap?: number;
}

const TruncatedText: FC<Props> = ({
  maxLines,
  mode,
  text,
  isRichText,
  waitForLineCalculation = true,
  customStyles,
  onShowMore,
  showMoreText = 'Show More',
  showLessText = 'Show Less',
  textBottomGap,
}) => {
  const richTextRef = useRef<HTMLDivElement>(null);
  const paragraphTextRef = useRef<HTMLParagraphElement>(null);

  const [isInitialHTMLInjected, setIsInitialHTMLInjected] = useState(false);
  const [isAdditionalContentVisible, setContentVisibility] = useState(false);

  const handleShowMore: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isAdditionalContentVisible && onShowMore) {
      onShowMore();
      return;
    }

    setContentVisibility(!isAdditionalContentVisible);
  };
  const [totalTextLines, setTotalTextLines] = useState(0);

  const { isBaseTheme } = useAppState(
    (state: Record<string, unknown>) => state.theme,
  );

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const doesExceedMaxLines =
    !isNil(mode) && !isNil(maxLines) && totalTextLines > maxLines;

  const shouldTextTruncate = useMemo(() => {
    if (isFirstLoad || !doesExceedMaxLines) {
      return false;
    }
    const isInlineMode = mode === TruncationMode.INLINE;
    return isInlineMode ? !isAdditionalContentVisible : true;
  }, [isFirstLoad, doesExceedMaxLines, mode, isAdditionalContentVisible]);

  useEffect(() => {
    if (isFirstLoad && !isBaseTheme) {
      if (isRichText && !isInitialHTMLInjected) {
        return;
      }
      const numberOfLines = calculateNumberOfLines({
        isRichText,
        ref: isRichText ? richTextRef.current : paragraphTextRef.current,
      });
      setTotalTextLines(numberOfLines);
      setIsFirstLoad(false);
    }
  }, [isRichText, isInitialHTMLInjected, isBaseTheme, isFirstLoad]);

  return (
    <div
      style={{
        // @ts-ignore
        '--maxLines': maxLines,
      }}
    >
      {isRichText ? (
        <HTMLBlockDescription
          ref={richTextRef}
          shouldTruncate={waitForLineCalculation ? shouldTextTruncate : true}
          maxLines={doesExceedMaxLines ? maxLines : undefined}
          htmlDescription={text}
          onInitialHTMLInsert={setIsInitialHTMLInjected}
          disableTruncation={false}
          customStyles={customStyles}
        />
      ) : (
        <TruncatedParagraph
          ref={paragraphTextRef}
          shouldTruncate={waitForLineCalculation ? shouldTextTruncate : true}
          maxLines={maxLines}
          customStyles={customStyles}
        >
          {text}
        </TruncatedParagraph>
      )}

      {doesExceedMaxLines && (
        <ReadMoreButton
          onClick={handleShowMore}
          className="read-more-btn"
          style={{ marginTop: textBottomGap ?? 0 }}
        >
          {isAdditionalContentVisible ? showLessText : showMoreText}
        </ReadMoreButton>
      )}
      {mode === TruncationMode.MODAL && (
        <Modal
          size="large"
          title="Details"
          disableDismissOnClickOutside={false}
          isVisible={isAdditionalContentVisible}
          isClosable
          onClose={() => setContentVisibility(false)}
          content={
            isRichText ? (
              <HTMLBlockDescription
                ref={richTextRef}
                shouldTruncate={false}
                htmlDescription={text}
                onInitialHTMLInsert={setIsInitialHTMLInjected}
                disableTruncation
              />
            ) : (
              <ModalParagraph>{text}</ModalParagraph>
            )
          }
        />
      )}
    </div>
  );
};

const Paragraph = styled(P1)`
  line-height: 32px !important;
`;

const ModalParagraph = styled(Paragraph)`
  color: var(--way-palette-black-100) !important;
`;

const TruncatedParagraph = withTruncationStyles(Paragraph);

export default TruncatedText;
