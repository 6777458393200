import React, { FC } from 'react';
import styled from 'styled-components';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { ExperienceImageSizeMediaSchema } from '@kouto/types';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import { Flex } from 'components/common/styled/common-styled';
import { currencyFormat } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import { SkeletonPulse } from 'components/theme/Skeleton/Skeleton';

interface Props {
  displayedCapacity: number | null;
  price?: number;
  title: string;
  heroImage: z.infer<typeof ExperienceImageSizeMediaSchema>;
}

const GroupHero: FC<Props> = ({
  displayedCapacity,
  heroImage,
  title,
  price,
}) => {
  const currency = useBrandCurrency();
  const { t: translate } = useTranslation();
  const { isLoading } = useResourceBookingSession();

  let detailsLabel = '';
  if (currency && price && Number.isFinite(price) && !Number.isNaN(price)) {
    detailsLabel = `${translate('from')} ${currencyFormat(currency)(price)}`;
  }
  if (currency && displayedCapacity) {
    detailsLabel += ' • ';
  }
  if (displayedCapacity) {
    detailsLabel += `${displayedCapacity} ${translate(
      displayedCapacity === 1 ? 'person' : 'people',
    )}`;
  }

  return (
    <Flex direction="column" alignItem="flex-start" gap={24}>
      <HeroImageWrapper>
        <ResponsiveImage
          viewType="large"
          uriObject={heroImage?.uri}
          defaultSize="480w"
          CustomRenderer={ResourceCoverImage}
          pictureStyle={`
            width: 100%;
            object-fit: cover;
          `}
          style={{ width: '100%' }}
          alt={title}
        />
      </HeroImageWrapper>
      <Flex direction="column" gap={12} alignItem="flex-start">
        <GroupTitle>{title}</GroupTitle>
        {detailsLabel && isLoading ? (
          <SkeletonPulse style={{ height: 18 }} />
        ) : (
          <CurrencyWrapper>
            <ResourcePagePriceText
              lineHeight="24px"
              fontSize="16px"
              className="resource-page__resource-starting-price"
            >
              {detailsLabel}
            </ResourcePagePriceText>
          </CurrencyWrapper>
        )}
      </Flex>
    </Flex>
  );
};

const HeroImageWrapper = styled.div`
  margin-bottom: 0;
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 279px;
  max-width: 481px;
  align-items: center;
  justify-content: center;
`;

const CurrencyWrapper = styled.span`
  margin: 0px;
`;

const GroupTitle = styled.span`
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  white-space: normal;
  overflow: hidden;
`;

const ResourceCoverImage = styled.img<{ isSixteenNineRatio: boolean }>`
  height: 279px;
  object-fit: cover;
  border-radius: var(--way-design-borderRadiusDefault);
  object-position: center;
`;

const ResourcePagePriceText = styled(ResourceParagraph)`
  #kouto-embed-root && {
    margin-right: 10px;
    display: inline;
  }
`;

export default GroupHero;
