export default {
  buyTickets: 'Acheter des billets',
  salesEnded: 'Ventes terminées',
  joinWaitlist: "Rejoindre la liste d'attente",
  soldOut: 'Épuisé',
  noDatesAvailable: 'Aucune date disponible',
  seeMoreDates: 'Voir plus de dates',
  about: 'À propos',
  agenda: 'Agenda',
  menu: 'Menu',
  included: 'Inclus',
  viewMore: 'Voir plus',
  viewLess: 'Voir moins',
  location: 'Lieu',
  time: 'Heure',
  at: 'à',
  noTimesAvailableOnThisDateFotThisPartySize:
    'Aucun horaire disponible à cette date pour cette taille de groupe',
  availableTimes: 'Horaires disponibles',
  waitlist: "Liste d'attente",
  continue: 'Continuer',
  ticketsAreNoLongerAvailable: 'Les billets ne sont plus disponibles.',
  checkOutOutOtherExperiences: 'Découvrez nos autres expériences!',
  viewExperiences: 'Voir les expériences',
  from: 'à partir de',
};
