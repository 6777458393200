import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

// Note used css-loader only for this library.
import 'react-phone-input-2/lib/style.css';
import './PhoneNumberInput.css';

import { useAppState } from 'AppProvider';

const PhoneNumberInput = ({ value, countryCode, onChange, ...props }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (value) {
      setPhoneNumber(value);
    }
  }, [value]);

  const { theme } = useAppState();

  const handleChange = (changedData) => {
    onChange(changedData);
  };

  return (
    <PhoneInput
      country={countryCode ? countryCode.toLowerCase() : 'us'}
      value={phoneNumber}
      countryCodeEditable={false}
      onChange={(phone, country) => {
        if (country.dialCode === phone) {
          handleChange('');
          return;
        }
        handleChange(`+${phone}`);
      }}
      containerStyle={{
        fontFamily: theme.font.primaryFont,
        width: '100%',
        color: 'var(--way-colors-inputColor)',
      }}
      inputStyle={{
        border: '1px solid var(--way-colors-borderColor)',
        width: '100%',
        height: '43px',
        background: 'transparent',
        borderRadius: '0px',
        color: 'var(--way-colors-inputColor)',
      }}
      specialLabel=""
      buttonStyle={{
        width: '38px',
        height: '40px',
        border: 'none',
        borderRadius: '0px',
        borderRight: '1px solid var(--way-colors-borderColor)',
        background: 'transparent',
        color: 'var(--way-colors-inputColor)',
      }}
      dropdownStyle={{
        border: '1px solid var(--way-colors-borderColor)',
        borderRadius: '0px',
        color: 'var(--way-palette-black-100)',
      }}
      searchStyle={{}}
      {...props}
    />
  );
};

PhoneNumberInput.propTypes = {
  value: PropTypes.string,
  countryCode: PropTypes.string,
  onChange: PropTypes.func,
};

PhoneNumberInput.defaultProps = {
  value: null,
  countryCode: null,
  onChange: () => {},
};

export default PhoneNumberInput;
