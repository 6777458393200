import React from 'react';
import { OptionProps } from 'react-select';
import styled from 'styled-components';
import { FilterOption } from '../types';
import { Check } from './Check';

export const Option: React.ComponentType<OptionProps<FilterOption, boolean>> = (
  props,
) => {
  return (
    <OptionRow
      isSelected={props.isSelected}
      {...props.innerRef}
      {...props.innerProps}
    >
      <CheckWrap>{props.isSelected && <Check />}</CheckWrap>
      {props.children}
    </OptionRow>
  );
};

const CheckWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
`;

const OptionRow = styled.div<{ isSelected: boolean }>`
  display: flex;
  height: 32px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? 'var(--way-palette-black-5)' : 'transparent'};
  margin: 2px 4px;
  border-radius: var(--way-design-borderRadiusDefault);
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
`;
