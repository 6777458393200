/* eslint-disable consistent-return */
import React from 'react';
import { setAxiosHeader } from 'utils/axios';
import isEqual from 'lodash/isEqual';
import ReactDOM from 'react-dom/client';
import { AppContextProvider } from 'AppProvider';
import { ToastProvider } from 'ToastProvider';
import WindowDimensionsProvider from 'WindowDimensionProvider';
import ContainerDimensionProvider from 'ContainerDimensionProvider';
import { setFetchHeader } from 'utils/fetch';
import ErrorBoundary from 'components/ErrorBoundary';
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop';
import 'translations/i18n';
import { StyleSheetManager } from 'styled-components';
import { initializeRouter } from 'router';
import { KOUTO_EMBED_ROOT } from 'consts';
import App from './App';
import NotFound from './NotFound';
import stylisPluginCssNamespace from './stylis';
import { analyticsManager } from './features/analytics';

function initApp() {
  const koutoEmbedContainer = document.getElementById(KOUTO_EMBED_ROOT);
  if (!koutoEmbedContainer) {
    console.error(
      new Error(
        "Can't find kouto-embed-root element - please check your integration",
      ),
    );
    return false;
  }

  if (koutoEmbedContainer.getAttribute('data-kouto-initialized')) {
    return true;
  }
  koutoEmbedContainer.setAttribute('data-kouto-initialized', true);

  koutoEmbedContainer.innerHTML = `
    <div id="kouto-app-root"></div><div id="kouto-modal-root"></div>
  `;
  const koutoAppContainer = document.getElementById('kouto-app-root');

  let brandId = null;
  const isDev = isEqual(process.env.NODE_ENV, 'development');

  if (isEqual(process.env.NODE_ENV, 'production')) {
    brandId = koutoEmbedContainer.dataset.brandId;
    console.info('production');
    console.info('brandId', brandId);
  }

  // for staging environment
  if (!brandId) {
    const brandIdRegexp =
      /\/brands\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
    if (brandIdRegexp.test(window.location.pathname)) {
      const idMatches = window.location.pathname.match(brandIdRegexp);
      brandId = idMatches[0].split('/')[2];
    }
  }

  const { Router, routerProps } = initializeRouter(brandId);

  // for development
  if (isDev && !brandId) {
    brandId = process.env.KOUTO_BRAND_ID;
  }
  setFetchHeader('Kouto-Brand-Id', brandId);
  setFetchHeader('Way-Brand-Id', brandId);
  setAxiosHeader('Kouto-Brand-Id', brandId);
  setAxiosHeader('Way-Brand-Id', brandId);

  const cssNameSpacePlugin = stylisPluginCssNamespace({
    cssNamespace: '#kouto-embed-root',
  });

  if (brandId) {
    analyticsManager
      .setProviders(brandId)
      .then(() => {
        analyticsManager.initProviders();
      })
      .catch(() => null);
  }

  const ContextWrapper = () => {
    if (!brandId) {
      return <NotFound isDev={isDev} />;
    }

    return (
      <StyleSheetManager stylisPlugins={[cssNameSpacePlugin]}>
        <AppContextProvider brandId={brandId}>
          <ToastProvider>
            <WindowDimensionsProvider>
              <ContainerDimensionProvider>
                <ErrorBoundary>
                  <Router {...routerProps}>
                    <ScrollToTop />
                    <App />
                  </Router>
                </ErrorBoundary>
              </ContainerDimensionProvider>
            </WindowDimensionsProvider>
          </ToastProvider>
        </AppContextProvider>
      </StyleSheetManager>
    );
  };

  const root = ReactDOM.createRoot(koutoAppContainer);
  root.render(<ContextWrapper />);

  return true;
}

const success = initApp();

if (!success) {
  document.addEventListener('readystatechange', initApp);
}
