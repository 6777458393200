export default {
  featuredExperiences: 'Nos meilleures expériences',
  searchAllExperiences: 'Voir toutes les expériences',
  searchAll: 'Voir toutes ',
  groupSize: 'Capacité',
  eventSize: 'Nombre de participants',
  upTo: 'Jusqu’à',
  whatWeDo: 'Au programme',
  hostedBy: 'Proposée par',
  whatsIncluded: 'Ce qui est inclus',
  avail: 'Disponibilité',
  whereWeAre: 'Le lieu',
  experienceStartingPrice: 'À partir de {{price}}',
  experienceTotalPrice: 'Total {{price}}',
  at: 'à',
  viewPrivateSessAvailability: 'Voir la disponibilité des expériences privées',
  selectSession: 'Sélectionner l’horaire',
  select: 'Sélectionner',
  slotsLeft: '{{remainSlots}} places restantes',
  slotLeft: '{{remainSlots}} place restante',
  bookExperience: 'Réserver l’expérience',
  book: 'Réserver',
  apply: 'Valider',
  maxPartySizes: 'Maximum {{maxGroupSize}} participants par groupe',
  maxPartySize: 'Maximum {{maxGroupSize}} participant par groupe',
  selectSessionValidation: 'Veuillez sélectionner l’horaire',
  selectParticipantValidation:
    'Veuillez sélectionner le nombre de participants',
  selectDateValidation: '',
  reminders: 'Rappels',
  seeOtherDates: 'Voir les autres dates',
  location: 'Point de rendez-vous',
  about: 'À propos',
  soldOut: 'Complète',
  reviews: 'Les Commentaires',
  reviewSingular: 'commentaire',
  reviewPlural: 'commentaires',
  dateOfExperience: 'la date de l’expérience',
  readLess: 'Afficher moins',
  readMore: 'Afficher plus',
  seeMore: 'Voir les détails',
  details: 'Détails',
};
