import moment from 'moment';
import { DATE_FORMAT } from 'utils';
import { Session, Event } from 'types/listings';
import { SessionStatus } from '@kouto/types';

interface IOption {
  startTime: string;
  duration: string;
  dateTimeFormat?: string;
}

export function getSessionStartEndTime({
  startTime,
  duration,
  dateTimeFormat = 'h:mm a',
}: IOption) {
  const startDateTime = moment(startTime);
  const durationInMinutes = moment.duration(duration, 'minutes').asMinutes();
  const endDateTime = startDateTime.add(durationInMinutes, 'minutes');

  return {
    startsAt: startDateTime.format(dateTimeFormat),
    endsAt: endDateTime.format(dateTimeFormat),
    durationInMinutes,
  };
}

export const getDatesFromSessions = (sessions: Session[]) => {
  const today = moment().format(DATE_FORMAT);
  return sessions
    .map((session) => session.startDateTime.split('T')[0])
    .filter((date) => !!date)
    .filter((date, index, self) => self.indexOf(date) === index)
    .filter((date) => date >= today)
    .sort((d1, d2) => (d1 > d2 ? 1 : -1));
};

export const getTimesFromSessions = (sessions: Session[]) => {
  return sessions
    .map((session) => session.startDateTime.split('T')[1])
    .filter((date, index, self) => self.indexOf(date) === index)
    .sort((time1, time2) => (time1 > time2 ? 1 : -1))
    .map((time) => ({
      value: time,
      label: moment(time, 'HH:mm:ss').format('LT'),
    }));
};

export const getFormattedTimes = (startTimes?: Event['startTimes']) => {
  const times = startTimes?.sort((a, b) => (a > b ? 1 : -1)) || [];

  if (times.length === 0) {
    return '';
  }

  if (times.length === 1) {
    return moment(times[0], 'HH:mm:ss').format('LT');
  }

  return `${moment(times[0], 'HH:mm:ss').format('LT')} - ${moment(
    times[times.length - 1],
    'HH:mm:ss',
  ).format('LT')}`;
};

export const getDateTimesLabelFromSessionsAvailability = (
  sessions: Session[],
  date: string,
  waitlistActive: boolean,
) => {
  const sessionsOnDate = sessions.filter(
    (session) => session.startDateTime.split('T')[0] === date,
  );

  const availableSessionsOnDate = waitlistActive
    ? sessionsOnDate
    : filterAvailableSessions(sessionsOnDate);

  const availableTimesOnDate = Array.from(
    new Set(
      availableSessionsOnDate.map(
        (session) => session.startDateTime.split('T')[1],
      ),
    ),
  );

  return getFormattedTimes(availableTimesOnDate);
};

export const filterAvailableSessions = (sessions: Session[]) => {
  return filterNonBlockedSessions(
    sessions.filter(
      (session) =>
        session.participantCount === 0 ||
        (session.participantCount < (session.capacity || 0) &&
          !session.isPrivateEvent),
    ), // non booked sessions
  );
};

export const filterNonBlockedSessions = (sessions: Session[]) => {
  // TODO filter out sessions that are in booking cutoff
  return sessions.filter(
    (session) => session.status !== SessionStatus.OVERLAPPED,
  );
};
