import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import uniq from 'lodash/uniq';
import { Error } from 'components/theme/Form/Form';
import ToggleFeature from 'components/ToggleFeature';

import { useAppState, useDispatch, useBrandId } from 'AppProvider';
import {
  deleteDiscountCode,
  validateDiscountCode,
} from 'actions/discount-code';
import { currencyFormat } from 'utils';
import { DiscountCode } from 'types/discount-code';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import {
  ClearButton,
  Flex,
  Flex1,
} from 'components/common/styled/common-styled';
import TrashIcon from 'assets/icon-trash';

import {
  ApplyDiscountButton,
  DiscountInput,
  DiscountInputContainer,
  DiscountSection,
  PromoAmount,
} from './styles';

const DiscountForm = ({
  cartExperienceIds,
  totalCartDiscount,
}: {
  cartExperienceIds: string[];
  totalCartDiscount: number;
}) => {
  const { t: translateText } = useTranslation();
  const dispatch = useDispatch();
  const currency = useBrandCurrency();
  const {
    discountCode,
    error: validateDiscountCodeError,
    validateDiscountCodeStatus,
  }: DiscountCode = useAppState(
    (state: Record<string, unknown>) => state.discountCode,
  );

  const brandId = useBrandId();
  const [code, setCode] = useState('');

  useEffect(() => {
    if (validateDiscountCodeError) {
      setCode('');
    }
  }, [validateDiscountCodeError]);

  useEffect(() => {
    return function cleanup() {
      dispatch(deleteDiscountCode());
    };
  }, []);

  const validate = () => {
    if (!code) return;
    dispatch(
      validateDiscountCode(code, brandId, {
        experienceIds: uniq(cartExperienceIds),
      }),
    );
  };

  const removeDiscountCode = () => {
    if (discountCode) {
      dispatch(deleteDiscountCode());
      setCode('');
    }
  };

  const renderForm = () => {
    if (discountCode) {
      return (
        <DiscountSection>
          <Flex>
            <Flex1>
              {translateText('promo')} ({discountCode.code})
            </Flex1>
            <PromoAmount>
              ({currencyFormat(currency)(totalCartDiscount)})
            </PromoAmount>
            <ClearButton
              onClick={removeDiscountCode}
              data-testid="delete-discount-code"
            >
              <TrashIcon />
            </ClearButton>
          </Flex>
        </DiscountSection>
      );
    }

    return (
      <DiscountSection>
        <DiscountInputContainer>
          <DiscountInput
            type="text"
            name="discountCode"
            placeholder={translateText('promoCode')}
            value={code}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setCode(e.target.value);
            }}
            onKeyDown={(e: KeyboardEvent) => {
              if (e.key === 'Enter') {
                validate();
              }
            }}
          />
          <ApplyDiscountButton
            className="cart__discount-button"
            type="button"
            disabled={validateDiscountCodeStatus === 'pending'}
            onClick={validate}
          >
            {translateText('apply')}
          </ApplyDiscountButton>
        </DiscountInputContainer>
        {validateDiscountCodeError && (
          <Error>{validateDiscountCodeError}</Error>
        )}
      </DiscountSection>
    );
  };

  return (
    <ToggleFeature featureId="discountCode">
      <div>{renderForm()}</div>
    </ToggleFeature>
  );
};

export default DiscountForm;
