export default {
  featuredExperiences: 'おすすめの体験',
  searchAllExperiences: 'すべての体験を検索',
  searchAll: 'すべて検索',
  groupSize: 'グループサイズ',
  eventSize: 'イベントサイズ',
  upTo: '最大',
  whatWeDo: '私たちがすること',
  hostedBy: 'ホスト',
  whatsIncluded: '含まれるもの',
  avail: '利用可能性',
  whereWeAre: 'どこで行われるのか',
  experienceStartingPrice: '{{price}}から',
  experienceTotalPrice: '合計{{price}}',
  at: 'で',
  viewPrivateSessAvailability: 'プライベートセッションの可用性を表示',
  selectSession: 'セッションを選択',
  noAvailability: '利用可能なスロットなし',
  select: '選択',
  slotsLeft: '残り{{remainSlots}}スロット',
  slotLeft: '残り{{remainSlots}}スロット',
  bookExperience: '体験を予約',
  book: '予約',
  maxPartySizes: 'パーティーあたり最大{{maxGroupSize}}スロット',
  maxPartySize: 'パーティーあたり最大{{maxGroupSize}}スロット',
  selectSessionValidation: 'セッションを選択してください',
  selectParticipantValidation: '参加者を選択してください',
  selectDateValidation: '日付を選択してください',
  reminders: 'リマインダー',
  seeOtherDates: '他の日付を見る',
  location: '場所',
  about: '約',
  soldOut: '売り切れ',
  reviews: 'レビュー',
  reviewSingular: 'レビュー',
  reviewPlural: 'レビュー',
  dateOfExperience: '体験日',
  readLess: '短く読む',
  readMore: 'もっと読む',
  seeMore: 'もっと見る',
  details: '詳細',
};
