import React from 'react';
import get from 'lodash/get';
import { Controller, ControllerProps, useFormContext } from 'hook-form';
import { useTranslation } from 'react-i18next';
import {
  StyledWrapInput,
  StyledLabel,
  StyledInput,
  ErrorContainer,
  Error,
} from 'components/theme/Form';
import { validateEmpty } from 'utils/validators';

export interface ITextFieldProps extends Partial<ControllerProps> {
  name: string;
  label?: string;
  required?: boolean;
}

const TextFieldInput = ({ name, label, ...props }: ITextFieldProps) => {
  const { control } = useFormContext();
  const { t: translate } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={props.required ? validateEmpty() : props.rules ?? {}}
      {...props}
      render={({ field, formState }) => {
        const errorMessage = get(formState, `errors.${name}.message`) as string;
        return (
          <StyledWrapInput>
            <StyledLabel>{label}</StyledLabel>
            <StyledInput type="text" {...field} />
            {errorMessage && (
              <ErrorContainer>
                <Error>{translate(errorMessage)}</Error>
              </ErrorContainer>
            )}
          </StyledWrapInput>
        );
      }}
    />
  );
};

export default TextFieldInput;
