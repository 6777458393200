import moment from 'moment';
import { ProductLine } from '@kouto/types';
import { useBrandId } from 'AppProvider';
import { DATE_FORMAT } from 'utils';
import useGetListings from '../../hooks/useGetListings';

const isListingsLoaded = (listing: ReturnType<typeof useGetListings>) =>
  !listing.isLoadingInitialData;

const productHasCoverPicture = (listing: ReturnType<typeof useGetListings>) =>
  isListingsLoaded(listing) && listing.listings[0]?.coverPicture?.uri['1080w'];

const useProductList = () => {
  const brandId = useBrandId();
  const products: Partial<
    Record<
      ProductLine,
      {
        bg: string;
        count: number;
      }
    >
  > = {};

  const hostListings = useGetListings(
    brandId,
    {
      productLine: ProductLine.HOST,
      limit: 1,
    },
    { ignoreSearchParam: true },
  );

  const activateListings = useGetListings(
    brandId,
    {
      productLine: ProductLine.ACTIVATE,
      limit: 1,
      from: moment().format(DATE_FORMAT),
      to: moment().add(1, 'year').format(DATE_FORMAT),
    },
    { ignoreSearchParam: true },
  );

  const reserveListings = useGetListings(
    brandId,
    {
      productLine: ProductLine.RESERVE,
      limit: 1,
    },
    { ignoreSearchParam: true },
  );

  if (productHasCoverPicture(hostListings)) {
    products[ProductLine.HOST] = {
      bg: hostListings.listings[0].coverPicture?.uri['1080w'] ?? '',
      count: hostListings.meta?.totalItems || 0,
    };
  }

  if (productHasCoverPicture(activateListings)) {
    products[ProductLine.ACTIVATE] = {
      bg: activateListings.listings[0].coverPicture?.uri['1080w'] ?? '',
      count: activateListings.meta?.totalItems || 0,
    };
  }

  if (productHasCoverPicture(reserveListings)) {
    products[ProductLine.RESERVE] = {
      bg: reserveListings.listings[0].coverPicture?.uri['1080w'] ?? '',
      count: reserveListings.meta?.totalItems || 0,
    };
  }

  const allProductsLoaded = [
    hostListings,
    activateListings,
    reserveListings,
  ].every(isListingsLoaded);

  if (!allProductsLoaded) {
    return { products: {}, isLoading: true };
  }

  return { products, loading: false };
};

export default useProductList;
