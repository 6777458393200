export default {
  buyTickets: 'Comprar ingressos',
  salesEnded: 'Vendas encerradas',
  joinWaitlist: 'Entrar na lista de espera',
  soldOut: 'Esgotado',
  noDatesAvailable: 'Nenhuma data disponível',
  seeMoreDates: 'Ver mais datas',
  about: 'Sobre',
  agenda: 'Agenda',
  menu: 'Menu',
  included: 'Incluído',
  viewMore: 'Ver mais',
  viewLess: 'Ver menos',
  location: 'Localização',
  time: 'Hora',
  at: 'em',
  noTimesAvailableOnThisDateFotThisPartySize:
    'Não há horários disponíveis nesta data para este tamanho de grupo',
  availableTimes: 'Horários disponíveis',
  waitlist: 'Lista de espera',
  continue: 'Continuar',
  ticketsAreNoLongerAvailable: 'Ingressos não estão mais disponíveis.',
  checkOutOutOtherExperiences: 'Confira nossas outras experiências!',
  viewExperiences: 'Ver experiências',
  from: 'de',
};
