export default {
  reviewCompleteHeader: 'Revisar y Completar',
  participants: 'Participantes',
  enterParticipantInfoText:
    'Por favor, introduce la información de los participantes en la experiencia. Si queda espacio, agrega más participantes a la experiencia.',
  purchaser: 'Comprador',
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'E-mail',
  optionalEmail: 'E-mail: (opcional)',
  duplicatePurchaserText: 'Igual que el Comprador',
  phone: 'Teléfono: (opcional)',
  addParticipant: 'Añadir {{tierLabel}}',
  participant: 'Participante',
  removeParticipant: 'Eliminar',
  experienceLimitReached:
    'Lo sentimos, esta experiencia ha alcanzado su número máximo de participantes',
  payment: 'Pago',
  duration: 'Duración',
  discountCode: 'Código de Descuento',
  removeCode: 'Eliminar Código',
  invalidCodeValidation: 'Código de Cupón No Válido',
  networkErrorDiscountCode: 'Algo salió mal por nuestra parte',
  discount: 'Descuento',
  promo: 'Promo',
  promoCode: 'Promo code',
  apply: 'Aplicar',
  total: 'Total',
  subTotal: 'Tsubtotal',
  includes: 'incluye',
  and: 'y',
  addHostNotes: 'Añade comentarios para tu anfitrión ',
  policyAggreementText:
    'Estoy de acuerdo con los <0>Términos de servicio</0> y el <1>Aviso de privacidad</1>',
  brandAggreementText:
    'Acepto o certifico de otro modo, que estoy autorizado, en nombre de este participante, a aceptar los términos y condiciones del',
  termsAggreementValidation: 'Por favor, acepta los términos',
  firstNameValidation: 'Se requiere el Nombre',
  lastNameValidation: 'Se requiere el Apellido',
  emailValidation: {
    emailRequiredText: 'Se requiere una Dirección de E-mail',
    'Invalid Email Address': 'Dirección de E-mail Inválida',
    not_specified: 'Dirección de E-mail Incorrecta',
  },
  phoneValidation: {
    'Invalid Phone Number': 'Número de Teléfono No Válido',
    not_specified: 'Inválida',
  },
  participantFirstNameValidation: 'Se requiere el Nombre del Participante',
  participantLastNameValidation: 'El Apellido del Participante es obligatorio',
  complete: 'Completar',
  '404Message': 'Página no encontrada',
  waiting: 'Esperando...',
  complimentary: 'Gratis',
  confirmationHeader: '¡Tu experiencia ha sido reservada!',
  confirmationText:
    'Se envió un e-mail de confirmación a {{email}} con detalles adicionales',
  viewAll: 'Ver todas las Experiencias',
  paymentMethod: 'Método de Pago',
  creditCard: 'Tarjeta de Crédito',
  roomCharge: 'Cargo por Habitación',
  memberCharge: 'Cargo por Miembro',
  roomNumber: 'Número de Habitación',
  memberNumber: 'Número de Miembro',
  chargeValidation: 'Se requiere el Número de Habitación y el Apellido',
  memberNumberValidation: 'Se requiere Número de Miembro y Apellido',
  invalidRoomNumber: 'El número de habitación no es válido',
  invalidMemberNumber: 'El número de miembro no es válido',
  cardNumberIncomplete: 'Número de Tarjeta Incompleto',
  lastNameValidationError: 'Se requiere el Apellido',
  isReqd: 'es obligatorio',
  private: 'Privado',
  browseAllExperieces: 'Explorar todas las Experiencias',
  payWithCreditCard: 'Pagar con Tarjeta de Crédito',
  payWithRoomCharge: 'Pagar con Cargo por Habitación',
  payWithMemberCharge: 'Pagar con Cargo por Miembro',
  payWith: 'Pagar con',
  bookBulkErrorByCard:
    'Hubo un problema al confirmar su reserva. Su tarjeta no fue cargada.',
  bookBulkErrorByRoomCharge: 'Apellido y número de habitación no válidos.',
  bookBulkErrorByMemberCharge: 'Apellido y número de miembro no válidos.',
};
