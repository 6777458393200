export default {
  reviewCompleteHeader: 'Rivedi e completa',
  participants: 'Partecipanti',
  enterParticipantInfoText:
    'Informazioni sui partecipanti di questa esperienza',
  purchaser: 'Acquirente',
  firstName: 'Nome',
  lastName: 'Cognome',
  email: 'Email',
  optionalEmail: 'Email: (opzionale)',
  'phone:': 'Telefono',
  participantFieldPlaceholder:
    'Aggingi nuovo o seleziona partecipante esistente',
  reservationSummary: 'Riepilogo della prenotazione',
  editParticipantsDetails: 'Modifica dettagli partecipanti',
  participantDetails: 'Dettagli dei partecipanti',
  duplicatePurchaserText: "Come l'acquirente",
  phone: 'Telefono',
  addParticipant: 'Aggiungi {{tierLabel}}',
  participant: 'Partecipante',
  removeParticipant: 'Rimuovi',
  experienceLimitReached: 'Questa esperienza è al completo',
  payment: 'Pagamento',
  duration: 'Durata',
  discountCode: 'Codice sconto',
  removeCode: 'Rimuovi codice',
  invalidCodeValidation: 'Codice non valido',
  networkErrorDiscountCode: 'Qualcosa è andato storto',
  discount: 'Sconto',
  promo: 'Promo',
  promoCode: 'Codice promozionale',
  apply: 'Applica',
  total: 'Totale',
  subTotal: 'Subtotale',
  includes: 'Includi',
  and: 'e',
  addHostNotes: 'Aggiungi note',
  policyAggreementText:
    'Concordo con i termini del servizio e le condizioni sulla privacy',
  brandAggreementText:
    'Oppure io certifico di essere stato autorizzato dal partecipante ad acconsentire a',
  termsAggreementValidation: 'Accetto i termini e condizioni',
  firstNameValidation: 'Nome è obbligatorio',
  lastNameValidation: 'Cognome è obbligatorio',
  emailValidation: {
    emailRequiredText: 'Email è obbligatorio',
    invalidEmail: 'Indirizzo email non valido',
    not_specified: 'Indirizzo email non corretto',
  },
  phoneValidation: {
    'Invalid Phone Number': 'Telefono non valido',
    not_specified: 'Non valido',
    phoneRequiredText: 'Numero di telefono è obbligatorio',
  },
  participantFirstNameValidation: 'Nome del partecipante obbligatorio',
  participantLastNameValidation: 'Cognome del partecipante obbligatorio',
  complete: 'Completo',
  '404Message': 'Non trovato',
  waiting: 'Aspetta...',
  complimentary: 'Gratuito',
  confirmationHeader: 'La tua esperienza è prenotata!',
  confirmationText: 'Ti è stata inviata una mail con maggiori dettagli',
  viewAll: 'Vedi tutte le esperienze',
  paymentMethod: 'Metodo di pagamento',
  creditCard: 'Carta di credito',
  roomCharge: 'Carica sul conto della camera',
  memberCharge: 'Carica sulla tessera socio',
  roomOrReservationNumber:
    'Numero di camera o numero di conferma della prenotazione hotel',
  roomNumber: 'Numero di camera',
  roomNumberLegacy: 'Numero di camera o numero della prenotazione hotel',
  memberNumber: 'Numero membro',
  kicc: 'Paga facile',
  chargeValidation: 'Numero di camera e cognome obbligatorio',
  memberNumberValidation: 'Numero di membro richiesto',
  invalidRoomNumber: 'Numero di camera non valido',
  invalidRoomNumberLegacy:
    'Numero di camera o codice di prenotazione non valido',
  invalidMemberNumber: 'Numero membro invalido',
  invalidPhoneNumber: 'Numero di telefono non valido',
  guestNameIsRequired: 'Nome ospite obbligatorio',
  roomNumberIsRequired: 'Numero di camera obligatorio',
  roomNumberIsRequiredLegacy:
    'Numero di camera o codice di prenotazione obbligatorio',
  roomOrReservationNumberIsRequired:
    'Numero di camera o numero di prenotazione obbligatorio',
  cardNumberIncomplete: 'Numero di carta incompleto',
  lastNameValidationError: 'Cognome obbligatorio',
  isReqd: 'è obbligatorio',
  reservationCode: 'Prenotazione/numero di conferma',
  nonHotelGuest: "Non ospite dell'hotel",
  onPropertyGuest: "Ospite dell'hotel",
  preArrivalGuest: '?',
  cardNumber: 'Numero di carta',
  pmsValidationErrorMessage:
    'Cognome o numero di camera o numero di prenotazione non valido',
  pmsDateValidationErrorMessage:
    'Ops!La data della tua esperienza non coincide con arrivo e partenza',
  fullName: 'Nome e cognome',
  checkout: 'Partenza',
  editParticipants: 'Modifica partecipanti',
  private: 'Privato',
  browseAllExperieces: 'Esplora tutte le esperienze',
  payWithCreditCard: 'Paga con carta di credito',
  payWithRoomCharge: 'Paga con addebito in camera',
  payWithMemberCharge: 'Paga con la tessera socio',
  payWith: 'Paga con',
  bookBulkErrorByCard:
    'Si è verificato un problema durante la conferma della tua prenotazione. La tua carta non è stata addebitata.',
  bookBulkErrorByRoomCharge: 'Cognome e numero di camera non validi.',
  bookBulkErrorByMemberCharge: 'Cognome e numero cliente non validi.',
};
