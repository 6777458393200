import React, { FC } from 'react';
import { useIsMobile } from 'WindowDimensionProvider';
import EventCoverPhotosDesktop from './EventCoverPhotosDesktop';
import EventCoverPhotosMobile from './EventCoverPhotosMobile';

interface Props {
  animationDelay?: number;
}

const EventCoverPhotos: FC<Props> = ({ animationDelay = 0 }) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return <EventCoverPhotosMobile animationDelay={animationDelay} />;
  }
  return <EventCoverPhotosDesktop animationDelay={animationDelay} />;
};

export default EventCoverPhotos;
