import React, { FC, useMemo } from 'react';
import { useBrandId } from 'AppProvider';
import { Wrapper } from 'components/theme/Wrapper';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY, ProductLine } from '@kouto/types';
import { NavigationHeader } from 'components/NavigationHeader';
import Spinner from 'components/Spinner/Spinner';

import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import {
  getAnalyticsItemsFromProductsListing,
  getAnalyticsListNameFromProductLine,
} from 'features/analytics';
import LandingPageHeader from './components/LandingPageHeader';
import useGetListings from './hooks/useGetListings';
import { LandingPageHero } from './components/LandingPageHero/LandingPageHero';
import { DynamicListingsGrid } from './components/DynamicListingsGrid';
import NoResultsPage from './components/NoResultsPage';

interface ProductLandingPageProps {
  product: ProductLine;
}

const eventsMapper = {
  [ProductLine.ACTIVATE]: ANALYTICS_EVENT.VIEW_EVENTS,
  [ProductLine.HOST]: ANALYTICS_EVENT.VIEW_HOSTS,
  [ProductLine.RESERVE]: ANALYTICS_EVENT.VIEW_ACTIVITIES,
};

export const ProductLandingPage: FC<ProductLandingPageProps> = ({
  product,
}) => {
  const brandId = useBrandId();
  const { isResults, product: productRoute } = useLandingPageRouteParams();
  const {
    listings,
    loadMore,
    allItemsLoaded,
    isLoadingInitialData,
    isLoadingMore,
  } = useGetListings(
    brandId,
    {
      productLine: product,
    },
    {
      showMultipleCardsPerEvent: isResults && product === ProductLine.ACTIVATE,
      sortByDate: isResults && product === ProductLine.ACTIVATE,
    },
  );

  const analyticsItems = useMemo(() => {
    const items = getAnalyticsItemsFromProductsListing({
      listings,
      product,
    });

    const data = items.reduce(
      (acc, item) => {
        acc.ids.push(item.id ?? '');
        acc.titles.push(item.title ?? '');

        acc.categories.push(item.category ?? '');
        return acc;
      },
      {
        ids: [] as string[],
        titles: [] as string[],
        categories: [] as string[],
      },
    );

    return { items, data };
  }, [listings, product]);

  usePageViewEvent({
    eventName: eventsMapper[product] || ANALYTICS_EVENT.VIEW_HOME,
    payload: {
      [ANALYTICS_PROPERTY.ListName]:
        getAnalyticsListNameFromProductLine(product),
      [ANALYTICS_PROPERTY.AllExperiences]:
        analyticsItems.data.titles.join(';') || '',
      [ANALYTICS_PROPERTY.AllCategories]:
        analyticsItems.data.categories.join(';') || '',
      [ANALYTICS_PROPERTY.ExperienceCount]:
        analyticsItems.items?.length?.toString(),
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(analyticsItems.items),
    },
    isNotReady: !listings.length,
  });

  return (
    <Wrapper
      style={{
        position: 'relative',
        zIndex: 5,
      }}
    >
      {(isResults || productRoute) && <NavigationHeader />}
      {!isResults && (
        <LandingPageHero
          product={product}
          isLoading={isLoadingInitialData}
          listings={listings}
        />
      )}
      <LandingPageHeader product={product} />

      {isResults && isLoadingInitialData && (
        <Spinner
          style={{
            marginTop: '100px',
            minHeight: '300px',
          }}
        />
      )}

      {(!isResults || (listings.length > 0 && !isLoadingInitialData)) && (
        <DynamicListingsGrid
          listings={listings}
          loadMore={loadMore}
          allItemsLoaded={allItemsLoaded}
          isLoading={isLoadingMore}
        />
      )}

      {isResults && listings.length === 0 && !isLoadingInitialData && (
        <NoResultsPage />
      )}
    </Wrapper>
  );
};
