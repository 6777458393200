export default {
  featuredExperiences: 'Naše doporučené zážitky',
  searchAllExperiences: 'Hledat všechny zážitky',
  searchAll: 'Hledat vše',
  groupSize: 'Velikost skupiny',
  eventSize: 'Velikost události',
  upTo: 'Až do',
  whatWeDo: 'Co budeme dělat',
  hostedBy: 'Pořádáno',
  whatsIncluded: 'Co je zahrnuto',
  avail: 'Dostupnost',
  whereWeAre: 'Kde budeme',
  experienceStartingPrice: 'Od {{price}}',
  experienceTotalPrice: 'Celkem {{price}}',
  at: 'v',
  viewPrivateSessAvailability: 'Zobrazit dostupnost soukromých sezení',
  selectSession: 'Vybrat sezení',
  noAvailability: 'Není dostupné',
  select: 'Vybrat',
  slotsLeft: '{{remainSlots}} slotů zbývá',
  slotLeft: '{{remainSlots}} slot zbývá',
  bookExperience: 'Rezervovat zážitek',
  book: 'Rezervovat',
  apply: 'Aplikovat',
  maxPartySizes: 'Maximálně {{maxGroupSize}} slotů na skupinu',
  maxPartySize: 'Maximálně {{maxGroupSize}} slot na skupinu',
  selectSessionValidation: 'Prosím, vyberte sezení',
  selectParticipantValidation: 'Prosím, vyberte účastníky',
  selectDateValidation: 'Prosím, vyberte datum',
  reminders: 'Připomenutí',
  seeOtherDates: 'Zobrazit jiná data',
  location: 'Místo',
  about: 'O',
  soldOut: 'Vyprodáno',
  reviews: 'Recenze',
  reviewSingular: 'recenze',
  reviewPlural: 'recenze',
  dateOfExperience: 'Datum zážitku',
  readLess: 'Číst méně',
  readMore: 'Číst více',
  seeMore: 'Vidět více',
  details: 'Detaily',
};
