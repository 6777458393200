import { useEmbedConfig } from 'features/EmbedConfig';
import useGetListings from 'features/LandingPage/hooks/useGetListings';

export const useFeaturedListings = () => {
  const { brandId } = useEmbedConfig();

  const listings = useGetListings(brandId, { featured: 'true' });

  return listings;
};
