export default {
  featuredExperiences: 'Unsere hervorgehobenen Erlebnisse',
  searchAllExperiences: 'Alle Erlebnisse suchen',
  searchAll: 'Alles suchen',
  groupSize: 'Gruppengröße',
  eventSize: 'Veranstaltungsgröße',
  upTo: 'Bis zu',
  whatWeDo: 'Was wir machen werden',
  hostedBy: 'Veranstaltet von',
  whatsIncluded: 'Was ist enthalten',
  avail: 'Verfügbarkeit',
  whereWeAre: 'Wo wir sein werden',
  experienceStartingPrice: 'Ab {{price}}',
  experienceTotalPrice: 'Gesamt {{price}}',
  at: 'bei',
  viewPrivateSessAvailability: 'Verfügbarkeit privater Sitzungen anzeigen',
  selectSession: 'Sitzung auswählen',
  noAvailability: 'Keine Verfügbarkeit',
  select: 'Auswählen',
  slotsLeft: '{{remainSlots}} Plätze übrig',
  slotLeft: '{{remainSlots}} Platz übrig',
  bookExperience: 'Erlebnis buchen',
  book: 'Buchen',
  apply: 'Anwenden',
  maxPartySizes: 'Maximal {{maxGroupSize}} Plätze pro Gruppe',
  maxPartySize: 'Maximal {{maxGroupSize}} Platz pro Gruppe',
  selectSessionValidation: 'Bitte Sitzung auswählen',
  selectParticipantValidation: 'Bitte Teilnehmer auswählen',
  selectDateValidation: 'Bitte Datum auswählen',
  reminders: 'Erinnerungen',
  seeOtherDates: 'Andere Daten anzeigen',
  location: 'Standort',
  about: 'Über',
  soldOut: 'Ausverkauft',
  reviews: 'Bewertungen',
  reviewSingular: 'Bewertung',
  reviewPlural: 'Bewertungen',
  dateOfExperience: 'Datum des Erlebnisses',
  readLess: 'Weniger lesen',
  readMore: 'Mehr lesen',
  seeMore: 'Mehr sehen',
  details: 'Details',
};
