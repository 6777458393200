import { createContext } from 'react';
import { CartTotalDetails, emptyCartTotalDetails } from '@kouto/cart-prices';
import {
  ConfirmCardPaymentCallback,
  SubmitCardPaymentCallback,
} from 'features/ShoppingCart/types';

type PaymentContextType = CartTotalDetails & {
  confirmCardPaymentCallback?: ConfirmCardPaymentCallback | null;
  setConfirmCardPaymentCallback?: (
    callback: ConfirmCardPaymentCallback,
  ) => void;
  submitCardPaymentCallback?: SubmitCardPaymentCallback | null;
  setSubmitCardPaymentCallback?: (callback: SubmitCardPaymentCallback) => void;
};

export const PaymentContext = createContext<PaymentContextType>(
  emptyCartTotalDetails,
);
