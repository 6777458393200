export default {
  buyTickets: 'チケットを購入',
  salesEnded: '販売終了',
  joinWaitlist: 'ウェイトリストに参加',
  soldOut: '売り切れ',
  noDatesAvailable: '利用可能な日付がありません',
  seeMoreDates: 'もっと見る',
  about: '概要',
  agenda: 'アジェンダ',
  menu: 'メニュー',
  included: '含まれています',
  viewMore: 'もっと見る',
  viewLess: '少なく見る',
  location: '場所',
  time: '時間',
  at: 'で',
  noTimesAvailableOnThisDateFotThisPartySize:
    'この日付ではこの人数の利用可能な時間がありません',
  availableTimes: '利用可能な時間',
  waitlist: 'ウェイトリスト',
  continue: '続ける',
  ticketsAreNoLongerAvailable: 'チケットはもう利用できません。',
  checkOutOutOtherExperiences: '他の体験もご覧ください！',
  viewExperiences: '体験を見る',
  from: 'から',
};
